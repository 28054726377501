<template>
<div>
    <b-row>
        <b-col>
            <div class="column-div">
                <b-row>
                    <b-col>
                        <h1>Dynamic Test</h1>
                        <h3>Element UID: <span><b-row><b-col cols="3"><b-form-input size="lg" style="width 200px;" :value="selectedViewData.uid" ref="copyUID"></b-form-input></b-col><b-col><b-button size="" v-on:click="copyText"><b-icon font-scale="1.8" icon="clipboard-plus"></b-icon></b-button></b-col></b-row></span></h3>                    </b-col>
                    
                    <b-col cols="12">
                        <!-- CK Editor -->
                        <div class="document-editor">
                            <div class="document-editor__toolbar"></div>
                            <div class="document-editor__editable-container">
                                <div class="document-editor__editable">
                                    <p>{{editorData}}</p>
                                </div>
                            </div>
                        </div>


                        <!-- CK Editor -->
                    </b-col>
                </b-row>
            </div>
        </b-col>
        <b-col cols="3">
            <div class="column-div">
                <div class="column-heading"> <!--  -->
                    <h4 style="margin: 0px;">Properties <b-icon v-on:click="dynamic.expanded = !dynamic.expanded" style="float: right;" :icon="dynamic.expanded ? 'dash' : 'plus'"></b-icon></h4>
                </div>
                <b-collapse style="padding:5px;" id="accordion-styles" v-model="dynamic.expanded">
                    <b-row class="btnRow">
                        <b-col cols="4">
                            <b-button @click="saveView">Save</b-button>
                        </b-col>
                        <b-col>
                            <!--<b-dropdown size="sm" variant="light" style="border-radius: 4px; border: 1px solid grey;">
                                <template #button-content>
                                    {{returnSheetNameFromId(editCell.cell.sheetID)}} <b-icon size="sm" icon="caret-down-fill"></b-icon>
                                </template>
                                <b-dropdown-item v-on:click="editCell.cell.sheetName = sheet.name; editCell.cell.sheetID = sheet.id;" v-for="sheet in spreadsheets" :key="sheet.id">{{sheet.name}}</b-dropdown-item>
                            </b-dropdown>-->
                        </b-col>
                    </b-row>
                    <b-row class="btnRow">
                        <b-col cols="4">
                            <label>Tab</label>
                        </b-col>
                        <b-col>
                            <!--<b-dropdown variant="light" style="border-radius: 4px; border: 1px solid grey;" size="sm">
                                <template #button-content>
                                    {{returnTabName(editCell.cell.tabName)}} <b-icon size="sm" icon="caret-down-fill"></b-icon>
                                </template>
                                <b-dropdown-item v-for="(tab, idx) in available_tabs" :key="idx" v-on:click="editCell.cell.tabName = tab" >{{tab}}</b-dropdown-item>
                            </b-dropdown>-->
                        </b-col>
                    </b-row>
                    <!--<b-row class="btnRow">
                        <b-col cols="4">
                            <label>Format</label>
                        </b-col>
                        <b-col>
                            <b-dropdown size="sm" variant="light" style="border-radius: 4px; border: 1px solid grey;">
                                <template #button-content :formatter="upperCase" >
                                    {{returnDataType()}}  <b-icon icon="caret-down-fill"></b-icon>
                                </template>
                                <b-dropdown-item v-for="(type, index) in cellDataTypes" :key="index" v-on:click="editCell.cell.dataType = type">{{type}}</b-dropdown-item>
                            </b-dropdown>
                        </b-col>
                    </b-row>-->
                    <b-row class="btnRow">
                        <b-col cols="4">
                            <label>Source Cell</label>
                        </b-col>
                        <!--<b-col>
                            <b-form-input :formatter="upperCase" ref="currentCellMappingInput" v-model="editCell.cell.cellID"></b-form-input>
                        </b-col>-->
                    </b-row>
                </b-collapse>
                    
                
            </div>
        </b-col>
    </b-row>

</div>
</template>

<script>
import Swal from 'sweetalert2'
//eslint-disable-next-line
import _ from 'lodash'
import { clientMethods, clientComputed, clientViewMethods, clientViewComputed, builderComputed, builderMethods } from '@/state/helpers'

import CustomEditor from "../../../../plugin/ckeditor-custom/ckeditor.js";

export default {
    components:{},
    computed:{
        ...clientComputed,
        ...clientViewComputed,
        ...builderComputed,

    },
    methods:{
        ...clientMethods,
        ...clientViewMethods,
        ...builderMethods,

        saveView(){
            let params = {
                render_view_id: this.selectedViewData.id,
                config_json: {},
            };
            params.config_json['html'] = window.editor.getData();

            this.saveViewConfig(params)
            .then(()=>{
                
            })
        },


        copyText() {
            var copy = this.$refs.copyUID;
            //copy.innerHTML = window.location.href;
            copy.select();
            document.execCommand("copy");
            Swal.fire({
                toast: true,
                position: 'top',
                iconColor: 'white',
                icon: 'success',
                title:`Copied ${copy.value}`,
                customClass: {
                    popup: 'colored-toast'
                },
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true
            })
        },

        initEditor(){
            CustomEditor
            .create( document.querySelector( '.document-editor__editable' ),{
                table: {
                    contentToolbar: [
                        'tableColumn', 'tableRow', 'mergeTableCells',
                        'tableProperties', 'tableCellProperties'
                    ],
                    tableProperties: {},
                    tableCellProperties: {}
                }
            })
            .then( editor => {
                const toolbarContainer = document.querySelector( '.document-editor__toolbar' );
                toolbarContainer.appendChild( editor.ui.view.toolbar.element );
                window.editor = editor;
                window.editor.setData(this.editorData)
            })
            .catch( err => {
                console.error( err );
            });
        }
    },
    data:()=>({
        editor: CustomEditor,
        editorData: '',

        dynamic:{
            expanded: true,
        },
    }),
    mounted(){
        if(this.selectedViewData.config_json != null){
            let data = this.selectedViewData.config_json.html;
            data = data.trim();
            this.editorData = data;
        }
        this.initEditor();
        /*this.$eventHub.$on('selectedView', this.selectViewEvent);
        this.$eventHub.$on('savedNewRow', this.savedNewRowEvent);
        this.$eventHub.$on('savedColumn', this.savedColumnEvent);
        this.$eventHub.$on('createNewTable', this.createNewTableEvent);
        this.$eventHub.$on('createCopyFromTable', this.createCopyFromTableEvent);
        this.$eventHub.$on('selectedApp', this.selectAppEvent);
        this.$eventHub.$on('forceSaveConfiguration', this.forceSaveEvent);*/
    },
    beforeDestroy(){/*
        this.$eventHub.$off('savedNewRow');
        this.$eventHub.$off('savedColumn');
        this.$eventHub.$off('createNewTable');
        this.$eventHub.$off('createCopyFromTable');
        this.$eventHub.$off('selectedView');
        this.$eventHub.$off('selectedApp');
        this.$eventHub.$off('forceSaveConfiguration');*/
    }
}
</script>

<style>

</style>