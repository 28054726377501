<template>
    <div>
        <b-row style="margin-bottom: 10px;">
            <b-col>
                <div class="column-heading"> <!--  -->
                    <h4 style="margin: 0px;">Configuration <b-icon v-on:click="table_properties.expanded = !table_properties.expanded" style="float: right;" :icon="table_properties.expanded ? 'dash' : 'plus'"></b-icon></h4>
                </div>
                <b-collapse id="accordion-styles" v-model="table_properties.expanded">
                    <b-form class="p-1">
                        <b-row>
                            <b-col cols="3">
                                <label>Name</label>
                            </b-col>
                            <b-col>
                                <b-form-input v-model="table_data.name"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="3">
                                <label>Description</label>
                            </b-col>
                            <b-col>
                                <b-form-textarea  rows="3" max-rows="6" v-model="table_data.description"></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="3">
                                <label>Rows</label>
                            </b-col>
                            <b-col cols="3">
                                <b-form-input min=1 type="number" v-model="table_data.rows"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="3">
                                <label>Cols</label>
                            </b-col>
                            <b-col cols="3">
                                <b-form-input min=1 type="number" v-model="table_data.cols"></b-form-input>
                            </b-col>
                        </b-row>
                        <!--<b-row>
                            <b-col cols="3">
                                <label for="template">Template</label>
                            </b-col>
                            <b-col>
                                <b-form-input id="template" v-model="getTemplateName"></b-form-input>
                            </b-col>
                            <b-col cols="2">
                                <div @click="editReport(report.id)" style="height: 100%; display: flex; align-items: center; justify-content: center;  border: 0px; border-radius: 0.3em; background:lightGrey; cursor:pointer;">
                                    <b-icon icon="file-earmark-code-fill"></b-icon>
                                </div>
                            </b-col>
                        </b-row>-->
                        
                    </b-form>
                </b-collapse>

                <!-- Spans -->
                <div class="column-heading"> <!--  -->
                    <h4 style="margin: 0px;">Spans <b-icon v-on:click="table_spans.expanded = !table_spans.expanded" style="float: right;" :icon="table_spans.expanded ? 'dash' : 'plus'"></b-icon></h4>
                </div>
                <b-collapse id="accordion-styles" v-model="table_spans.expanded">
                    <b-row>
                        <b-col>
                            hi
                        </b-col>
                    </b-row>
                </b-collapse>

                <!-- css -->
                <div class="column-heading"> <!--  -->
                    <h4 style="margin: 0px;">Styling <b-icon v-on:click="table_styling.expanded = !table_styling.expanded" style="float: right;" :icon="table_styling.expanded ? 'dash' : 'plus'"></b-icon></h4>
                </div>
                <b-collapse id="accordion-styles" v-model="table_styling.expanded">
                    <b-row>
                        <b-col>
                            hey
                        </b-col>
                    </b-row>
                </b-collapse>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import _ from 'lodash'
import { clientMethods, clientComputed, clientViewMethods, clientViewComputed, builderComputed, builderMethods } from '@/state/helpers'
export default {
    computed:{
        ...clientComputed,
        ...clientViewComputed,
        ...builderComputed,
    },
    methods:{
        ...clientMethods,
        ...clientViewMethods,
        ...builderMethods,
    },
    data:()=>({
        table_properties:{
            expanded: true,
        },
        table_spans:{
            expanded: true,
        },
        table_styling:{
            expanded: true,
        },

        table_data:{
            name: 'Test Table',
            description:'Describe this table using as many words as possible.',
            rows:1,
            cols:2,
            theme:'',
        },


        //* TABLE BUILDER START *//

        freezeDataType:'',
            //* sample sheet names *//
            available_tabs: [
                'BaseData','Yes','MCTable','TargetsV','SpendTables',
                'SpendTables(YES)','SpendTables(NonY)','BCDV',
                'SpendV','TMPSV','OneTarV','LevAmtV'
            ],

            //*mimic webix datatable width and height when hard refreshing
            datatableHeight: 1,
            datatableWidth: 1,

            //* Modal toggles
            toggleNewTableModal: false,
            toggleColumnModal:false,
            toggleRowModal: false,
            toggleTableDisplayModal: false,
            toggleGenerateTokenModal: false,

            showCreateSpan: false,
            isSavingConfig: false,

            lockedSheetAndTab: false,
            lockedSheet: '',
            lockedTab: '',
            toggleEditDataCell: false,

            addColumn:{
                id:'',
                header:'',
            },
            success:false,
            showTable: false,

            editTable:{
                name:'',
                description:'',
            },
            editCell:{//* Object for editing sell/row/column data
                selectedRowID: null,
                selectedColumnID: null,
                multiselect: false,
                cellCss:'',
                cell:{
                    text:'',
                    css:'',
                    type:'',
                    dataType:'',
                    cellID: '',
                    sheetName: '',
                    sheetID:'',
                    tabName:'',
                },
                span:{
                    column:'',
                    row:'',
                    width:0,
                    height:0,
                    value:'',
                    css:'',
                },
                column:{
                    id: '',
                    header:'',
                    width:'',
                    css:'',
                    fillspace:'',
                },
                row:{
                    css:'',
                }
            },
            cellMAppings:[],//? {}
            default_config:{
                id: 'tableBuilder',
                view: 'datatable',
                css: 'webix_header_border webix_data_border',
                header:true,
                spans:true,
                select:"cell",
                autoheight:true,
                autowidth: true,
                scrollX:false,
                fixedRowHeight:false,
                multiselect: true,
                columns:[],
                data:{
                    data:[],
                    spans:[]
                },
                
            },
            config:{
                id: 'tableBuilder',
                view: 'datatable',
                css: 'webix_header_border webix_data_border',
                header:true,
                spans:true,
                select:"cell",
                autoheight:true,
                autowidth: true,
                scrollX:false,
                fixedRowHeight:false,
                multiselect: true,
                columns:[],
                data:{
                    data:[],
                    spans:[]
                },
                
            },
            cellDataTypes:['Decimal','Percentage','Currency',],

            webixOnClickMethods:{
                onSelectChange(){
                    //eslint-disable-next-line no-undef
                    var text = $$('tableBuilder').getSelectedId(true);
                    if(text == ''){//* Nothing selected
                        this.editCell.selectedRowID = null;
                        this.editCell.selectedColumnID = null;
                    }
                },
                //eslint-disable-next-line no-unused-vars
                onItemDblClick(id, e, node){
                    var el = this.$scope.$parent;
                    el.$nextTick(()=>{
                        el.editCell.cell.type = 'data';
                        el.$nextTick(()=>{
                            if(el.lockedSheetAndTab == true){
                                el.editCell.cell.sheetID = el.lockedSheet;
                                el.editCell.cell.tabName = el.lockedTab;
                            }
                            el.$refs.currentCellMappingInput.focus();
                            el.$refs.currentCellMappingInput.select();
                        })
                        
                    })
                },
                //eslint-disable-next-line no-unused-vars
                onItemClick(id, e, node){
                    var el = this.$scope.$parent;
                    var type = this.config.select;
                    //eslint-disable-next-line no-undef
                    var table = $$("tableBuilder");
                    //console.log(table.getSelectedId(false, true))
                    var selectionArray = table.getSelectedId(true);
                    
                    //* Check if multi select support should be enabled for the config editor
                    if(selectionArray.length > 1){
                        el.editCell.multiselect = true;
                    }
                    else{
                        el.editCell.multiselect = false;
                    }

                    //* If painter mode is enabled then paint cells, 
                    //* If not enabled, resume normal cell selection actions
                    if(el.isPainterModeEnabled){
                        el.paintCells(selectionArray, type);
                    }
                    else{
                        //* Populate the editCell object based on selection type
                        if(type == 'cell'){
                            el.editCell.selectedRowID = id.row;
                            el.editCell.selectedColumnID = id.column;
                            el.editCell.span.column = id.column;
                            var row = table.getItem(id.row);
                            el.editCell.cell.text = row[id.column]

                            el.clearSelectionType('cell');

                            //*Check if the selected cell is saved as a data cell
                            var index = _.findIndex(el.cellMAppings, {row_id: id.row, col_id: id.column})
                            if(index != -1){
                                el.editCell.cell.type = 'data';
                                el.editCell.cell.mapID = el.cellMAppings[index].id;
                                el.editCell.cell.cellID = el.cellMAppings[index].sheet_coordinates;
                                el.editCell.cell.tabName = el.cellMAppings[index].tab_name;
                                el.editCell.cell.sheetName = '';
                                el.editCell.cell.sheetID = el.cellMAppings[index].client_app_spreadsheet_id;
                                el.editCell.cell.dataType = el.cellMAppings[index].data_type;
                                if(el.freezeDataType != ''){
                                    el.editCell.cell.dataType = el.freezeDataType
                                }
                                el.$nextTick(()=>{
                                    el.$refs.currentCellMappingInput.focus();
                                    el.$refs.currentCellMappingInput.select();
                                    if(el.freezeDataType != ''){
                                        el.editCell.cell.dataType = el.freezeDataType;
                                        el.$nextTick(()=>{
                                            el.saveCellMapping();
                                        })
                                    }
                                })
                                
                                /*if(this.lockedSheetAndTab){
                                    el.$refs.currentCellMappingInput.focus();
                                    el.editCell.cell.sheetID = el.lockedSheet;
                                    el.editCell.cell.tabName = el.lockedTab;
                                }*/
                            }
                            else{
                                el.editCell.cell.type = 'static';
                                el.editCell.cell.mapID = '';
                                el.editCell.cell.cellID = '';
                                el.editCell.cell.sheetName = '';
                                el.editCell.cell.tabName = '';
                                el.editCell.cell.sheetID = '';
                                if(el.toggleEditDataCell){
                                    el.editCell.cell.type = 'data';
                                    el.$nextTick(()=>{
                                        el.editCell.cell.sheetID = el.lockedSheet;
                                        el.editCell.cell.tabName = el.lockedTab;
                                        el.$refs.currentCellMappingInput.focus();
                                        el.$refs.currentCellMappingInput.select();
                                        
                                    })
                                }
                            }

                            //* If the cell is part of a span, display the spann css for the cell
                            var spanIndex = _.findIndex(this.config.data.spans, (data) => {
                                return (data[0] == id.row && data[1] == id.column)
                            })

                            if(spanIndex != -1){
                                //* Selected cell belongs to span
                                //el.editCell.cell.text = row[id.column]
                                el.editCell.cell.text = el.config.data.spans[spanIndex][4];
                                el.editCell.cell.css = el.config.data.spans[spanIndex][5];
                                
                            }
                            else{
                                //* Cell not in span
                                //*find css class names for individual cell
                                if(row.$cellCss == undefined){
                                    el.editCell.cell.css = '';
                                }
                                else if(row.$cellCss[id.column] == undefined){
                                    el.editCell.cell.css = '';
                                }
                                else{
                                    el.editCell.cell.css = row.$cellCss[id.column]
                                }
                            }
                            
                            el.$nextTick(()=>{
                                if(el.editCell.cell.type == 'static'){
                                    el.$refs.currentCellInput.focus();
                                    el.$refs.currentCellInput.select();
                                }
                                
                            })
                        }
                        else if(type == 'row'){
                            el.editCell.selectedRowID = id.row;
                            el.editCell.selectedColumnID = id.column;
                            el.editCell.span.column = id.column;

                            el.clearSelectionType('row');

                            //*find css classes for selected row
                            var record = table.getItem(id.row);
                            if(record.$css == undefined){
                                el.editCell.row.css = "";
                            }
                            else{
                                el.editCell.row.css = record.$css;
                            }
                        }
                        else if(type == 'column'){
                            el.editCell.selectedRowID = id.row;
                            el.editCell.selectedColumnID = id.column;

                            el.clearSelectionType('column');

                            //*Set column config options
                            var col = table.getColumnConfig(id.column);
                            el.editCell.column.id = col.id;
                            el.editCell.column.header = col.header[0].text;
                            el.editCell.column.width = col.width;
                            if(col.css != undefined){
                                el.editCell.column.css = col.css;
                            }
                            else{
                                el.editCell.column.css = "";
                            }
                            /*if(col.fillspace != undefined){
                                el.editCell.column.fillspace = col.fillspace;
                            }
                            else{
                                el.editCell.column.fillspace = "1";
                            }*/
                        }
                    }
                },
                onBeforeAreaAdd(area){
                    console.log('onB4', area)
                },
                onAfterAreaAdd(area){
                    var el = this.$scope.$parent;
                    var startColIndex = _.findIndex(this.config.columns, {id: area.start.column})
                    var startRowIndex = _.findIndex(this.config.data.data, {id: area.start.row})

                    var endColIndex = _.findIndex(this.config.columns, {id: area.end.column})
                    var endRowIndex = _.findIndex(this.config.data.data, {id: area.end.row})

                    var width = (endColIndex-startColIndex) + 1
                    var height = (endRowIndex-startRowIndex) + 1
                    el.editCell.span.row = area.start.row;
                    el.editCell.span.column = area.start.column;
                    el.editCell.span.width = width;
                    el.editCell.span.height = height;

                    //*Add optional check if area was added by code or user
                    //el.editCell.span.height = height;
                    //el.editCell.span.height = height;

                },
            }
    }),
}
</script>

<style>

</style>