
export const available_elements = [
        {
            id: 0,
            icon: 'mdi mdi-table-column ',
            name: 'Column',
            element:
`<column columns="##columns">
    ##containers
</column>
`,
            options:{
                columns:"2",
            }
        },
//*------------------------------------------------------------------------------------------------------------
        {
            id: 1,icon: 'mdi mdi-panorama-wide-angle', name: 'Column Container', options:null,
            element:
`<column-container>
    *
</column-container>` },

        {   
            id: 2,icon: 'mdi mdi-text-recognition', name: 'Dynamic Text', element: '<dynamic-text id="##id" ref="##ref"></dynamic-text>',
            options:{
                uid:'',
            }
        },
        {   
            id: 3,icon: 'mdi mdi-chart-bar', name: 'Graph', element: '<graph-plugin id="##id" ref="##ref" ></graph-plugin>',
            options:{
                uid:'',
            }
        },
        {   
            id: 4,icon: 'mdi mdi-format-title', name: 'Heading', element: '<heading></heading>',
            options:null
        },
        {   
            id: 5,icon: 'mdi mdi-card-text-outline', name: 'Text Block', element: '<text-block></text-block>',
            options:null
        },
        {   
            id: 6,icon: 'mdi mdi-table-large', name: 'Table', element: '<table-plugin id="##id" ref="##ref" ></table-plugin>',
            options:{
                uid:'',
            }
        },
        {   
            id: 7,icon: 'mdi mdi-format-list-numbered', name: 'List Ordered', element: '<list-ordered></list-ordered>',
            options:{
                items: "0"
            }
        },
        {   
            id: 8,icon: 'mdi mdi-format-list-bulleted', name: 'List Bullet', element: '<list-bullet></list-bullet>',
            options:{
                items: "0"
            }
        },
        {   
            id: 9,icon: 'mdi mdi-format-letter-starts-with', name: 'List Item', element: '<list-item></list-item>',
            options:null
        },
]


