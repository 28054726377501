<!--
@Author: Calvin Green <calvin>
@Date:   2021-07-05T14:19:38+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-07-20T20:15:44+02:00
@Copyright: Diversitude 2021
-->



<template>
    <b-modal id="addReportModal" title="Report">
        <b-form @submit.prevent>
            <b-row class="btnRow">
                <b-col>
                    <b-form-group label="Name" label-for="name">
                        <b-form-input v-model="report.name"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Tag" label-for="tag">
                        <b-form-input v-model="report.tag"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="btnRow">
                <b-col>
                    <b-form-group label="Description" label-for="description">
                        <b-form-input v-model="report.description"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="btnRow">
                <b-col>
                    <b-form-group label="Type" label-for="layout">
                        <b-form-select class="w-50 form-select" v-model="report.config_type" :options="typeOpts"></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Layout" label-for="layout">
                        <b-form-select class="w-50 form-select" v-model="report.layout" :options="layoutOpts"></b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="btnRow">
                <b-col>
                    <b-form-group label="Default Font" label-for="description">
                        <b-form-input class="w-50" v-model="report.default_font"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="btnRow">
                <b-col>
                    <b-form-group label="Upload File" label-for="upload">
                        <uploader :autoStart="false" :ref="'template_upload'" :options="renderUploadOptions()" class="uploader-example">
                        <uploader-unsupport></uploader-unsupport>
                            <uploader-drop>
                                <p>Drop files here to upload or</p>
                                <uploader-btn>select files</uploader-btn>
                                <!--<uploader-btn :attrs="attrs">select images</uploader-btn>
                                <uploader-btn :directory="true">select folder</uploader-btn>-->
                            </uploader-drop>
                            <uploader-list></uploader-list>
                        </uploader>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="report.template == null">
                <b-col>
                    <b-button disabled>Delete Template</b-button>
                </b-col>
            </b-row>
        </b-form>
        <template #modal-footer>
            <div class="w-100">
                <b-button variant="success" @click="save" type="submit" style="margin-left: 5px;float: right;">
                    Save
                </b-button>
                <b-button variant="light" style="float: right;" @click="$bvModal.hide('addReportModal')">
                    Close
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import _ from 'lodash';
import { clientViewMethods, clientViewComputed, clientComputed, clientMethods } from '@/state/helpers'
export default {
    data:()=>({
        report:{
            id:-1,
            client_app_id:'',
            name:'',
            description:'',
            tag:'',
            config_type:'template',
            layout: 'portrait',
            template:null,
            default_font: 'Roboto'
        },
        layoutOpts: [
            {value: 'portrait', text: 'Portrait'},
            {value: 'landscape', text: 'Landscape'}
        ],
        typeOpts:[
            {value: 'template', text: 'Template'},
            {value: 'builder', text: 'Builder'}
        ],
        saved_id:'',
    }),
    mounted(){
        if(this.edit_data != null){
            this.report = _.cloneDeep(this.edit_data);
        }
        else{
            this.report.client_app_id = this.selected_app.id;
        }
    },
    computed:{
        ...clientComputed,
        ...clientViewComputed,
    },
    methods:{
        ...clientMethods,
        ...clientViewMethods,
        save(){
            this.saveReport(this.report)
            .then((id)=>{
                this.report.id = id;
                this.saved_id = id;
                if(this.report.template != null){
                    setTimeout(()=>{
                    this.processUpload()
                        .then(()=>{
                            this.$bvModal.hide('addReportModal');
                            this.$eventHub.$emit('refreshReportPreview', id);
                        })
                        .catch(()=>{
                            alert('Error uploading template file');
                        })
                    }, 100)
                }
                else{
                    this.$bvModal.hide('addReportModal');
                    this.$eventHub.$emit('loadReports');
                }
            })
        },
        processUpload(){
            return new Promise((resolve, reject)=>{
                const uploaderInstance = this.$refs['template_upload'];
                const upload = uploaderInstance.uploader;
                upload.opts.query.client_app_report_id = this.saved_id;
                upload.resume();

                //eslint-disable-next-line no-unused-vars
                upload.on('fileSuccess', (rootFile, file, message, chunk)=>{
                    var response = JSON.parse(message);
                    if(response.success){
                        //eslint-disable-next-line no-console
                        console.log("Upload success", file);
                        this.$eventHub.$emit('loadReports');
                        resolve();
                    }
                    else {
                       //eslint-disable-next-line no-console
                        console.log("Upload error");
                        reject();
                    }
                })
                setTimeout(()=>{
                    upload.off("fileSuccess");
                    upload.cancel();
                },2000)
            })

        },
        renderUploadOptions() {
            var storage = window.localStorage;
            var AUTH_TOKEN = storage.getItem('x_token');
            var url = process.env.VUE_APP_API_BASEURL + 'api/clientapp/uploadtemplate';
            var options = {
                target: url,
                query: {
                    client_app_report_id: this.report.id,
                },
                headers: {
                    'Access-Control-Allow-Credentials': true,
                    'X-Requested-With': 'XMLHttpRequest',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer ' + AUTH_TOKEN,
                },
                testChunks: false,
                fileParameterName: 'template', //Set the upload post param which will contain the binary payload
                singleFile: true, //Limit upload to one file
            }
            return options;
        },
    },

}
</script>

<style>
  .uploader-example {
    width: 100%;

    margin: 0 auto 0;
    font-size: 12px;
  }
  .uploader-example .uploader-btn {
    margin-right: 4px;
  }
  .uploader-example .uploader-list {
    max-height: 440px;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
  }
</style>
