<template>
    <div>
        <b-row v-if="!showSettings">
            <b-col>
                <h5>No Report Selected</h5>
            </b-col>
        </b-row>
        <b-row v-else style="margin-bottom: 10px;">
            <b-col>
                <div class="column-heading"> <!--  -->
                    <h4 style="margin: 0px;">Configuration <b-icon v-on:click="configuration.expanded = !configuration.expanded" style="float: right;" :icon="configuration.expanded ? 'dash' : 'plus'"></b-icon></h4>
                </div>
                <b-collapse id="accordion-styles" v-model="configuration.expanded">
                    <b-form class="p-1">
                        <b-row>
                            <b-col cols="3">
                                <label for="reportName">Report Name</label>
                            </b-col>
                            <b-col>
                                <b-form-input id="reportName" v-model="report.name"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="3">
                                <label for="template">Template</label>
                            </b-col>
                            <b-col>
                                <b-form-input id="template" v-model="getTemplateName"></b-form-input>
                            </b-col>
                            <b-col cols="2">
                                <div @click="editReport(report.id)" style="height: 100%; display: flex; align-items: center; justify-content: center;  border: 0px; border-radius: 0.3em; background:lightGrey; cursor:pointer;">
                                    <b-icon icon="file-earmark-code-fill"></b-icon>
                                </div>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-collapse>
                <div class="column-heading"> <!--  -->
                    <h4 style="margin: 0px;">Components <b-icon v-on:click="components.expanded = components.expanded" style="float: right;" :icon="components.expanded ? 'dash' : 'plus'"></b-icon></h4>
                </div>
                <b-collapse id="accordion-styles" v-model="components.expanded">
                    <b-row>
                        <draggable :list="this.components.types"
                        :group="{ name: 'viewType', pull: 'clone', put: false }">
                        <b-col cols="12" v-for="type in components.types" :key="type.id">
                            {{type.name}}
                        </b-col>
                        </draggable>
                    </b-row>
                </b-collapse>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import _ from 'lodash'
import { clientViewMethods, clientViewComputed, clientComputed, clientMethods } from '@/state/helpers'
export default {
    components:{
        draggable,
    },
    data:()=>({
        configuration:{
            expanded: true,
            report_name: '',
            template: '',
        },
        components:{
            expanded: true,
            types:[
                {id:0, name: 'Static Text', value:'static_text'},
                {id:1, name: 'Table', value:'table'}
            ]
        },
        showSettings:false,

        report:{}
    }),
    computed:{
        ...clientViewComputed,
        ...clientComputed,
        getTemplateName(){
            if(this.report.template == null){
                return 'No template loaded';
            }
            else{
                return this.report.template;
            }
        }
    },
    methods:{
        ...clientViewMethods,
        ...clientMethods,
        selectedReportEvent(){
            if(this.selected_report != {}){
                this.report = _.cloneDeep(this.selected_report)
                this.showSettings = true;
            }
        },
        editReport(id){
            this.$eventHub.$emit('openEditReportModal', id);
        }
    },
    mounted(){
        this.$eventHub.$on('selectedNewReport', this.selectedReportEvent)
    },
    beforeDestroy(){
        this.$eventHub.$off('selectedNewReport')
    }

}
</script>

<style>

</style>