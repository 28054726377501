<template>
    <div>
    <b-row>
        <b-col :cols="hasConfig? 9:12">
            <div class="column-div">
                <b-overlay :show="isSaving">
                    <b-row>
                        <b-col>
                            <!--<h1>Graph</h1>-->
                            <!--<h3>Element UID: {{selectedViewData.uid}} <b-button size="sm" variant="outline-info" v-on:click="testMapping">Test Mapping</b-button></h3>-->
                            <h3 style="padding-top: 0.8rem; padding-left: 0.2rem; padding-right: 0.2rem;">
                                <b-button @click="$eventHub.$emit('changeSelectedViewType', 'group_table')" style="padding-top: 5px; padding-bottom: 5px;" variant="outline-primary">
                                    <b-icon icon="arrow-left" class="label-icon"></b-icon> Back
                                </b-button>
                                &nbsp;
                                {{graphName}}</h3>
                                <!--<span><b-row><b-col cols="3"><b-form-input size="lg" style="width 200px;" :value="selectedViewData.uid" ref="copyUID"></b-form-input></b-col><b-col><b-button size="" v-on:click="copyText"><b-icon font-scale="1.8" icon="clipboard-plus"></b-icon></b-button></b-col></b-row></span>-->
                        </b-col>
                    </b-row>
                    <b-row v-if="config != null">
                        <b-col cols="12" v-if="isSupportedGraphType">
                            <b-dropdown size="sm" variant="success" id="dropdown-form" text="Add Series" ref="dropdown" class="m-2">
                                <b-dropdown-form class="p-1">
                                    <b-row class="btnRow">
                                        <b-col>
                                            <label label="Name" label-for="label">Name</label>
                                            <b-form-input v-model="new_series.text" label="Legend Name"></b-form-input>
                                        </b-col>
                                    </b-row>
                                    <b-row class="btnRow">
                                        <b-col>
                                            <label label="Name" label-for="label">Colour</label>
                                            <b-form-input type="color" v-model="new_series.color" label="Legend Name"></b-form-input>
                                        </b-col>
                                    </b-row>
                                    <hr>
                                    <b-button @click="addSeries(new_series.text, new_series.color)">Save</b-button>
                                    
                                </b-dropdown-form>
                            </b-dropdown>

                            <b-dropdown size="sm" variant="danger" text="Delete Series" class="m-2">
                                <b-dropdown-item @click="deleteSeries(idx)" v-for="(item, idx) in config.config_json.legend.values" :key="idx">{{item.text}}</b-dropdown-item>
                            </b-dropdown>

                            <b-dropdown size="sm" variant="success" id="dropdown-form2" text="Add Bar" ref="dropdown2" class="m-2">
                                <b-dropdown-form class="p-1">
                                    <b-row class="btnRow">
                                        <b-col>
                                            <label label="Name" label-for="label">Bar Label</label>
                                            <b-form-input v-model="new_bar.label" label="Legend Name"></b-form-input>
                                        </b-col>
                                    </b-row>
                                    <hr>
                                    <b-button @click="addBar()">Save</b-button>
                                    
                                </b-dropdown-form>
                            </b-dropdown>

                            <b-dropdown size="sm" variant="danger" text="Delete Bar" class="m-2">
                                <b-dropdown-item @click="deleteBar(idx)" v-for="(item, idx) in config.config_json.data" :key="idx">{{item.label}}</b-dropdown-item>
                            </b-dropdown>

                            <b-button @click="softRefresh();" size="sm" variant="warning"><b-icon font-scale="2" icon="arrow-repeat"></b-icon></b-button>
                        </b-col>
                        <b-col v-if="showGraph && config.config_json != null">
                            <webix-ui :config="config.config_json" />
                        </b-col>

                        <!-- no graph data -->
                        <b-col cols="3" v-if="config.config_json == null"><!-- empty --></b-col>
                        <b-col cols="6" v-if="config.config_json == null">
                            <div class="text-center" style="margin-top: 4rem; margin-bottom: 4rem;">
                                <h4>No graph configured</h4>
                            <b-button @click="openGraphWizardModal" variant="outline-primary">Show Wizard</b-button>
                            </div>
                        </b-col>
                        <b-col cols="3" v-if="config.config_json == null"><!-- empty --></b-col>
                    </b-row>
                </b-overlay>
            </div>
        </b-col>
        <b-col cols="3">
            <div v-if="hasConfig" class="column-div" style="min-height: 40rem; max-height: 100%; overflow-x:hidden; overflow-y:hidden;">
                <b-overlay :show="isSaving">
                    <div v-if="isSupportedGraphType && hasConfig" style="margin-bottom: 0.5rem;">
                        <div class="column-heading" v-on:click="toggleCollapseMenu('properties')">
                            <h4 style="margin: 0px;">Properties <b-icon style="float: right;" :icon="properties.expanded ? 'dash' : 'plus'"></b-icon></h4>
                        </div>
                        <b-collapse style="padding:5px;" id="accordion-styles" v-model="properties.expanded">
                            <b-row class="btnRow">
                                <b-col cols="4">
                                    <label class="col-form-label">X Axis Label</label>
                                </b-col>
                                <b-col>
                                    <b-form-input v-model="config.config_json.xAxis.title"></b-form-input>
                                </b-col>
                            </b-row>
                            <div v-if="isHorizontal">
                                <b-row class="btnRow">
                                    <b-col cols="4">
                                        <label class="col-form-label">Start</label>
                                    </b-col>
                                    <b-col>
                                        <b-form-input v-model.number="config.config_json.xAxis.start"></b-form-input>
                                    </b-col>
                                </b-row>
                                <b-row class="btnRow">
                                    <b-col cols="4">
                                        <label class="col-form-label">End</label>
                                    </b-col>
                                    <b-col>
                                        <b-form-input v-model.number="config.config_json.xAxis.end"></b-form-input>
                                    </b-col>
                                </b-row>
                                <b-row class="btnRow">
                                    <b-col cols="4">
                                        <label class="col-form-label">Steps</label>
                                    </b-col>
                                    <b-col>
                                        <b-form-input v-model.number="config.config_json.xAxis.step"></b-form-input>
                                    </b-col>
                                </b-row>
                            </div>
                            <hr>
                            <span class="card-title m-0">Labels</span>
                            <b-row class="btnRow">
                                <b-col cols="4">
                                    <label class="col-form-label">Y Axis Title</label>
                                </b-col>
                                <b-col>
                                    <b-form-input v-model="config.config_json.yAxis.title"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="btnRow" v-if="!isVertical">
                                <b-col cols="4">
                                    <label class="col-form-label">Show Lables</label>
                                </b-col>
                                <b-col cols="6">
                                    <b-button class="h4" variant="outline-success" @click="hideLabel('y')" v-if="hasAxisLables('y')">
                                        <b-icon font-scale="1.3" icon="toggle-on"></b-icon>
                                    </b-button>
                                    <b-button variant="outline-default" v-else @click="showLabel('y')">
                                        <b-icon font-scale="1.3" icon="toggle-off"></b-icon>
                                    </b-button>
                                        
                                </b-col>
                            </b-row>
                            <div v-if="isVertical">
                                <b-row class="btnRow">
                                    <b-col cols="4">
                                        <label class="col-form-label">Show Lables</label>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-button class="h4" variant="outline-success" @click="hideLabel('x')" v-if="hasAxisLables('x')">
                                            <b-icon font-scale="1.2"  icon="toggle-on"></b-icon>
                                        </b-button>
                                        <b-button variant="outline-default" v-else @click="showLabel('x')">
                                            <b-icon font-scale="1.2" icon="toggle-off"></b-icon>
                                        </b-button>
                                    </b-col>
                                </b-row>
                                <b-row class="btnRow">
                                    <b-col cols="4">
                                        <label class="col-form-label">Start</label>
                                    </b-col>
                                    <b-col>
                                        <b-form-input v-model.number="config.config_json.yAxis.start"></b-form-input>
                                    </b-col>
                                </b-row>
                                <b-row class="btnRow">
                                    <b-col cols="4">
                                        <label class="col-form-label">End</label>
                                    </b-col>
                                    <b-col>
                                        <b-form-input v-model.number="config.config_json.yAxis.end"></b-form-input>
                                    </b-col>
                                </b-row>
                                <b-row class="btnRow">
                                    <b-col cols="4">
                                        <label class="col-form-label">Steps</label>
                                    </b-col>
                                    <b-col>
                                        <b-form-input v-model.number="config.config_json.yAxis.step"></b-form-input>
                                    </b-col>
                                </b-row>
                            </div>
                            <hr>
                            <span class="card-title m-0">Bar</span>
                            <b-row class="btnRow">
                                <b-col cols="4">
                                    <label class="col-form-label">Bar Width</label>
                                </b-col>
                                <b-col>
                                    <b-form-input v-model.number="config.config_json.barWidth"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="btnRow">
                                <b-col cols="4">
                                    <label class="col-form-label">Height</label>
                                </b-col>
                                <b-col>
                                    <b-form-input v-model.number="config.config_json.height"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="btnRow">
                                <b-col cols="12">
                                    <b-button @click="enableLabelsInColumn" variant="primary">Display Label In Column</b-button>
                                </b-col>
                            </b-row>
                            <hr>
                            <span class="card-title m-0">Graph Padding</span>
                            <b-row class="btnRow">
                                <b-col @click="toggleSidePadding('left')" cols="1">
                                    <b-icon class="mt-2" font-scale="1.2" :icon="showPadding.left ? 'check-square': 'square'"></b-icon>
                                </b-col>
                                <b-col cols="3"><label class="col-form-label">Left</label></b-col>
                                <b-col cols="4"><b-form-input type="number" v-model.number="config.config_json.padding['left']"></b-form-input></b-col>
                            </b-row>
                            <b-row class="btnRow">
                                <b-col @click="toggleSidePadding('right')" cols="1">
                                    <b-icon class="mt-2" font-scale="1.2" :icon="showPadding.right ? 'check-square': 'square'"></b-icon>
                                </b-col>
                                <b-col cols="3"><label class="col-form-label">Right</label></b-col>
                                <b-col cols="4"><b-form-input type="number" v-model.number="config.config_json.padding['right']"></b-form-input></b-col>
                            </b-row>
                            <b-row class="btnRow">
                                <b-col @click="toggleSidePadding('top')" cols="1">
                                    <b-icon class="mt-2" font-scale="1.2" :icon="showPadding.top ? 'check-square': 'square'"></b-icon>
                                </b-col>
                                <b-col cols="3"><label class="col-form-label">Top</label></b-col>
                                <b-col cols="4"><b-form-input type="number" v-model.number="config.config_json.padding['top']"></b-form-input></b-col>
                            </b-row>
                            <b-row class="btnRow">
                                <b-col @click="toggleSidePadding('bottom')" cols="1">
                                    <b-icon class="mt-2" font-scale="1.2" :icon="showPadding.bottom ? 'check-square': 'square'"></b-icon>
                                </b-col>
                                <b-col cols="3"><label class="col-form-label">Bottom</label></b-col>
                                <b-col cols="4"><b-form-input type="number" v-model.number="config.config_json.padding['bottom']"></b-form-input></b-col>
                            </b-row>
                            

                            <!--<b-row v-if="config.config_json.padding == undefined">
                                <b-col>
                                    <b-button v-on:click="initPadding">Init Padding</b-button>
                                </b-col>
                            </b-row>
                            <b-row class="btnRow">
                                <b-col cols="3">
                                    <label>Padding L</label>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-input v-model="config.config_json.padding['left']"></b-form-input>
                                </b-col>
                                <b-col cols="3">
                                    <label>Padding R</label>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-input v-model="config.config_json.padding['right']"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="btnRow">
                                <b-col cols="3">
                                    <label>Padding T</label>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-input v-model="config.config_json.barWidth"></b-form-input>
                                </b-col>
                                <b-col cols="3">
                                    <label>Padding B</label>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-input v-model="config.config_json.barWidth"></b-form-input>
                                </b-col>
                            </b-row>-->

                            <b-row class="btnRow">
                                <b-col>
                                    <b-button variant="success" v-on:click="saveGraphConfig">Save Config</b-button>
                                </b-col>
                            </b-row>
                        </b-collapse>
                    </div>
                    <!--  -->

                    <div style="margin-bottom: 0.5rem;">
                        <div v-if="hasConfig" class="column-heading" v-on:click="toggleCollapseMenu('graph')" >
                            <h4 style="margin: 0px;">{{returnCategoryName}} <b-icon style="float: right;" :icon="graph.expanded ? 'dash' : 'plus'"></b-icon></h4>
                        </div>
                        <b-collapse v-if="hasConfig" style="padding:5px; margin-bottom: 2px;" id="accordion-styles" v-model="graph.expanded">
                            <b-row class="btnRow">
                                    <b-col cols="4">
                                        <label class="col-form-label">Graph Height</label>
                                    </b-col>
                                    <b-col>
                                        <b-form-input v-model="config.config_json.height"></b-form-input>
                                    </b-col>
                            </b-row>
                            <hr>

                            <b-row v-if="config.config_json != null">
                                <b-col cols="12">
                                    <!--<strong>Selected Group: {{editData.bar.label}}</strong>-->
                                    <strong v-if="config.config_json.type=='pie'" >Slices</strong>
                                    <strong v-else >Bars</strong>
                                </b-col>
                                <b-col v-if="config.config_json.type=='pie'">
                                    <b-button v-on:click="editData.pie = item; displayMapping(item.id); selectedLabel = item.id;" :variant="showSelectedLabel(item.id)" size="sm" style="margin: 2px;" v-for="item in config.config_json.data" :key="item.id">{{item.label}}</b-button>
                                </b-col>
                                <b-col v-else>
                                    <b-button :variant="showSelectedLabel(item.id)" v-on:click="selectBarGroup(item.id)" size="sm" style="margin-right: 2px; margin-bottom: 2px;" v-for="item in config.config_json.data" :key="item.id">{{item.label}}</b-button>
                                </b-col>
                            </b-row>
                            
                            <div v-if="editData.pie != null" style="">
                                <b-row class="btnRow">
                                    <b-col cols="4">
                                        <label class="col-form-label">Value</label>
                                    </b-col>
                                    <b-col>
                                        <b-form-input v-model="editData.pie.value"></b-form-input>
                                    </b-col>
                                </b-row>
                                <b-row class="btnRow">
                                    <b-col cols="4">
                                        <label class="col-form-label">Display Label</label>
                                    </b-col>
                                    <b-col>
                                        <b-form-input v-model="editData.pie.label"></b-form-input>
                                    </b-col>
                                </b-row>
                                <b-row class="btnRow">
                                    <b-col cols="4">
                                        <label class="col-form-label">Colour</label>
                                    </b-col>
                                    <b-col cols="3">
                                        <b-form-input type="color" style="padding: 0.4rem; height: 2.2rem;" v-model.lazy="editData.pie.color"></b-form-input>
                                    </b-col>
                                    <b-col cols="5">
                                        <b-form-input v-model.lazy="editData.pie.color"></b-form-input>
                                    </b-col>
                                    
                                </b-row >
                                <b-row class="btnRow">
                                    <b-col>
                                        <b-button variant="success" v-on:click="updateCurrentConfig()">Update</b-button>
                                    </b-col>
                                </b-row>
                            </div>
                            <div v-if="editData.bar != null">
                                <div>
                                    <b-row class="btnRow">
                                        <b-col>
                                            <!--<strong>Selected Group: {{editData.bar.label}}</strong>-->
                                            <strong>Selected Group: {{editData.bar.label}}</strong>
                                        </b-col>
                                    </b-row>
                                    
                                </div>
                                <div>
                                    <b-row class="btnRow">
                                        <b-col>
                                            <b-button style="margin-right:  2px; margin-bottom: 2px;" :variant="showSelectedBar(bar)" size="sm" v-for="(bar,idx) in availableBars" v-on:click="selectBarValue(bar)" :key="idx">{{formatName(bar)}}</b-button>
                                        </b-col>
                                        
                                        <!--<b-col cols="4">
                                            <label>{{bar}}</label>
                                        </b-col>
                                        <b-col>
                                            <b-form-input v-model="editData.bar[bar]"></b-form-input>
                                        </b-col>-->
                                    </b-row>
                                    <div v-if="editBar.value != ''">
                                        <b-row class="btnRow">
                                            <hr>
                                            <b-col cols="4">
                                                <label  class="col-form-label"><!--{{editBar.value}}-->Value</label>
                                            </b-col>
                                            <b-col>
                                                <b-form-input v-model="editData.bar[editBar.value]"></b-form-input>
                                            </b-col>
                                            
                                        </b-row>
                                        <b-row class="btnRow">
                                            <b-col cols="12">
                                                <b-button variant="success" @click="saveGraphConfig">Save Value</b-button>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </div>
                            </div>
                            <div v-if="showMapping">
                                <hr/>
                                <b-row class="btnRow">
                                    <b-col cols="4">
                                        <label class="col-form-label">Sheet</label>
                                    </b-col>
                                    <b-col>
                                        <b-dropdown size="sm" variant="light" style="border-radius: 4px; border: 1px solid grey;">
                                            <template #button-content>
                                                {{returnSheetNameFromId(graph_mapping.sheet_id)}} <b-icon size="sm" icon="caret-down-fill"></b-icon>
                                            </template>
                                            <b-dropdown-item class="dropdownItem" v-on:click="graph_mapping.sheet_name = sheet.name; graph_mapping.sheet_id = sheet.id;" v-for="sheet in spreadsheets" :key="sheet.id">{{sheet.name}}</b-dropdown-item>
                                        </b-dropdown>
                                    </b-col>
                                </b-row>
                                <b-row class="btnRow">
                                    <b-col cols="4">
                                        <label class="col-form-label">Tab</label>
                                    </b-col>
                                    <b-col>
                                        <b-dropdown variant="light" style="border-radius: 4px; border: 1px solid grey;" size="sm">
                                            <template #button-content>
                                                {{returnTabName(graph_mapping.tab)}} <b-icon size="sm" icon="caret-down-fill"></b-icon>
                                            </template>
                                            <b-dropdown-item class="dropdownItem" v-for="(tab, idx) in available_tabs" :key="idx" v-on:click="selectTab(tab)" >{{tab}}</b-dropdown-item>
                                        </b-dropdown>
                                    </b-col>
                                </b-row>
                                <b-row class="btnRow">
                                    <b-col cols="4">
                                        <label class="col-form-label">Source Cell</label>
                                    </b-col>
                                    <b-col>
                                        <b-form-input :formatter="upperCase" id="currentCellMappingInput" ref="currentCellMappingInput" v-model="graph_mapping.source"></b-form-input>
                                    </b-col>
                                </b-row>
                                <b-row class="btnRow">
                                    <b-col>
                                        <b-button variant="success" v-on:click="saveMapping()">Save Mapping</b-button> &nbsp;
                                        <b-button :variant="isMappingLocked" v-on:click="lockMapping()">Lock Mapping Choices</b-button>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-collapse>
                    </div>

                    <!-- START LEGEND CONFIG GROUP -->
                    <div v-if="config != null && isSupportedGraphType" style="margin-bottom: 0.5rem;">
                        <div class="column-heading" v-on:click="toggleCollapseMenu('legend')" >
                            <h4 style="margin: 0px;">Legend Settings <b-icon style="float: right;" :icon="legend.expanded ? 'dash' : 'plus'"></b-icon></h4>
                        </div>
                        <b-collapse style="padding:5px; margin-bottom: 2px;" id="accordion-styles" v-model="legend.expanded">
                            <b-row v-if="config.config_json" class="btnRow">
                                <b-col cols="12">
                                    <span class="card-title">Legend Position</span>
                                </b-col>
                                <b-col cols="4">
                                    <label class="col-form-label">Layout</label>
                                </b-col>
                                <b-col>
                                    <b-dropdown :formatter="upperCase" :text="config.config_json.legend.layout">
                                        <b-dropdown-item @click="setLegendLayout('x')">X axis align</b-dropdown-item>
                                        <b-dropdown-item @click="setLegendLayout('y')">Y axis align</b-dropdown-item>
                                    </b-dropdown>
                                </b-col>
                            </b-row>
                            <div v-if="config.config_json.legend.layout != 'xx'">
                                <b-row v-if="config.config_json" class="btnRow">
                                    <b-col cols="4">
                                        <label class="col-form-label">Horizontal Align</label>
                                    </b-col>
                                    <b-col>
                                        <b-dropdown :formatter="upperCase" :text="config.config_json.legend.align">
                                            <b-dropdown-item @click="config.config_json.legend.align = 'left'; softRefresh();">Left</b-dropdown-item>
                                            <b-dropdown-item @click="config.config_json.legend.align = 'right'; softRefresh();">Right</b-dropdown-item>
                                            <b-dropdown-item v-if="config.config_json.legend.layout == 'x'" @click="config.config_json.legend.align = 'center'; softRefresh();" >Center</b-dropdown-item>
                                        </b-dropdown>
                                    </b-col>
                                </b-row>
                                <b-row v-if="config.config_json" class="btnRow">
                                    <b-col cols="4">
                                        <label class="col-form-label">Vertical Align</label>
                                    </b-col>
                                    <b-col>
                                        <b-dropdown :formatter="upperCase" :text="config.config_json.legend.valign">
                                            <b-dropdown-item @click="config.config_json.legend.valign = 'top'; softRefresh();">Top</b-dropdown-item>
                                            <b-dropdown-item @click="config.config_json.legend.valign = 'bottom'; softRefresh();">Bottom</b-dropdown-item>
                                            <b-dropdown-item v-if="config.config_json.legend.layout == 'y'" @click="config.config_json.legend.valign = 'middle'; softRefresh();">Middle</b-dropdown-item>
                                        </b-dropdown>
                                    </b-col>
                                </b-row>
                                <b-row class="btnRow">
                                    <b-col cols="12">
                                        <b-button @click="saveGraphConfig();" variant="success">Save Legend Layout</b-button>
                                    </b-col>
                                </b-row>
                            </div>
                            <hr>
                            <b-row v-if="config.config_json">
                                <b-col cols="12" >
                                    <!--<b-button v-for="(legend, idx) in config.config_json.legend.values" :key="idx" style=" margin-bottom: 0.4rem; margin-left: 0.4rem; margin-right: 0.4rem;" ></b-button>-->
                                    <b-button variant="outline-primary" v-for="(legend, idx) in config.config_json.legend.values" :key="idx" @click="editLegend(idx)" style="width: 100%; margin-bottom: 0.4rem;" >
                                        <div style="display: flex; flex-direction: row">
                                            <div style="float:left; text-align: left; width: 70%;">{{legend.text}}</div>
                                            <div style="width: 30%;"><div :style="returnColor(legend.color)" style="border: 1px solid grey; border-radius: 4px; width: 100%;">color</div></div>
                                        </div>

                                    </b-button>
                                </b-col>
                            </b-row>
                            <div v-if="selectedLegend != null">
                                <hr class="simple">
                            <b-row class="btnRow">
                                <b-form-group id="example-color" label-cols-sm="2" label-cols-lg="2" label="Name" label-for="color">
                                    <b-form-input id="name" ref="legendText" type="text" name="name" v-model="edit_legend.text"></b-form-input>
                                </b-form-group>
                            </b-row>
                            <b-row class="btnRow">
                                <b-form-group id="example-color" label-cols-sm="2" label-cols-lg="2" label="Color" label-for="color">
                                    <b-form-input style="padding: 0.2rem; height: 2.2rem;" id="color" type="color" name="color" v-model="edit_legend.color"></b-form-input>
                                </b-form-group>
                            </b-row>
                            <b-row class="btnRow">
                                <b-col>
                                    <b-button @click="saveLegend(selectedLegend)" variant="success">Save</b-button>
                                </b-col>
                            </b-row>
                            </div>
                        </b-collapse>
                    </div>

                    <!-- overlay graph settings -->
                    <div v-if="config != null && isSupportedGraphType" style="margin-bottom: 0.5rem;">
                        <div class="column-heading" v-on:click="toggleCollapseMenu('overlay')" >
                            <h4 style="margin: 0px;">Graph Overlay<b-icon style="float: right;" :icon="overlay.expanded ? 'dash' : 'plus'"></b-icon></h4>
                        </div>
                        <b-collapse style="padding:5px; margin-bottom: 2px;" id="accordion-styles" v-model="overlay.expanded">
                            <b-row v-if="config.config_json" class="btnRow">
                                
                                <b-col cols="12">
                                    <span v-if="hasGraphOverlay" class="card-title">
                                        Configure Graph Overlay &nbsp;
                                        <b-button style="float: right;" size="sm" variant="danger" @click="deleteGraphOverlay()">Remove Overlay</b-button>
                                    </span>
                                    <div v-else>
                                        <b-button @click="showGraphOverlayModal" v-if="showGraphOverlayConfig == false">Enable Graph Overlay</b-button>
                                    </div>
                                </b-col>
                                
                            </b-row>
                            <b-row class="btnRow" v-if="hasGraphOverlay"><!-- orientation check -->
                                <b-col cols="4" class="mb-1 pt-1">
                                    <label for="4">Icon offset</label>
                                </b-col>
                                <b-col cols="8">
                                    <b-button variant="outline-primary" size="sm" @click="changeOffset('-')"><strong>-</strong></b-button>&nbsp;
                                    <b-button variant="outline-primary" size="sm" @click="changeOffset('+')"><strong>+</strong></b-button>
                                </b-col>
                                <b-col cols="4" class="mb-1 pt-1">
                                    <label for="4">Icon Type</label>
                                </b-col>
                                <b-col cols="1">
                                    <label class="pt-1" v-if="overlayChartIndex != -1" for="4">
                                        <b-icon v-if="scatterIconType == 'Square'" icon="square-fill"></b-icon>
                                        <b-icon v-if="scatterIconType == 'Triangle'" icon="triangle-fill"></b-icon>
                                        <b-icon v-if="scatterIconType == 'Diamond'" icon="diamond-fill"></b-icon>
                                        <b-icon v-if="scatterIconType == 'Circle'" icon="circle-fill"></b-icon>
                                    </label>
                                </b-col>
                                <b-col cols="5">
                                    <b-dropdown size="sm" variant="info" text="Change Icon">
                                        <b-dropdown-item @click="config.config_json.series[overlayChartIndex].item.type = 'd'; softRefresh();">
                                            Diamond</b-dropdown-item>
                                        <b-dropdown-item @click="config.config_json.series[overlayChartIndex].item.type = 't'; softRefresh();">
                                            Triangle</b-dropdown-item>
                                        <b-dropdown-item @click="config.config_json.series[overlayChartIndex].item.type = 'c'; softRefresh();">
                                            Circle</b-dropdown-item>
                                        <b-dropdown-item @click="config.config_json.series[overlayChartIndex].item.type = 's'; softRefresh();">
                                            Square</b-dropdown-item>
                                    </b-dropdown>
                                </b-col>
                                <b-col cols="12">
                                    <hr>
                                </b-col>
                                <b-col cols="12">
                                    <span v-if="showOverlayValues" class="card-title">Edit Overlay Default Values</span>
                                    <b-button v-if="!showOverlayValues" @click="showOverlayValues = !showOverlayValues" size="sm" variant="info">Edit Overlay Values</b-button>
                                    <b-collapse v-model="showOverlayValues">
                                        <b-row>
                                            <b-col v-for="(item, idx) in config.config_json.data" :key="item.id" cols="12">
                                                <b-form-input v-model.number="config.config_json.data[idx].magic_data"></b-form-input>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col class="mt-1 mb-1">
                                                <b-button variant="success" @click="saveOverlayValues()">Save Values</b-button>&nbsp;
                                                <b-button variant="warning" @click="showOverlayValues = false;">Back</b-button>
                                            </b-col>
                                        </b-row>
                                    </b-collapse>
                                    <hr>
                                </b-col>
                                <b-col cols="4">
                                    Color
                                </b-col>
                                <b-col cols="8">
                                    <b-form-input type="color" v-model="config.config_json.series[overlayChartIndex].item.color"></b-form-input>
                                </b-col>
                                <b-col cols="4">
                                    Border Colour
                                </b-col>
                                <b-col cols="8">
                                    <b-form-input type="color" v-model="config.config_json.series[overlayChartIndex].item.borderColor"></b-form-input>
                                </b-col>
                                <b-col cols="12" class="mt-2 mb-2">
                                    <b-button @click="softRefresh()" size="sm" variant="primary">Apply Color</b-button>
                                    &nbsp;
                                    <b-button @click="saveGraphConfig()" size="sm" variant="success">Save Changes</b-button>
                                </b-col>
                                <hr>
                                <div class="card-title">Overlay Mapping</div>
                                <b-col cols="12">
                                    <b-button size="sm" @click="selectOverlayValue(item)" :variant="showSelectedOverlayItem(item.id)" class="m-1" v-for="item in config.config_json.data" :key="item.id">{{item.label}}</b-button>
                                    <div v-if="editOverlay.value != ''">
                                        <b-row class="btnRow">
                                            <b-col cols="4">
                                                <label class="col-form-label">Sheet</label>
                                            </b-col>
                                            <b-col>
                                                <b-dropdown size="sm" variant="light" style="border-radius: 4px; border: 1px solid grey;">
                                                    <template #button-content>
                                                        {{returnSheetNameFromId(graph_mapping.sheet_id)}} <b-icon size="sm" icon="caret-down-fill"></b-icon>
                                                    </template>
                                                    <b-dropdown-item class="dropdownItem" v-on:click="graph_mapping.sheet_name = sheet.name; graph_mapping.sheet_id = sheet.id;" v-for="sheet in spreadsheets" :key="sheet.id">{{sheet.name}}</b-dropdown-item>
                                                </b-dropdown>
                                            </b-col>
                                        </b-row>
                                        <b-row class="btnRow">
                                            <b-col cols="4">
                                                <label class="col-form-label">Tab</label>
                                            </b-col>
                                            <b-col>
                                                <b-dropdown variant="light" style="border-radius: 4px; border: 1px solid grey;" size="sm">
                                                    <template #button-content>
                                                        {{returnTabName(graph_mapping.tab)}} <b-icon size="sm" icon="caret-down-fill"></b-icon>
                                                    </template>
                                                    <b-dropdown-item class="dropdownItem" v-for="(tab, idx) in available_tabs" :key="idx" v-on:click="selectTab(tab)" >{{tab}}</b-dropdown-item>
                                                </b-dropdown>
                                            </b-col>
                                        </b-row>
                                        <b-row class="btnRow">
                                            <b-col cols="4">
                                                <label class="col-form-label">Source Cell</label>
                                            </b-col>
                                            <b-col>
                                                <b-form-input :formatter="upperCase" id="currentCellMappingInput" ref="currentCellMappingInput" v-model="graph_mapping.source"></b-form-input>
                                            </b-col>
                                        </b-row>
                                        <b-row class="btnRow">
                                            <b-col>
                                                <b-button variant="success" v-on:click="saveOverlayMapping()">Save Mapping</b-button> &nbsp;
                                                <b-button :variant="isMappingLocked" v-on:click="lockMapping()">Lock Mapping Choices</b-button>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    
                                </b-col>
                            </b-row>
                        </b-collapse>
                    </div>
                </b-overlay>
            </div>
        </b-col>
    </b-row>
    <GraphWizardModal v-if="showGraphWizardModal"/>
    <OverlayModal v-if="showGraphOverlayConfig" :config="config" :barCount="data_series_length" />
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import OverlayModal from './addOverlayModal.vue'

//eslint-disable-next-line
import _ from 'lodash'
import { clientMethods, clientComputed, clientViewMethods, clientViewComputed, builderComputed, builderMethods } from '@/state/helpers'

import GraphWizardModal from '../../modals/graphWizardModal.vue'

export default {
    components:{
        GraphWizardModal,
        OverlayModal,
    },
    computed:{
        ...clientComputed,
        ...clientViewComputed,
        ...builderComputed,
        hasGraphOverlay(){
            var hasScatter = false;
            if(this.hasConfig){
                var index = _.findIndex(this.config.config_json.series, {type: 'scatter'});
                if(index != -1){
                    hasScatter = true;
                }
            }
            return hasScatter;
        },
        availableBars(){
            if(this.editData.bar != null){
                var bars = [];
                //eslint-disable-next-line
                for(let [key, value] of Object.entries(this.editData.bar)){
                    if(key.includes('value')){
                        bars.push(key);
                    }
                }
                return bars;
            }
            else{
                return '';
            }
        },
        
        returnCategoryName(){
            var name = '';
            if(this.config != null){
                if(this.config.config_json != null){
                    if(this.config.config_json.type == 'pie'){
                        name = 'Pie Settings'
                    }
                    else{
                        name = 'Bar Group Settings'
                    }
                }
            }
            return name;
        },
        graphName(){
            if(this.config != null){
                if(this.config.name != null){
                    return this.config.name
                }
                else{
                    return false;
                }
            }
            else{
                return false;
            }
        },
        scatterIconType(){
            var type = this.config.config_json.series[this.overlayChartIndex].item.type;
            if(type == 'c'){
                return 'Circle';
            }
            if(type == 'd'){
                return 'Diamond';
            }
            if(type == 't'){
                return 'Triangle';
            }
            if(type == 's'){
                return 'Square';
            }
            else{
                return '';
            }
        },
        showMapping(){
            if(this.config != null){
                if(this.config.config_json != null){
                    var type = this.config.config_json.type;
                    if(type == 'pie' && this.selectedLabel != -1 && this.editData.pie != null){
                        return true;
                    }
                    else if(this.selectedLabel != -1 && this.editBar.value != '' && this.editData.bar != null){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                else{
                    return false;
                }
            }
            else{
                return false;
            }
        },
        isMappingLocked(){
            if(this.isLocked){
                return 'info'
            }
            else{
                return 'outline-info'
            }
        },
        hasConfig(){
            var valid = false;
            if(this.config != null){
                if(this.config.config_json != null){
                    valid = true;
                }
            }
            return valid;
        },
        isSupportedGraphType(){
            var valid = false;
            if(this.hasConfig){
                var type = this.config.config_json.type;
                if(type == 'bar' || type == 'barH' || type == 'stackedBar' || type == 'stackedBarH'){
                    valid = true;
                }
            }
            return valid;
        },

        isVertical(){
            var type = this.config.config_json.type;
            if(type == 'bar' || type == 'stackedBar'){
                return true;
            }
            else{
                return false;
            }
        },
        isHorizontal(){
            var type = this.config.config_json.type;
            if(type == 'barH' || type == 'stackedBarH'){
                return true;
            }
            else{
                return false;
            }
        }
        ,

        overlayChartIndex(){
            var index = _.findIndex(this.config.config_json.series, {type: 'scatter'});
            return index;
        }


    },
    methods:{
        ...clientMethods,
        ...clientViewMethods,
        ...builderMethods,


        enableLabelsInColumn(){
            //TODO: Add check to see if labels exist, if they do exist - remove them

            //*loop over series and set the column label to the same template as the value
            if(this.config.config_json.type == 'bar' || this.config.config_json.type == 'stackedBar'){
                this.config.config_json.series.forEach( item => {
                    item['label'] = item.value;
                });
            }
            else if(this.config.config_json.type == 'barH' || this.config.config_json.type == 'stackedBarH'){
                this.config.config_json.series.forEach( item => {
                    if(item.value == '#magic#'){
                        item['label'] = "#magic_data#";
                    }
                    else{
                        item['label'] = item.value;
                    }
                    
                });
                this.config.config_json.xAxis['template'] = '';
            }
            this.softRefresh();
        },

        softRefresh(){
            this.showGraph = false;
            this.$nextTick(()=>{
                this.showGraph = true;
            })
        },
        changeOffset(type){
            if(type == '+'){
                this.config.config_json.data.forEach( item => {
                    item.magic += 0.05;
                })
            }
            else{
                this.config.config_json.data.forEach( item => {
                    item.magic -= 0.05;
                })
            }
            this.softRefresh();
        },

        hasAxisLables(type){
            var valid = false;
            if(this.hasConfig){
                if(type == 'y'){
                    if(this.config.config_json.yAxis.template != ""){
                        valid = true;
                    }
                }
                if(type == 'x'){
                    if(this.config.config_json.xAxis.template != ""){
                        valid = true;
                    }
                }
                
            }
            return valid;
        },
        setLegendLayout(type){
            this.config.config_json.legend.layout = type;
            if(type == 'y'){
                if(this.config.config_json.legend.align == 'center'){
                    this.config.config_json.legend.align = 'right';
                }
            }
            else{
                if(this.config.config_json.legend.valign == 'middle'){
                    this.config.config_json.legend.valign = 'top';
                }
            }
            this.softRefresh();
        },

        hideLabel(type){
            if(type == 'y'){
                this.config.config_json.yAxis.template = '';
                
            }
            else{
                this.config.config_json.xAxis.template = '';
            }
            this.saveGraphConfig();
        },
        showLabel(type){
            if(type == 'y'){
                this.config.config_json.yAxis.template = '#label#';
            }
            else{
                this.config.config_json.xAxis.template = '#label#';
            }
            this.saveGraphConfig();
        },
        toggleCollapseMenu(item){
            switch(item){
                case 'overlay':{
                    this.overlay.expanded = !this.overlay.expanded
                    if(this.overlay.expanded){
                        this.graph.expanded = false;
                        this.legend.expanded = false;
                        this.properties.expanded = false;
                    }
                    break;
                }
                case 'properties':{
                    this.properties.expanded = !this.properties.expanded
                    if(this.properties.expanded){
                        this.graph.expanded = false;
                        this.legend.expanded = false;
                    }
                    break;
                }
                case 'graph':{
                    this.graph.expanded = !this.graph.expanded
                    if(this.graph.expanded){
                        this.properties.expanded = false;
                        this.legend.expanded = false;
                    }
                    break;
                }
                case 'legend':{
                    this.legend.expanded = !this.legend.expanded
                    if(this.legend.expanded){
                        this.graph.expanded = false;
                        this.properties.expanded = false;
                    }
                    break;
                }
            }
        },
        deleteGraphOverlay(){
            let index = _.findIndex(this.config.config_json.series, {type: 'scatter'});
            if(index != -1){
                this.config.config_json.series.splice(index, 1);

                this.config.config_json.data.forEach( item => {
                    delete item.magic;
                    delete item.magic_data;
                })

                delete this.config.config_json.xValue;

                alert('Success');
            }
        },

        showGraphOverlayModal(){
            this.data_series_length = this.config.config_json.data.length;
            this.showGraphOverlayConfig = true;
            this.$nextTick(()=>{
                this.$bvModal.show('overlayGraph');
                this.$root.$once('bv::modal::hidden',(event)=>{
                    if(event.type == 'hidden' && event.componentId == 'overlayGraph'){
                        this.showGraphOverlayConfig = false;
                    }
                })
            })
            
        },

        initPadding(){
            this.config.config_json['padding'] = {};
        },
        returnColor(color){
            return `background-color: ${color}; color: ${color};`
        },
        formatName(text){
            var index = Number(text.substring(5)) - 1;
            //console.log(this.config.config_json.legend.values[index])
            return this.config.config_json.legend.values[index].text;
            //return text.replace("value", "Bar ");
        },

        copyText() {
            var copy = this.$refs.copyUID;
            //copy.innerHTML = window.location.href;
            copy.select();
            document.execCommand("copy");
            Swal.fire({
                        toast: true,
                        position: 'top',
                        iconColor: 'white',
                        icon: 'success',
                        title:`Copied ${copy.value}`,
                        customClass: {
                            popup: 'colored-toast'
                        },
                        showConfirmButton: false,
                        timer: 4000,
                        timerProgressBar: true
                    })
        },
        editLegend(index){
            this.selectedLegend = index;
            this.edit_legend = _.cloneDeep(this.config.config_json.legend.values[index])
            this.$nextTick(()=>{
                this.$refs.legendText.focus();
            })
        },
        saveLegend(index){
            this.config.config_json.legend.values[index] = this.edit_legend;
            this.config.config_json.series[index].color = this.edit_legend.color;

            this.saveGraphConfig();
        },

        testMapping(){
            //eslint-disable-next-line
            var mapping_test = [
                {id:1425,mapped_cell_id:"1626079415287|value1",value:5},
                {id:1425,mapped_cell_id:"1626079415287|value2",value:8},
                {id:1425,mapped_cell_id:"1626079415288|value1",value:6},
                {id:1425,mapped_cell_id:"1626079415288|value2",value:9},]

            mapping_test.forEach(item => {
                var [group_id, column ] = item.mapped_cell_id.split('|');
                group_id = parseInt(group_id);
                var index = _.findIndex(this.config.config_json.data, (element)=>{
                    return element.id == group_id
                });
                if(index != -1){
                    this.config.config_json.data[index][column] = item.value;
                }
            })
            this.showGraph = false;
            this.$nextTick(()=>{
                this.showGraph = true;
            })
        },

        loadGraph(){
            this.showGraph = false;
            this.config = _.cloneDeep(this.selectedViewData);
            if(this.config.config_json != null){
                //* Removes x axis steps from config for horizontal stacked bar graph
                if(this.config.config_json.type == 'stackedBarH'){
                    //this.config.config_json.xAxis = {};
                }


                //* Padding checks
                if(this.config.config_json.padding == undefined){
                    this.config.config_json.padding = {};
                }
                else{
                    if(this.config.config_json.padding.left != undefined){
                        this.showPadding['left'] = true;
                    }
                    if(this.config.config_json.padding.right != undefined){
                        this.showPadding['right'] = true;
                    }
                    if(this.config.config_json.padding.top != undefined){
                        this.showPadding['top'] = true;
                    }
                    if(this.config.config_json.padding.bottom != undefined){
                        this.showPadding['bottom'] = true;
                    }
                }

                //* Add on click method to config
                this.config.config_json.on = { 
                    onItemClick: function(id){
                        var el = this.$scope.$parent.$parent;
                        el.graphOnClick(id)
                    }
                }
                this.config.config_json.id = "graphView"
            }
            this.$nextTick(()=>{
                this.showGraph = true;
            })
        },

        saveGraphConfig(){
            if(this.showPadding.left == false){
                delete this.config.config_json.padding.left
            }
            if(this.showPadding.right == false){
                delete this.config.config_json.padding.right
            }
            if(this.showPadding.top == false){
                delete this.config.config_json.padding.top
            }
            if(this.showPadding.bottom == false){
                delete this.config.config_json.padding.bottom
            }

            var id = this.selectedViewData.id;
            var params = {
                render_view_id: id,
                config_json: JSON.stringify(this.config.config_json),
            }

            this.isSaving = true;

            this.saveViewConfig(params)
            .then(()=>{
                Swal.fire({ toast: true, position: 'top', iconColor: 'white', icon: 'success', title:'Saved Configuration', customClass: {     popup: 'colored-toast' }, showConfirmButton: false, timer: 2000, timerProgressBar: true})
                this.getViewGroups(this.selected_app.id)
                .then(()=>{
                    this.$eventHub.$emit('displaySelectedView', this.selectedViewData.id);
                    this.setSelectedView(this.selected_view_id);
                    this.loadGraph();
                    this.isSaving = false;
                    setTimeout(()=>{
                        if(this.selectedLabel != -1){
                            var bar = this.editBar.value;
                            this.selectBarGroup(this.selectedLabel);
                            this.$nextTick(()=>{
                                this.selectBarValue(bar);
                            })
                        }
                        
                    },250)
                })
                .catch(()=>{
                    alert('Get views failed');
                    this.isSaving = false;
                })
            })
            .catch(()=>{
                alert('Failed');
                this.isSaving = false;
            })
        },

        updateCurrentConfig(){
            if(this.editData.pie != null){
                var index = _.findIndex(this.config.config_json.data, {id:this.editData.pie.id})
                if(index != -1){
                    this.showGraph = false;
                    this.config.config_json.data[index] = this.editData.pie;
                    this.$nextTick(()=>{
                        this.showGraph = true;
                    })
                }
            }
        },

        selectTab(tab){
            this.graph_mapping.tab = tab;
            this.$nextTick(()=>{
                this.$refs.currentCellMappingInput.focus();
            })
        },
        saveOverlayMapping(){
            Swal.fire({ toast: true, position: 'top', iconColor: 'white', icon: 'success', title:'Saving...', customClass: { popup: 'white-toast' }, showConfirmButton: false, timer: 2000, timerProgressBar: true})
            let params = {
                client_app_sheet_id: this.graph_mapping.sheet_id,
                render_view_id: this.selected_view_id,
                tab_name: this.graph_mapping.tab,
                sheet_co_ords: this.graph_mapping.source,
                mapped_cell_id: this.selectedOverlayID + '|magic_data',
                data_type: '',
                data_source: 'spreadsheet',
            }
            console.log(params);
            this.saveViewMapping(params)
            .then(()=>{
                Swal.fire({ toast: true, position: 'top', iconColor: 'white', icon: 'success', title:'Saved Mapping', customClass: {     popup: 'colored-toast' }, showConfirmButton: false, timer: 2000, timerProgressBar: true})
                this.getViewGroups(this.selected_app.id)
                .then(()=>{
                    this.$eventHub.$emit('displaySelectedView', this.selectedViewData.id)
                    this.setSelectedView(this.selected_view_id)
                    .then(()=>{
                        this.loadGraph();
                        this.$nextTick(()=>{
                            if(this.isRefreshing){
                                //this.graphOnClick(this.selectedLabel);
                                this.isRefreshing = false;
                            }
                        })
                        
                    })
                })
            })
        },
        saveOverlayValues(){
            this.showOverlayValues = false;
            this.saveGraphConfig();
        },

        saveMapping(){
            Swal.fire({ toast: true, position: 'top', iconColor: 'white', icon: 'success', title:'Saving...', customClass: { popup: 'white-toast' }, showConfirmButton: false, timer: 2000, timerProgressBar: true})
            var type = this.config.config_json.type
            if(type == "barH" || type == "stackedBarH" || type == "stackedBar"){
                type = 'bar';
            }

            switch(type){
                default:{
                    alert('Add support')
                    break;
                }
                case 'pie':{
                    let params = {
                        client_app_sheet_id: this.graph_mapping.sheet_id,
                        render_view_id: this.selected_view_id,
                        tab_name: this.graph_mapping.tab,
                        sheet_co_ords: this.graph_mapping.source,
                        mapped_cell_id: this.editData.pie.id,
                        data_type: '',
                    }
                    this.saveViewMapping(params)
                    .then(()=>{
                        Swal.fire({ toast: true, position: 'top', iconColor: 'white', icon: 'success', title:'Saved Mapping', customClass: {     popup: 'colored-toast' }, showConfirmButton: false, timer: 2000, timerProgressBar: true})
                        this.getViewGroups(this.selected_app.id)
                        .then(()=>{
                            this.$eventHub.$emit('displaySelectedView', this.selectedViewData.id)
                            this.setSelectedView(this.selected_view_id)
                            .then(()=>{
                                this.loadGraph();
                                this.$nextTick(()=>{
                                    if(this.isRefreshing){
                                        this.graphOnClick(this.selectedLabel);
                                        this.isRefreshing = false;
                                    }
                                })
                                
                            })
                        })
                    })
                    .catch(()=>{
                        alert('Mapping save error.')
                    })
                    break;
                }
                case 'bar':{
                    let params = {
                        client_app_sheet_id: this.graph_mapping.sheet_id,
                        render_view_id: this.selected_view_id,
                        tab_name: this.graph_mapping.tab,
                        sheet_co_ords: this.graph_mapping.source,
                        mapped_cell_id: this.editData.bar.id + '|'+ this.editBar.value,
                        data_type: '',
                        data_source: 'spreadsheet',
                    }
                    this.saveViewMapping(params)
                    .then(()=>{
                        Swal.fire({ toast: true, position: 'top', iconColor: 'white', icon: 'success', title:'Saved Mapping', customClass: {     popup: 'colored-toast' }, showConfirmButton: false, timer: 2000, timerProgressBar: true})
                        this.getViewGroups(this.selected_app.id)
                        .then(()=>{
                            this.$eventHub.$emit('displaySelectedView', this.selectedViewData.id)
                            this.setSelectedView(this.selected_view_id)
                            .then(()=>{
                                this.loadGraph();
                                this.$nextTick(()=>{
                                    if(this.isRefreshing == false){
                                        this.graphOnClick(this.selectedLabel);
                                        
                                    }
                                    else{
                                        this.isRefreshing = false;
                                    }
                                    
                                })
                                
                            })
                        })
                    })
                    .catch(()=>{
                        alert('Mapping save error.')
                    })
                }
                break;
            }
        },

        graphOnClick(id){
            if(this.config.config_json.type == 'pie'){
                this.editData.bar = null;

                let index = _.findIndex(this.config.config_json.data, {id:Number(id)})
                this.editData.pie = this.config.config_json.data[index];
                this.selectedLabel = id;
                this.displayMapping(id);
            }
            else{
                this.editData.pie = null;
                this.editBar.value = '';
                let index = _.findIndex(this.config.config_json.data, {id:Number(id)})
                this.editData.bar = this.config.config_json.data[index];
                this.selectedLabel = id;
            }
            if(!this.graph.expanded){
                //this.toggleCollapseMenu('graph');
            }
            
        },
        lockMapping(){
            if(this.isLocked){
                this.isLocked = false;
            }
            else{
                this.isLocked = true;
                this.lockedMapping = _.cloneDeep(this.graph_mapping);

            }
        },
        displayOverlayMapping(id){
            var index = -1;

            var item_id = id;
            if(typeof id == 'number'){
                item_id = id.toString();
            }

            item_id += ('|magic_data')
            index = _.findIndex(this.config.mapping,{mapped_cell_id: item_id})
            if(index != -1){
                this.graph_mapping.tab = this.config.mapping[index].tab_name;
                this.graph_mapping.sheet_id = this.config.mapping[index].client_app_spreadsheet_id;
                this.graph_mapping.sheet_name = '';
                this.graph_mapping.source = this.config.mapping[index].sheet_coordinates;
                if(this.isLocked){
                    this.graph_mapping.tab = this.lockedMapping.tab;
                    this.graph_mapping.sheet_id = this.lockedMapping.sheet_id;
                    this.graph_mapping.sheet_name = this.lockedMapping.sheet_name;
                }
            }
            else{
                this.graph_mapping.source = '';

                if(this.isLocked){
                    this.graph_mapping.tab = this.lockedMapping.tab;
                    this.graph_mapping.sheet_id = this.lockedMapping.sheet_id;
                    this.graph_mapping.sheet_name = this.lockedMapping.sheet_name;
                }
                else{
                    this.graph_mapping.tab = '';
                    this.graph_mapping.sheet_id = '';
                    this.graph_mapping.sheet_name = '';
                }
            }
        },
        displayMapping(id){
            var item_id = id;
            if(typeof id == 'number'){
                item_id = id.toString();
            }
            var index = -1;
            if(this.config.config_json.type == 'pie'){
                index = _.findIndex(this.config.mapping,{mapped_cell_id: item_id})
                if(index != -1){
                    this.graph_mapping.tab = this.config.mapping[index].tab_name;
                    this.graph_mapping.sheet_id = this.config.mapping[index].client_app_spreadsheet_id;
                    this.graph_mapping.sheet_name = '';
                    this.graph_mapping.source = this.config.mapping[index].sheet_coordinates;
                }
            }
            else{
                item_id += ('|'+this.editBar.value)
                index = _.findIndex(this.config.mapping,{mapped_cell_id: item_id})
                if(index != -1){
                    this.graph_mapping.tab = this.config.mapping[index].tab_name;
                    this.graph_mapping.sheet_id = this.config.mapping[index].client_app_spreadsheet_id;
                    this.graph_mapping.sheet_name = '';
                    this.graph_mapping.source = this.config.mapping[index].sheet_coordinates;
                    if(this.isLocked){
                        this.graph_mapping.tab = this.lockedMapping.tab;
                        this.graph_mapping.sheet_id = this.lockedMapping.sheet_id;
                        this.graph_mapping.sheet_name = this.lockedMapping.sheet_name;
                    }
                }
                else{
                    this.graph_mapping.source = '';

                    if(this.isLocked){
                        this.graph_mapping.tab = this.lockedMapping.tab;
                        this.graph_mapping.sheet_id = this.lockedMapping.sheet_id;
                        this.graph_mapping.sheet_name = this.lockedMapping.sheet_name;
                    }
                    else{
                        this.graph_mapping.tab = '';
                        this.graph_mapping.sheet_id = '';
                        this.graph_mapping.sheet_name = '';
                    }
                }
            }
            
        },
        insertGraphOverlay(payload){
            var orientation = payload[0];
            var data = payload[1];
            if(orientation == 'vertical'){
                data.config_json.xAxis['start'] = 0;
                data.config_json.xAxis['step'] = 1;
                data.config_json.xAxis['end'] = data.config_json.data.length;
            }
            else{
                data.config_json.yAxis['start'] = 0;
                data.config_json.yAxis['step'] = 1;
                data.config_json.yAxis['end'] = data.config_json.data.length;
            }
            
            //console.log(data)
            this.config = data;
            this.$bvModal.hide('overlayGraph');
            this.softRefresh();
        },
        addSeries(name, color){
            var newIndex = this.config.config_json.series.length+1;
            this.config.config_json.series.push({color: color, value: `#value${newIndex}#`});
            this.config.config_json.legend.values.push({color: color, text: name});
            this.config.config_json.data.forEach(group => {
                group[`value${newIndex}`] = 5;
            })
            this.saveGraphConfig();
        },
        deleteSeries(idx){
            Swal.fire({
                title: "Are you sure you want to delete this graph series?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!"
            }).then(result => {
                if (result.value) {
                    this.config.config_json.series.splice(idx, 1);
                    this.config.config_json.legend.values.splice(idx, 1);
                    this.config.config_json.data.forEach(item => {
                        delete item[`value${idx+1}`]
                    })
                    //delete this.config.config_json.data[`value${idx+1}`]
                    this.saveGraphConfig();
                }
            });
        },
        addBar(){
            this.new_bar.id = window.webix.uid();
            var legend_length = this.config.config_json.legend.values.length;
            for(var i = 1; i <= legend_length; i++ ){
                this.new_bar['value'+i] = i;
            }
            this.config.config_json.data.push(this.new_bar);
            this.new_bar = {};
            this.new_bar['id'] = '';
            this.new_bar['label'] = '';
            this.saveGraphConfig();
            this.$refs.dropdown2.hide(true)
        },
        deleteBar(idx){
            Swal.fire({
                title: "Are you sure you want to delete this bar?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!"
            }).then(result => {
                if (result.value) {
                    this.config.config_json.data.splice(idx, 1);
                    //delete this.config.config_json.data[`value${idx+1}`]
                    this.saveGraphConfig();
                }
            });
        },
        selectBarGroup(group_id){
            this.editData.pie = null;
            this.editBar.value = '';

            let index = _.findIndex(this.config.config_json.data, {id:Number(group_id)})
            this.editData.bar = this.config.config_json.data[index];
            this.selectedLabel = group_id;
        },

        selectOverlayValue(item){
            this.selectedOverlayID = item.id;
            let index = _.findIndex(this.config.config_json.data, {id:Number(item.id)});
            if(index != -1){

                let data_group = this.config.config_json.data[index]
                this.editOverlay.value = data_group.magic_data;
                //TODO: Check if mapping is locked.
                this.displayOverlayMapping(data_group.id);  
            }
        },
        selectBarValue(bar){
            this.editBar.value = bar;
            this.displayMapping(this.editData.bar.id);
            if(this.isLocked){
                this.$nextTick(()=>{
                    this.$refs.currentCellMappingInput.focus();
                })
            }
        },
        toggleSidePadding(side){
            if(this.config.config_json.padding == undefined){
                this.config.config_json.padding = {};
            }
            
            if(this.showPadding[side]){
                this.showPadding[side] = false;
                delete this.config.config_json.padding[side];
            }
            else{
                this.showPadding[side] = true;
                this.config.config_json.padding[side] = 30;
            }
        },

        selectedViewEvent(){
            //*reset config variables and then load graph
            this.graph_mapping = {
                tab:'',
                sheet_id:'',
                sheet_name:'',
                source:'',
            }
            this.editData = {
                pie: null,
                bar: null,
            }
            this.editBar.value = '';
            this.selectedLabel = -1,
            this.loadGraph();
        },

        updatedViewEvent(){
            this.isRefreshing = true;
            this.loadGraph();
        },

        returnTabName(name){
            if(name != ''){
                return name;
            }
            else{
                return 'Select Tab';
            }
        },
        returnSheetNameFromId(id){
            if(id == ''){
                return 'Select Sheet';
            }
            else{
                var index = _.findIndex(this.spreadsheets, {id: id});
                if(index != -1){
                    return this.spreadsheets[index].name;
                }
            }
        },
        upperCase(value){
            return _.toUpper(value);
        },

        showSelectedLabel(id){
            if(id == Number(this.selectedLabel)){
                return 'success';
            }
            else{
                return 'primary';
            }
        },
        showSelectedOverlayItem(id){
            if(id == this.selectedOverlayID){
                return 'success';
            }
            else{
                return 'primary';
            }
        },
        showSelectedBar(bar){
            if(bar == this.editBar.value){
                return 'success';
            }
            else{
                return 'primary';
            }
        },

        openGraphWizardModal(){
            this.showGraphWizardModal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('graphWizardModal');
                this.$root.$once('bv::modal::hidden',(event)=>{
                    if(event.type == 'hidden' && event.componentId == 'graphWizardModal'){
                        this.showGraphWizardModal = false;
                        //this.showTable = true;
                    }
                })
            })
        },
        currentGraphType(){
            var type = false;
            if(this.config.config_json){
                if(this.config.config_json.type){
                    type = this.config.config_json.type
                }
            }
            return type;
        }
    },
    data:()=>({
        showOverlayValues: false,
        isLocked: false,
        lockedMapping:{
            tab:'',
            sheet_id:'',
            sheet_name:'',
            source:'',
        },
        isRefreshing: false,
        showGraphWizardModal: false,
        isSaving: false,

        showGraphOverlayConfig: false,

        selectedLabel:-1,
        selectedLegend: null,

        showGraph: false,
        graph_mapping:{
            tab:'',
            sheet_id:'',
            sheet_name:'',
            source:'',
        },
        new_series:{
            color: '',
            text: '',
        },
        new_bar:{
            id:'',
            label: '',
        },
        edit_legend:{
            color: '',
            text: '',
        },
        config: null,
        properties:{
            expanded: false,
        },
        overlay:{
            expanded: false,
        },
        graph:{
            expanded: true,
        },
        legend:{
            expanded: false,
        },
        editData:{
            pie:null,
            bar: null,
        },
        editBar:{
            value: '',
        },
        editOverlay:{
            value: '',
        },
        available_tabs: [
                'Scorecard', 'OneScore',
                'BaseData','Yes','MCTable','TargetsV','SpendTables',
                'SpendTables(YES)','SpendTables(NonY)','BCDV',
                'SpendV','TMPSV','OneTarV','LevAmtV'
            ],

        showPadding:{
            top: false,
            bottom: false,
            left: false,
            right: false,
        },
        selectedOverlayID: null,


        data_series_length: null,
    }),
    mounted(){
        //this.loadGraph();
        this.$eventHub.$on('selectedView', this.selectedViewEvent);
        this.$eventHub.$on('updatedView', this.updatedViewEvent);//
        this.$eventHub.$on('addedOverlayGraph', this.insertGraphOverlay);
        setTimeout(()=>{
            if(this.graphName == false){
                this.loadGraph();
            }
        }, 200);
    },
    beforeDestroy(){
        this.$eventHub.$off('selectedView');
        this.$eventHub.$off('updatedView');
        this.$eventHub.$off('addedOverlayGraph');
    }
}
</script>

<style>
.webix_canvas_text.webix_axis_item_y{
    
}
</style>