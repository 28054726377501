<template>
    <div>
        <b-overlay :show="isLoading">
            <div v-if="hasPages">
                <div v-if="showPages" style="padding-left: 1rem; padding-right: 1rem;">
                    <page-element v-for="page in selected_report.builder_pages" :key="page.id" :layout="selected_report.layout" :page="page"></page-element>
                </div>
            </div>
        </b-overlay>
        <div v-if="showEditor">
            <page-editor :page="selected_page"></page-editor>
        </div>
    </div>
</template>

<script>
import PageEditor from './pageEditor.vue'
import PageElement from './page-element.vue'
import { clientViewMethods, clientViewComputed, clientComputed, clientMethods } from '@/state/helpers'
export default {
    data:()=>({
        showEditor: false,
        selected_page:null,
        isLoading: false,
        showPages: true,
    }),
    props:{
        report:{},
    },
    components:{
        PageElement,
        PageEditor
    },
    methods:{
        ...clientViewMethods,
        ...clientMethods,


        addNewPage(){
            this.isLoading = true;
            var report_id = this.selected_report.id;
            var params = {
                id: -1,
                client_app_report_id: report_id,
                page_number: this.selected_report.builder_pages.length + 1,
                page_content: '<p>Empty</p>'
,
            }
            this.saveReportPage(params)
            .then( () => {
                //this.getReports(this.selected_app.id)
                //.then(()=>{
                    this.$eventHub.$emit('refreshReportPreview', report_id);
                //})
            })
            .finally( () => {
                this.isLoading = false;
            })
        },

        showEditorModal(page){
            this.selected_page = page;
            this.showEditor = true;
            this.$nextTick(()=>{
                this.$bvModal.show('editor')
                this.$root.$once('bv::modal::hidden',(event)=>{
                    if(event.type == 'hidden' && event.componentId == 'editor'){
                        this.showEditor = false;
                    }
                })
            })
        },
        refreshPages(){
            this.showPages = false;
            this.$nextTick(()=>{
                this.showPages = true;
            })
        }
    },
    computed:{
        ...clientViewComputed,
        ...clientComputed,
        hasPages(){
            if(this.selected_report.builder_pages.length > 0){
                return true;
            }
            else{ return false }
        }
    },
    mounted(){
        this.$eventHub.$on('refreshBuilderPages', this.refreshPages);
        this.$eventHub.$on('showEditor', this.showEditorModal);
        this.$eventHub.$on('addNewPage', this.addNewPage);
    },
    beforeDestroy(){
        this.$eventHub.$off('refreshBuilderPages');
        this.$eventHub.$off('showEditor');
        this.$eventHub.$off('addNewPage');
    }
}
</script>

<style>

</style>