<template>
    <div class="mb-4">
        <div @click="showDropdown = !showDropdown" style="height: 2rem; width: 100%; background-color: #ededed;" class="pageCollapseHeading mb-0">
            {{page.pag_number}}
            <b-icon style="float:right;" font-scale="2.2" :icon="showDropdown ? 'dash':'plus'"></b-icon>
        </div>
        <div :class="showDropdown ? 'expandedStyle' : 'collapsedStyle'" ref="page" @click="openEditor(page)" :style="pageLayout" class="pageHover w-100 page-element" style="padding: 0!important; border: 1px solid grey; cursor: pointer;">
            <div class="pageElement" v-show="showDropdown" v-html="page.page_content" :id="'reportPage'+page.id"></div>
        </div>
    </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import * as Quill from 'quill'

export default {
    props:{
        layout:{},
        page:{}
    },
    data:()=>({
        editor: null,
        showDropdown: true,
    }),
    computed:{
        pageLayout(){
            if(this.layout == 'portrait'){
                return 'padding: 0.2cm; padding-left: 1cm; padding-right: 1cm; ';//max-height: 295mm;
            }
            if(this.layout == 'landscape'){
                return 'padding: 0.2cm; padding-left: 1cm; padding-right: 1cm; height: 206mm;';
            }
            else{
                return '';
            }
        }
    },
    methods:{
        openEditor(page){
            this.$eventHub.$emit('showEditor', page)
        },
        initQuill(){
            return new Promise((resolve) => {
                var options = {
                    placeholder: '',
                    theme: 'bubble',
                    readOnly: true,
                };
                var container = document.getElementById(`reportPage${this.page.id}`)
                this.editor = new Quill(container, options);

                resolve();
            })
        },
        createQuill(){
            if(this.editor == null){
                this.initQuill()
                .then(()=>{
                    this.editor.root.innerHTML = this.page.page_content;
                })
            }
            else{
                this.editor.root.innerHTML = this.page.page_content;
            }
        }
    },
    mounted(){
        
        this.createQuill();
        //this.$refs.page.innerHTML = this.page.page_content
    },
    beforeDestroy(){
        this.$eventHub.$off('refreshBuilderPages');
    }
}
</script>

<style>
.pageHover{
    padding: 0 !important;
    box-shadow: 0 0.7rem 1.5rem rgb(18 38 63 / 10%);
    transition: all 0.2s linear;
}
.pageHover:hover{
    box-shadow: 0 0.7rem 1.5rem rgb(18 38 63 / 25%);
    transition: all 0.3s linear;
}
.pageCollapseHeading:hover{
    cursor: pointer;
    background-color: rgb(180, 183, 231) !important;
    transition: all 0.15s linear;
}
.pageCollapseHeading{
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
}

.pageElement > .ql-editor{
    line-height: 1.2;
    
    font-size: 1rem;
    max-height: 25rem;
}

.collapsedStyle{
    padding: 1rem;
}
</style>