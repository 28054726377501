<template>
<div>
    <b-row v-if="currentViewType == 'none'">
        <b-col cols="9">
            <div class="column-div">
                <div style="text-align: center; margin: 20px;">
                    <h4>No View Selected</h4>
                </div>
            </div>
        </b-col>
    </b-row>
    <table-display v-if="currentViewType == 'table'"></table-display>
    <data-table-display v-if="currentViewType == 'data_table'"></data-table-display>
    <dynamic-display v-if="currentViewType == 'dynamic_text'"></dynamic-display>
    <graph-display v-if="currentViewType == 'graph'"></graph-display>
    <form-builder v-if="currentViewType == 'form'" />
    <grouped-views :group="currentGroup" v-show="currentViewType == 'group_table'"></grouped-views>
</div>
</template>

<script>
import TableDisplay from './components/table/tableDisplay.vue'
import DataTableDisplay from './components/table/data-table/tableDisplay.vue'
import DynamicDisplay from './components/dynamic/dynamicDisplay.vue'
import GraphDisplay from './components/graph/graphDisplay.vue'
import GroupedViews from './components/viewGroups/groupsDisplay.vue'
import FormBuilder from '../form-builder/form-builder.vue'

export default {
    components:{
        TableDisplay,
        DynamicDisplay,
        GraphDisplay,
        GroupedViews,
        FormBuilder,
        DataTableDisplay
    },
    data:()=>({
        currentViewType: 'group_table',//'none',
        currentGroup:'',
    }),
    methods:{
        changeViewTypeEvent(type){
            this.currentViewType = type;
            if(type == 'group_table'){
                this.$eventHub.$emit('runSizeCheck')
                this.$eventHub.$emit('runFilterCheck')
            }
        },
        changedViewGroupEvent(id){
            this.currentGroup = id;
        }
    },
    mounted(){
        this.$eventHub.$on('changeSelectedViewGroup', this.changedViewGroupEvent);
        this.$eventHub.$on('changeSelectedViewType', this.changeViewTypeEvent);
    },
    beforeDestroy(){
        this.$eventHub.$off('changeSelectedViewType');
        this.$eventHub.$off('changeSelectedViewGroup');
    }
}
</script>

<style>

</style>