<template>
    <div>
        <div v-if="showLayout">
            <b-row>
                <b-col cols="9">
                    <div>
                        <div class="card column-div">
                            <div class="left-heading" style="display: flex">
                                <div style="margin: 0px; width: 75%;">
                                    <b-button-group style="margin: 2px;">
                                        <b-button size="lg" style="color: black; background: #92d36e;" class="btnThin" variant="outline-dark" >Report View</b-button>
                                        <b-button size="lg" class="btnThin" variant="outline-primary" >Page View</b-button>
                                    </b-button-group>

                                    <b-button-group style="margin: 2px;">
                                        <b-button size="lg" style="" :style="isPreviewMode(false)" v-on:click="showPreview = false;" class="btnThin" variant="outline-dark" >Design</b-button>
                                        <b-button size="lg" class="btnThin" :style="isPreviewMode(true)" v-on:click="previewReport()" variant="outline-dark" >Preview</b-button>
                                    </b-button-group>

                                    <b-button size="lg" @click="$eventHub.$emit('addNewPage')" v-if="showPreview == false" class="btnThin" style="margin: 2px;" variant="outline-primary" >Add Page</b-button>
                                    <b-button-group style="margin: 2px;">
                                        <b-button size="lg" class="btnThin" variant="outline-primary" >+</b-button>
                                        <b-button size="lg" class="btnThin" variant="outline-primary" >Zoom</b-button>
                                        <b-button size="lg" class="btnThin" variant="outline-primary" >-</b-button>
                                    </b-button-group>


                                    <h1  style="margin-top: 10px;" v-if="isLoading">Loading preview.. <b-spinner label="Spinning"></b-spinner></h1>
                                </div>
                            </div>
                            <b-row v-if="showPreview">
                                <b-col>
                                    <div class="col-sm-12">
                                        <iframe :src="'data:application/pdf;base64,' + previewBase64" style="width:100%; height:700px;" frameborder="0"></iframe>
                                    </div>
                                </b-col>
                            </b-row>
                            <div v-else style="overflow: hidden overlay; height: 46rem;" class="p-4">
                                    <page-container v-if="isReportType('builder')" :report="getSelectedReport"></page-container>
                                <!--<b-row v-for="view in selected_report.render_views" :key="view.id" style="margin-bottom: 10px;">
                                    <b-col cols="12">
                                        <pre>{{view}}</pre>
                                    </b-col>
                                </b-row>
                                -->
                                    <div v-if="isReportType('template')"></div>
                            </div>
                        </div>
                    </div>
                    
                        
                </b-col>
                <b-col>
                    <div class="card column-div" style="min-height: 90%;">
                        <b-overlay :show="isLoading">
                        <b-row v-if="!showLayout">
                            <b-col>
                                <h5>No Report Selected</h5>
                            </b-col>
                        </b-row>
                        <b-row v-else style="margin-bottom: 10px;">
                            <b-col>
                                <div class="column-heading"> <!--  -->
                                    <h4 style="margin: 0px;">Configuration <b-icon v-on:click="configuration.expanded = !configuration.expanded" style="float: right;" class="border border-dark rounded" :icon="configuration.expanded ? 'dash' : 'plus'"></b-icon></h4>
                                </div>
                                <b-collapse id="accordion-styles" v-model="configuration.expanded">
                                    <b-form class="p-1">
                                        <b-row class="btnRow">
                                            <b-col cols="3">
                                                <label for="reportName">Report Name</label>
                                            </b-col>
                                            <b-col>
                                                <b-form-input id="reportName" v-model="report.name"></b-form-input>
                                            </b-col>
                                        </b-row>
                                        <b-row class="btnRow">
                                            <b-col cols="3">
                                                <label class="col-form-label" for="template">Template</label>
                                            </b-col>
                                            <b-col>
                                                <b-form-input id="template" readonly v-model="getTemplateName"></b-form-input>
                                            </b-col>
                                            <b-col cols="2">
                                                <div @click="editReport(report.id)" title="Open Report Edit Modal" style="height: 100%; display: flex; align-items: center; justify-content: center;  border: 0px; border-radius: 0.3em; background:lightGrey; cursor:pointer;">
                                                    <b-icon icon="file-earmark-code-fill"></b-icon>
                                                </div>
                                            </b-col>
                                        </b-row>
                                        <b-row class="btnRow">
                                            <b-col cols="3">
                                                <label class="col-form-label" for="template">Layout</label>
                                            </b-col>
                                            <b-col>
                                                <b-form-select class="w-50 form-select" v-model="report.layout" :options="layoutOpts"></b-form-select>
                                            </b-col>
                                        </b-row>
                                        <b-row class="btnRow">
                                            <b-col>
                                                <uploader :autoStart="false" :ref="'template_uploader'" :options="renderUploadOptions()" class="uploader-example">
                                                    <uploader-unsupport></uploader-unsupport>
                                                    <uploader-drop style="background-color:rgb(237 255 239);">
                                                        <b-row>
                                                            <b-col cols="7">
                                                                <p style="margin-top: 5px ;margin-bottom: 0;">Drop files here to upload or</p>
                                                            </b-col>
                                                            <b-col cols="5">
                                                                <uploader-btn style="margin-bottom: 0;">Select file</uploader-btn>
                                                            </b-col>
                                                        </b-row>
                                                        
                                                        
                                                    </uploader-drop>
                                                    <uploader-list></uploader-list>
                                                </uploader>
                                            </b-col>
                                        </b-row>
                                        <b-row class="btnRow">
                                            <b-col>
                                                <b-button @click="save" variant="success">Save</b-button>
                                            </b-col>
                                        </b-row>
                                    </b-form>
                                </b-collapse>
                                <div class="column-heading"> <!--  -->
                                    <h4 style="margin: 0px;">Components <b-icon v-on:click="components.expanded = components.expanded" class="border border-dark rounded" style="float: right;" :icon="components.expanded ? 'dash' : 'plus'"></b-icon></h4>
                                </div>
                                <b-collapse id="accordion-styles" v-model="components.expanded">
                                    <b-row>
                                        <draggable :list="this.components.types"
                                        :group="{ name: 'viewType', pull: 'clone', put: false }">
                                        <b-col cols="12" v-for="type in components.types" :key="type.id">
                                            {{type.name}}
                                        </b-col>
                                        </draggable>
                                    </b-row>
                                </b-collapse>
                            </b-col>
                        </b-row>
                        </b-overlay>
                    </div>
                </b-col>
            </b-row>
            <div>
                
            </div>
        </div>
        <div v-else>
            <div class="card column-div text-center" style="padding: 6rem;">
                <b-row>
                    <b-col cols="6">
                        <h2>Select A Report</h2>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
import PageContainer from './components/pageContainer.vue'
import Swal from 'sweetalert2'
import _ from 'lodash'
import draggable from 'vuedraggable'
import { clientViewMethods, clientViewComputed, clientComputed, clientMethods } from '@/state/helpers'
export default {
    components:{
        draggable,
        PageContainer
    },
    data:()=>({
        isLoading: false,
        showPreview: false,
        previewBase64: "",
        report:{},
        showLayout: false,
        report_elements:[
            /*{
                id: 0,
                name: 'Introduction',
                description: 'Some text here...',
                expanded: true,
                elements:[],
            },
            {
                id: 1,
                name: 'Ownership',
                description: 'Some other unrelated here...',
                expanded: false,
                elements:[],
            },*/
        ],
        viewType: 'page', // page OR report


        configuration:{
            expanded: true,
            report_name: '',
            template: '',
        },
        components:{
            expanded: true,
            types:[
                {id:0, name: 'Static Text', value:'static_text'},
                {id:1, name: 'Table', value:'table'}
            ]
        },
        layoutOpts: [
            {value: 'portrait', text: 'Portrait'},
            {value: 'landscape', text: 'Landscape'}
        ],
    }),
    methods:{
        ...clientViewMethods,
        ...clientMethods,

        selectedReportEvent(){
            var enablePreview = this.showPreview;

            this.showPreview = false;
            if(this.selected_report != {}){
                this.report = _.cloneDeep(this.selected_report)
                this.showLayout = true;

                if(enablePreview){
                    this.previewReport();
                }
            }
        },
        editReport(id){
            this.$eventHub.$emit('openEditReportModal', id);
        },
        isPreviewMode(state){
            if(this.showPreview == state){
                return 'color: black; background: #92d36e;'
            }
            else{
                return ''
            }
        },
        isReportType(type){
            if(this.report.comfig_type == type){
                return true
            }
            else{
                return false
            }
            
        },
        previewReport: function() {
            this.isLoading = true;
            this.showPreview = false;
            window.axios.post('api/clientapp/previewreport', {id: this.report.id})
            .then((response)=>{
                // eslint-disable-next-line no-console
                //console.log(response.data);
                this.previewBase64 = response.data.b64;
                this.$nextTick(() => {
                    this.showPreview = true;
                    this.isLoading = false;
                });
            })
            .catch((e)=>{
                // eslint-disable-next-line no-console
                console.log(e);
                this.isLoading = false;
                this.previewBase64 = '';
            })
        },

        refreshPreviewEvent(id){
            console.log(id)
            this.loadReports();
            if(this.selected_report.config_type == 'builder'){
                console.log('IS BUILDER REPORT:', this.selected_report)
                this.$eventHub.$emit('refreshBuilderPages');
            }
            if(this.showPreview){
                this.previewReport();
            }
        },

        //* UPLOADER
        save(){
            Swal.fire({toast: true, position: 'top', iconColor: 'white',    title:'Saving...',    customClass: {popup: 'toast'    },    showConfirmButton: false,    timer: 2000,    timerProgressBar: true})
            this.saveReport(this.report)
            .then((id)=>{
                this.report.id = id;
                this.$nextTick(()=>{
                    this.processUpload()
                    .then(()=>{
                        Swal.fire({
                            toast: true,
                            position: 'top',
                            iconColor: 'white',
                            icon: 'success',
                            title:'Saved Report',
                            customClass: {
                                popup: 'colored-toast'
                            },
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true
                        })
                        //this.$bvModal.hide('addReportModal');
                        this.getReports(this.selected_app.id)
                        .then(()=>{
                            this.$eventHub.$emit('refreshReportPreview', id);
                        })
                    })
                    .catch(()=>{
                        Swal.fire({
                            toast: true,
                            position: 'top',
                            iconColor: 'red',
                            icon: 'error',
                            title:'Error uploading template file',
                            customClass: {
                                popup: 'error-toast'
                            },
                            showConfirmButton: false,
                            timer: 4000,
                            timerProgressBar: true
                        })
                        
                    })
                })

            })
        },
        processUpload(){
            return new Promise((resolve, reject)=>{
                const uploaderInstance = this.$refs['template_uploader'];
                const upload = uploaderInstance.uploader;
                upload.opts.query.client_app_report_id = this.report.id;
                upload.resume();

                //eslint-disable-next-line no-unused-vars
                upload.on('fileSuccess', (rootFile, file, message, chunk)=>{
                    var response = JSON.parse(message);
                    if(response.success){
                        //eslint-disable-next-line no-console
                        console.log("Upload success", file);
                        this.$eventHub.$emit('loadReports');
                        resolve();
                    }
                    else {
                       //eslint-disable-next-line no-console
                        console.log("Upload error");
                        reject();
                    }
                })
                setTimeout(()=>{
                    upload.off("fileSuccess");
                    upload.cancel();
                },2000)
            })

        },
        renderUploadOptions() {
            var storage = window.localStorage;
            var AUTH_TOKEN = storage.getItem('x_token');
            var url = process.env.VUE_APP_API_BASEURL + 'api/clientapp/uploadtemplate';
            var options = {
                target: url,
                query: {
                    client_app_report_id: this.report.id,
                },
                headers: {
                    'Access-Control-Allow-Credentials': true,
                    'X-Requested-With': 'XMLHttpRequest',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer ' + AUTH_TOKEN,
                },
                testChunks: false,
                fileParameterName: 'template', //Set the upload post param which will contain the binary payload
                singleFile: true, //Limit upload to one file
            }
            return options;
        },
    },
    computed:{
        ...clientViewComputed,
        ...clientComputed,
        getTemplateName(){
            if(this.report.template == null){
                return 'No template loaded';
            }
            else{
                return 'template.'+this.report.template.id;
            }
        },
        getSelectedReport(){
            if(this.selected_report != null){
                return this.selected_report;
            }
            else{
                return null;
            }
        }
    },
    mounted(){
        this.$eventHub.$on('selectedNewReport', this.selectedReportEvent)
        this.$eventHub.$on('refreshReportPreview', this.refreshPreviewEvent);
    },
    beforeDestroy(){
        this.$eventHub.$off('selectedNewReport');
        this.$eventHub.$off('refreshReportPreview');
    }
}
</script>

<style>

</style>