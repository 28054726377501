<script>
import Layout from "../../layouts/main";
//import PageHeader from "@/components/page-header";
//import BuilderUI from '../../../components/builder/builderLayout.vue';
import BuilderMain from '../../../components/builder/builderMain.vue';
import { 
    clientMethods, clientComputed, clientViewMethods, clientViewComputed,
} from '@/state/helpers'

import router from "@/router";


/**
 * Dashboard Component
 */
export default {
    name: 'ClientApp',
    page: {
        title: "Dashboard",
    },
    components: {
        Layout,
        //PageHeader,
        //BuilderUI,
        BuilderMain
    },
    mounted(){
        if(this.selected_app == null){
            router.push('/');
        }
    },
    computed:{
        ...clientComputed,
        ...clientViewComputed,

        showBuilder(){
            var show = false;
            if(this.selected_app != null){
                show = true;
            }
            return show;
        },

        showSaveButton(){
            if(this.newApp.name == '' || this.newApp.tag == ''){
                return false;
            }
            else{
                return true;
            }
        },
    },
    methods:{
        ...clientMethods,
        ...clientViewMethods,

        saveApp(){
            this.isSaving = true;
            this.saveNewApp(this.newApp)
            .then(()=>{
                this.newApp = { id: -1, name:'', tag:''};
                this.loadApps();
                this.isSaving = false;
                //this.$refs.newAppForm.hide(true);
                this.showAddApp = false
            })
            .catch(()=>{
                this.isSaving = false;
            })
        },
    },
    data() {
        return {
            id:null,

            showAddApp: false,
            newApp:{
                id: -1,
                name: '',
                tag: '',
            },
            isSaving: false,
        };
    }
};
</script>

<template>
    <Layout>
        <div v-if="showBuilder">
            <!--<PageHeader :title="title" :items="items" style="padding: 0px; margin: 0px; height:0px; width: 0px;" />-->
            <!--<BuilderUI v-if="showElement == 1" />-->
            <BuilderMain/>
        </div>
        <div v-else class="text-center">
            
            <div v-if="showAddApp == false">
                <b-row class="mt-4">
                    <b-col cols="3"></b-col>
                    <b-col cols="6">
                        <div class="card cardHover">
                            <div class="card-body">
                                <h1 style="margin-top: 2rem;" class="mb-4">
                                    Select an <strong>app</strong> to get started.
                                </h1>
                                <h1 style="margin-top: 2rem;">
                                    or
                                </h1>
                                <b-button v-on:click="showAddApp = true;" class="addItemDropdown" size="lg" ref="newAppForm" variant="outline-primary" style="margin-top: 2rem;">
                                    <b-icon style="margin-right: 0.2em;" font-scale="2" icon="plus-square"></b-icon>
                                        Create New App
                                </b-button>
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="3"></b-col>
                </b-row>
                <!--
                <h1 style="margin-top: 2rem;">
                    Select an <strong>app</strong> to get started.
                </h1>
                <h1 style="margin-top: 2rem;">
                    or
                </h1>
                <b-button v-on:click="showAddApp = true;" class="addItemDropdown" size="lg" ref="newAppForm" variant="outline-primary" style="margin-top: 2rem;">
                    <b-icon style="margin-right: 0.2em;" font-scale="2" icon="plus-square"></b-icon>
                        Create New App
                </b-button>
                -->
            </div>
            <div v-else>
                <b-form style="margin-top: 2rem; display: flex; align-items: center; justify-content: center;">
                    <b-overlay style="padding: 6px; border: 1px solid black; border-radius: 6px;" :show="isSaving">
                        <b-form-group submit="save">
                            <b-row class="btnRow">
                                <b-col style="text-align: left; margin-top: 0.5em;" cols="2">
                                    <label for="">Name</label>
                                </b-col>
                                <b-col>
                                    <b-form-input v-model="newApp.name"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="btnRow">
                                <b-col style="text-align: left; margin-top: 0.5em;" cols="2">
                                    <label for="">Tag</label>
                                </b-col>
                                <b-col>
                                    <b-form-input v-model="newApp.tag"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-form-group>
                        <hr>
                        <div style="text-align:center;">

                            <b-button v-if="showSaveButton" v-on:click="saveApp" variant="success">Save</b-button>
                            <b-button v-else disabled v-on:click="saveApp" variant="success">Save</b-button>
                            &nbsp;
                            <b-button v-on:click="showAddApp = false;" variant="warning">Back</b-button>
                        </div>
                    </b-overlay>
                </b-form>
            </div>
        </div>
    </Layout>
</template>

<style>

</style>
