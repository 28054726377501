<template>
    <b-modal id="graphWizardModal" ref="graphWizardModal" name="graphWizardModal" size="" hide-footer title="Graph Wizard" style="padding: 0px;">
        <b-overlay :show="isSaving">
        <div class="card" style="margin: 0px;">
            <div class="card-body" style="padding: 0px;">
                <form-wizard @on-change="onChange" @on-complete="onComplete" shape="tab" color="#556ee6">
                    <tab-content icon="mdi mdi-plus-outline" :before-change="hasGraphSelected">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group row mb-3">
                                    <label class="col-md-3 col-form-label" for="name">Graph Type</label>
                                    <div class="col-md-9">
                                        <select id="type" type="text" class="form-control" v-model="graph.type" style="cursor:pointer;" >
                                            <option disabled value="">Select a graph type</option>
                                            <option value="pie">Pie</option>
                                            <option value="bar">Bar</option>
                                            <option value="barH">Bar (Horizontal)</option>
                                            <option value="stackedBar">Stacked Bar</option>
                                            <option value="stackedBarH">Stacked Bar (Horizontal)</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12" v-if="hasSeries">
                                <div class="form-group row mb-3">
                                    <label class="col-md-3 col-form-label" for="name">Series</label>
                                    <div class="col-md-3">
                                        <b-form-input readonly v-model="graph.series"></b-form-input>
                                    </div>
                                    <div class="col-md-6">
                                        <b-button v-on:click="setSeriesCount('add')" variant="success"><b-icon scale="4" icon="plus-square"></b-icon></b-button>
                                        &nbsp;
                                        <b-button v-on:click="setSeriesCount('subtract')" variant="warning"><b-icon scale="4" icon="dash-square"></b-icon></b-button>
                                    </div>
                                </div>
                            </div>
                            <!-- end col -->
                        </div>
                        <!-- end row -->
                    </tab-content>
<!--    #################################-->
                    <tab-content icon="mdi mdi-chart-pie" :before-change="hasDataAdded">
                        <div v-if="graph.type == 'pie'">
                            <b-row>
                                <b-col>
                                    <b-form class="form-group row mb-3">
                                        <label class="col-md-2 col-form-label" for="name">Label</label>
                                        <div class="col-md-3">
                                            <b-form-input type="text" v-model="label_text"></b-form-input> 
                                        </div>
                                        <label class="col-md-2 col-form-label" for="color">Color</label>
                                        <div class="col-md-3">
                                            <b-form-input type="color" style="padding: 0.1rem; height: 2.2rem;" name="color" class="" v-model="label_color"></b-form-input>
                                        </div>
                                        <div class="col-md-2">
                                            <b-button variant="success" v-on:click="addLabel"><b-icon icon="plus-square"></b-icon></b-button>
                                        </div>
                                    </b-form>
                                </b-col>
                            </b-row>

                            <hr>

                            <b-row>
                                <b-col>
                                    <div class="form-group row mb-3">
                                        <label class="col-md-3 col-form-label" for="name">series1</label>
                                        <div class="col-md-9 col-form-label" v-if="pie.data.length == 0">
                                            No labels added.
                                        </div>
                                        <div class="col-md-9">
                                            <b-dropdown class="graphLabelItem" size="sm" style="margin-right: 0.2em;" variant="outline-secondary" v-for="(item, idx) in pie.data" :key="idx">
                                                <template #button-content >
                                                    <div  > <!-- :style="setItemColor(item.color)" -->
                                                    {{item.label}}
                                                    </div>
                                                </template>
                                                <b-dropdown-item  v-on:click="removeLabel(idx)">
                                                    Remove
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>

                        <div v-if="graph.type == 'bar'">
                            <b-row>
                                <b-col>
                                    <div class="form-group row mb-3">
                                        <label class="col-md-2 col-form-label" for="name">Label</label>
                                        <div class="col-md-3">
                                            <b-form-input type="text" v-model="label_text"></b-form-input> 
                                        </div>
                                        <div class="col-md-2">
                                            <b-button variant="success" v-on:click="addLabel"><b-icon icon="plus-square"></b-icon></b-button>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>

                            <hr>

                            <b-row>
                                <b-col>
                                    <div class="form-group row mb-3">
                                        <label class="col-md-3 col-form-label" for="name">Labels</label>
                                        <div class="col-md-9 col-form-label" v-if="bar.data.length == 0">
                                            No labels added.
                                        </div>
                                        <div class="col-md-9">
                                            <b-dropdown class="graphLabelItem" size="sm" style="margin-right: 0.2em;" variant="outline-secondary" v-for="(item, idx) in bar.data" :key="idx">
                                                <template #button-content >
                                                    <div>
                                                    {{item.label}}
                                                    </div>
                                                </template>
                                                <b-dropdown-item  v-on:click="removeLabel(idx)">
                                                    Remove
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>

                            <hr>

                            <b-row>
                                <b-col>
                                    <div class="form-group row mb-3">
                                        <label class="col-md-3 col-form-label" for="name">Legend</label>
                                        <!--<div class="col-md-9 col-form-label" v-if="bar.data.length == 0">
                                            No labels added.
                                        </div>-->
                                        <div class="col-md-3" v-for="(legend, idx) in bar.legend.values" :key="idx">
                                            <b-form-input v-model="bar.legend.values[idx].text"></b-form-input>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>

                        <div v-if="graph.type == 'barH'">
                            <b-row>
                                <b-col>
                                    <div class="form-group row mb-3">
                                        <label class="col-md-2 col-form-label" for="name">Label</label>
                                        <div class="col-md-3">
                                            <b-form-input type="text" v-model="label_text"></b-form-input> 
                                        </div>
                                        <div class="col-md-2">
                                            <b-button variant="success" v-on:click="addLabel"><b-icon icon="plus-square"></b-icon></b-button>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>

                            <hr>

                            <b-row>
                                <b-col>
                                    <div class="form-group row mb-3">
                                        <label class="col-md-3 col-form-label" for="name">Labels</label>
                                        <div class="col-md-9 col-form-label" v-if="barH.data.length == 0">
                                            No labels added.
                                        </div>
                                        <div class="col-md-9">
                                            <b-dropdown class="graphLabelItem" size="sm" style="margin-right: 0.2em;" variant="outline-secondary" v-for="(item, idx) in barH.data" :key="idx">
                                                <template #button-content >
                                                    <div>
                                                    {{item.label}}
                                                    </div>
                                                </template>
                                                <b-dropdown-item  v-on:click="removeLabel(idx)">
                                                    Remove
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>

                            <hr>

                            <b-row>
                                <b-col>
                                    <div class="form-group row mb-3">
                                        <label class="col-md-3 col-form-label" for="name">Legend</label>
                                        <!--<div class="col-md-9 col-form-label" v-if="bar.data.length == 0">
                                            No labels added.
                                        </div>-->
                                        <div class="col-md-3" v-for="(legend, idx) in barH.legend.values" :key="idx">
                                            <b-form-input v-model="barH.legend.values[idx].text"></b-form-input>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>

                        <div v-if="graph.type == 'stackedBar'">
                            <b-row>
                                <b-col>
                                    <div class="form-group row mb-3">
                                        <label class="col-md-2 col-form-label" for="name">Label</label>
                                        <div class="col-md-3">
                                            <b-form-input type="text" v-model="label_text"></b-form-input> 
                                        </div>
                                        <div class="col-md-2">
                                            <b-button variant="success" v-on:click="addLabel"><b-icon icon="plus-square"></b-icon></b-button>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>

                            <hr>

                            <b-row>
                                <b-col>
                                    <div class="form-group row mb-3">
                                        <label class="col-md-3 col-form-label" for="name">Labels</label>
                                        <div class="col-md-9 col-form-label" v-if="stackedBar.data.length == 0">
                                            No labels added.
                                        </div>
                                        <div class="col-md-9">
                                            <b-dropdown class="graphLabelItem" size="sm" style="margin-right: 0.2em;" variant="outline-secondary" v-for="(item, idx) in stackedBar.data" :key="idx">
                                                <template #button-content >
                                                    <div>
                                                    {{item.label}}
                                                    </div>
                                                </template>
                                                <b-dropdown-item  v-on:click="removeLabel(idx)">
                                                    Remove
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>

                            <hr>

                            <b-row>
                                <b-col>
                                    <div class="form-group row mb-3">
                                        <label class="col-md-3 col-form-label" for="name">Legend</label>
                                        <!--<div class="col-md-9 col-form-label" v-if="bar.data.length == 0">
                                            No labels added.
                                        </div>-->
                                        <div class="col-md-3" v-for="(legend, idx) in stackedBar.legend.values" :key="idx">
                                            <b-form-input v-model="stackedBar.legend.values[idx].text"></b-form-input>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>

                        <div v-if="graph.type == 'stackedBarH'">
                            <b-row>
                                <b-col>
                                    <div class="form-group row mb-3">
                                        <label class="col-md-2 col-form-label" for="name">Label</label>
                                        <div class="col-md-3">
                                            <b-form-input type="text" v-model="label_text"></b-form-input> 
                                        </div>
                                        <div class="col-md-2">
                                            <b-button variant="success" v-on:click="addLabel"><b-icon icon="plus-square"></b-icon></b-button>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>

                            <hr>

                            <b-row>
                                <b-col>
                                    <div class="form-group row mb-3">
                                        <label class="col-md-3 col-form-label" for="name">Labels</label>
                                        <div class="col-md-9 col-form-label" v-if="stackedBarH.data.length == 0">
                                            No labels added.
                                        </div>
                                        <div class="col-md-9">
                                            <b-dropdown class="graphLabelItem" size="sm" style="margin-right: 0.2em;" variant="outline-secondary" v-for="(item, idx) in stackedBarH.data" :key="idx">
                                                <template #button-content >
                                                    <div>
                                                    {{item.label}}
                                                    </div>
                                                </template>
                                                <b-dropdown-item  v-on:click="removeLabel(idx)">
                                                    Remove
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>

                            <hr>

                            <b-row>
                                <b-col>
                                    <div class="form-group row mb-3">
                                        <label class="col-md-3 col-form-label" for="name">Legend</label>
                                        <!--<div class="col-md-9 col-form-label" v-if="bar.data.length == 0">
                                            No labels added.
                                        </div>-->
                                        <div class="col-md-3" v-for="(legend, idx) in stackedBarH.legend.values" :key="idx">
                                            <b-form-input v-model="stackedBarH.legend.values[idx].text"></b-form-input>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </tab-content>
<!--    #################################-->
                    <tab-content icon="mdi mdi-auto-fix" :before-change="hasGraphSelected">
                        <div v-if="show == false">
                            Loading graph..
                        </div>
                        <webix-ui v-else :config="returnCurrentConfig"/>

                        <!-- end row -->
                    </tab-content>
                </form-wizard>
            </div>
        <!-- end card-body -->
        </div>
        </b-overlay>
    </b-modal>
</template>

<script>
import Swal from 'sweetalert2'
import _ from 'lodash'
import {clientViewMethods, clientViewComputed, clientComputed } from '@/state/helpers'
import { FormWizard, TabContent } from "vue-form-wizard";
export default {
    components: { FormWizard, TabContent },
    data:()=>({
        isSaving: false,

        colors:['#7a7dff','#4dfe53','#ffff75','#ff9924', '#ff5c5c'],
        graph:{
            type:'',
            orientation:'vertical',
            series: 1
        },
        label_text:'',
        label_color:'#ffffff',
        show:false,

        pie:{
            height:250,
            view: "chart",
            type:"pie",
            value:"#value#",
            color:"#color#",
            label:"#label#",
            pieInnerText:"#value#",
            shadow:0,
            data:[]
        },

        bar:{
            height:250,
            view:"chart",
            type:"bar",
            barWidth:35,
            radius:0,
            xAxis:{
                template:'#label#'
            },
            yAxis:{
                start:0, end: 10, step: 1,
            },
            legend:{
                values:[],
                valign:"middle",
                align:"right",
                width:90,
                layout:"y"
            },
            data:[],
            series:[
                
            ]
        },
        barH:{
            height:250,
            view:"chart",
            type:"barH",
            barWidth:35,
            radius:0,
            yAxis:{
                template:'#label#'
            },
            xAxis:{
                start:0, end: 10, step: 1,
            },
            legend:{
                values:[],
                valign:"middle",
                align:"right",
                width:90,
                layout:"y"
            },
            data:[],
            series:[
                
            ]
        },
        stackedBar:{
            height:250,
            view:"chart",
            type:"stackedBar",
            barWidth:35,
            radius:0,
            xAxis:{
                template:'#label#'
            },
            yAxis:{
                start:0, end: 10, step: 1,
            },
            legend:{
                values:[],
                valign:"middle",
                align:"right",
                width:90,
                layout:"y"
            },
            data:[],
            series:[
                
            ]
        },
        stackedBarH:{
            height:250,
            view:"chart",
            type:"stackedBarH",
            barWidth:35,
            radius:0,
            yAxis:{
                template:'#label#'
            },
            xAxis:{
                //start:0, end: 10, step: 1,
            },
            legend:{
                values:[],
                valign:"middle",
                align:"right",
                width:90,
                layout:"y"
            },
            data:[],
            series:[
                
            ]
        },

        
    }),
    computed:{
        ...clientViewComputed,
        ...clientComputed,
        returnCurrentConfig(){
            //*Return the graph config related to the selected graph type
            return this[this.graph.type];
        },
        hasSeries(){
            switch(this.graph.type){
                case 'bar':{
                    return true;
                }
                case 'barH':{
                    return true;
                }
                case 'stackedBar':{
                    return true;
                }
                case 'stackedBarH':{
                    return true;
                }
                default :{
                    return false;
                }
            }
        }
    },
    methods:{
        ...clientViewMethods,
        addLabel(){
            var item = {};
            var type = this.graph.type
            if( type == 'pie'){
                item = { id:window.webix.uid(), value: this.randomNumber(), label: this.label_text, color: this.label_color }
                this[this.graph.type].data.push(item);
                this.label_text = '';
                this.label_color = '#ffffff';
            }
            else if(type == 'bar' || type == 'barH' || type == 'stackedBar' || type == 'stackedBarH'){
                item = { id:window.webix.uid(), label: this.label_text };
                for(var i = 1; i <= this.graph.series; i++){
                    item[`value${i}`] = this.randomNumber();
                }
                this[this.graph.type].data.push(item);
            }
            
        },
        removeLabel(index){
            this[this.graph.type].data.splice(index, 1);
        },

        setItemColor(color){
            var color_string = 'color:' + color + '!important;';
            return color_string;
        },

        setSeriesCount(action){
            switch(action){
                case 'add':{
                    if(this.graph.series <=4){
                        this.graph.series++;
                    }
                    break;
                }
                case 'subtract':{
                    if(this.graph.series > 1){
                        this.graph.series--;
                    }
                    break;
                }
            }
        },
        closeModal(){
            this.$bvModal.hide('graphWizardModal');
        },


        previewGraph(){
            return this[this.graph.type];
        },

        onChange(previous, next){
            if(previous == 2 && next == 1){
                this[this.graph.type].data = [];
            }


            if(next == 2 && previous == 1){ //* reset show var for the preview in the last step
                this.show = false;
                this[this.graph.type].series = [];
                if(this.graph.type == 'bar' || this.graph.type == 'barH' || this.graph.type == 'stackedBar' || this.graph.type == 'stackedBarH'){
                    for(let i = 1; i <= this.graph.series; i++){
                        this[this.graph.type].series.push({
                            value: `#value${i}#`, color: this.colors[i-1]
                        })
                    }
                }
                setTimeout(()=>{
                    this.show = true;
                },500)
            }
            else if(previous == 0 && next == 1){
                if(this.graph.type == 'bar' || this.graph.type == 'barH' || this.graph.type == 'stackedBar' || this.graph.type == 'stackedBarH'){
                    for(let i = 1; i <= this.graph.series; i++){
                        this[this.graph.type].legend.values.push({
                            text: '',
                            color: this.colors[i-1],
                        })
                    }
                }
            }
        },
        //* on-change methods for wizard
        hasGraphSelected(){//* step 1
            if(this.graph.type == ''){
                alert('Select a graph')
                return false;
            }
            else{
                return true;
            }
        },

        hasDataAdded(){//* step 2
            switch(this.graph.type){
                case 'pie':{
                    if(this.pie.data.length == 0){
                        alert('Graph needs data.')
                        return false;
                    }
                    else{
                        return true;
                    }
                }
                case 'bar':{
                    if(this.bar.data.length == 0){
                        alert('Graph needs data.')
                        return false;
                    }
                    else{
                        return true;
                    }
                }
                case 'barH':{
                    if(this.barH.data.length == 0){
                        alert('Graph needs data.')
                        return false;
                    }
                    else{
                        return true;
                    }
                }
                case 'stackedBar':{
                    if(this.stackedBar.data.length == 0){
                        alert('Graph needs data.')
                        return false;
                    }
                    else{
                        return true;
                    }
                }
                case 'stackedBarH':{
                    if(this.stackedBarH.data.length == 0){
                        alert('Graph needs data.')
                        return false;
                    }
                    else{
                        return true;
                    }
                }
            }
        },

        //*on-complete method for the wizard
        onComplete(){
            this.isSaving = true;

            var view_id = this.selectedViewData.id
            var params = {
                render_view_id: view_id,
                config_json: JSON.stringify(this[this.graph.type]),
            }
            this.saveViewConfig(params)
            .then(()=>{
                Swal.fire({ toast: true, position: 'top', iconColor: 'white', icon: 'success', title:'Saved Graph Configuration', customClass: {     popup: 'colored-toast' }, showConfirmButton: false, timer: 2000, timerProgressBar: true})
                this.getViewGroups(this.selected_app.id)
                .then(()=>{
                    this.isSaving = false;
                    this.$nextTick(()=>{
                        this.$eventHub.$emit('displaySelectedView', this.selectedViewData.id)
                        this.selectedView(view_id);
                        //this.$eventHub.$emit('displaySelectedView', view_id);
                        this.closeModal();
                    })
                })
                
            })
            .catch(()=>{
                alert("Error saving graph");
                this.isSaving = false;
            })
        },

        randomNumber(){
            return Math.floor(Math.random() * 11);
        },

        selectedView(id){
            var view = _.find(this.views, {id: id})
            if(view != undefined){
                this.$eventHub.$emit('changeSelectedViewType', view.type);
                this.selectedViewID = id;
                this.setSelectedView(id)
                .then(()=>{
                    this.$eventHub.$emit('selectedView');
                    /*if(this.selectedViewData.config_json == null && this.selectedViewData.type == "graph"){
                        this.openGraphWizardModal();
                    }*/
                })
            }
        },
    }
};
</script>

<style>
.graphLabelItem > ul > li > a:hover{
    font-weight: bold;
}
</style>