<template>
    <div>
        <!-- Properties START -->
        <div v-on:click="showProperties = !showProperties" class="column-heading">
            <h4 style="margin: 0px;">Properties <b-icon style="float: right;" icon="dash"></b-icon></h4>
        </div>
        <b-collapse id="accordion-styles" v-model="showProperties">
            <b-button style="width: 100%;" variant="none">Tables</b-button>
        </b-collapse>
        <!-- Properties End -->

        <!-- Styling START -->
        <div v-on:click="showStyling = !showStyling" class="column-heading">
            <h4 style="margin: 0px;">Styling <b-icon style="float: right;" icon="dash"></b-icon></h4>
        </div>
        <b-collapse id="accordion-styles" v-model="showStyling">
            <b-button style="width: 100%;" variant="none">Tables</b-button>
        </b-collapse>
        <!-- Styling End -->

        <!-- Mapping START -->
        <div v-on:click="showMapping = !showMapping" class="column-heading">
            <h4 style="margin: 0px;">Mapping <b-icon style="float: right;" icon="dash"></b-icon></h4>
        </div>
        <b-collapse id="accordion-styles" v-model="showMapping">
            <b-button v-b-toggle.accordion-views style="width: 100%;" variant="none">Tables</b-button>
        </b-collapse>
        <!-- Mapping End -->
    </div>
</template>

<script>
export default {
    data:()=>({
        showProperties: true,
        showStyling: true,
        showMapping: true,

        sampleTest: ['','','','']
    }),
}
</script>

<style>

</style>