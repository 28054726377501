<template>
    <b-dropdown size="lg" ref="painterForm" variant="outline-primary" :class="isEnabled" class="dropdownThin" text="">
        <template #button-content class="">
            <b-icon icon="brush" style="margin-right: 5px;"></b-icon>Style Painter
        </template>
        <b-dropdown-form style="padding: 5px;">
            <b-form-group>
                <b-row v-if="enabled">
                    <b-col style="text-align: center;">
                        <b-button v-on:click="disableStylePainter" variant="danger">Disable Painter</b-button>
                    </b-col>
                </b-row>
                <hr v-if="enabled">
                <b-row>
                    <b-col cols="12">
                        <label for="">Styles</label>
                        <b-form-input v-model="stylesToAdd"></b-form-input>
                    </b-col>
                </b-row>
                <hr>
                <b-row>
                    <b-col style="text-align: center;">
                        <b-button v-on:click="save" variant="success">Save</b-button>
                    </b-col>
                </b-row>
            </b-form-group>
        </b-dropdown-form>
    </b-dropdown>
</template>

<script>

import { builderMethods } from '@/state/helpers'

export default {
    data(){
        return{
            stylesToAdd:'',
            enabled: false,
        }
    },
    /*watch:{
        enabled(newValue, oldValue){
            console.log(newValue, oldValue);
        }
    },*/
    computed:{
        isEnabled(){
            if(this.enabled){
                return 'rainbow';
            }
            else{
                return ''
            }
        }
    },
    methods:{
        ...builderMethods,
        save(){
            this.enabled = true;
            this.togglePainterMode(this.enabled);
            this.savePainterStyles(this.stylesToAdd);
            this.$refs.painterForm.hide(true);
        },
        disableStylePainter(){
            this.enabled = false;
            this.togglePainterMode(false);
            this.savePainterStyles('');
            this.stylesToAdd = '';
            this.$refs.painterForm.hide(true);
        }
    },
    beforeDestroy(){
        this.togglePainterMode(false);
        this.savePainterStyles('');
    }
}
</script>

<style>

</style>