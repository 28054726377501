<template>
    <b-modal style="max-height: 70%;" scrollable id="editor" size="xl" title="Page Editor">
            <b-row>
                <b-col>
                    <h6>Page: {{page.pag_number}}</h6>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="10">
                    <div id="abcdefg"></div>
                </b-col>
                <b-col cols="2">
                    <b-collapse v-model="collapsed" class="p-1">
                        <div v-if="selected_element != null">
                            <div v-if="elementHasOptions">
                                <div v-if="selected_element.id == 2">
                                    <strong class="w-100"><p>Select Dynamic Text View</p></strong>
                                    <b-dropdown text="Select Item">
                                        <b-dropdown-item @click="selected_element.options.uid = view.uid" v-for="view in returnViews('dynamic_text')" :key="view.id">{{view.name}}</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                                <div v-else-if="selected_element.id == 3">
                                    <strong class="w-100"><p>Select Graph View</p></strong>
                                    <b-dropdown text="Select Item">
                                        <b-dropdown-item @click="selected_element.options.uid = view.uid" v-for="view in returnViews('graph')" :key="view.id">{{view.name}}</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                                <div v-else-if="selected_element.id == 6">
                                    <strong class="w-100"><p>Select Table View</p></strong>
                                    <b-dropdown text="Select Item">
                                        <b-dropdown-item @click="selected_element.options.uid = view.uid" v-for="view in returnViews('table')" :key="view.id">{{view.name}}</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                                <div v-else>
                                    <b-form-group v-for="(option, index) in selected_element.options" :key="index" :label="index" label-for="formrow-firstname-input">
                                        <b-form-input v-model="selected_element.options[index]" type="text"></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>
                            <div v-else>
                                <h5 class="text-center font-size-14 mb-0 mt-3">No options available</h5>
                            </div>
                            <br>
                            <b-row>
                                <b-col>
                                    <b-button variant="success" @click="insertTemplateString()"><strong>Add</strong></b-button>
                                    &nbsp;
                                    <b-button variant="warning" @click="selected_element = null; collapsed = false;"><strong>Back</strong></b-button>
                                </b-col>
                            </b-row>
                        </div>
                    </b-collapse>
                    <br v-show="collapsed">
                    <div class="mail-list mail-list-border p-1" style="cursor: pointer; font-size: 1.2rem;"> <!-- position: fixed; -->
                        <div class="p-1" :style="isElementSelected(element.id)" @click="selectElement(element)" v-for="(element, idx) in available_elements" :key="idx">
                            <i :class="element.icon" class="mr-2"></i> {{element.name}}
                        </div>
                    </div>
                    <!--<div v-show="selected_element != null" class="p-1">
                        <b-row>
                            <b-col>
                                <b-button variant="success" @click="insertTemplateString()" >Add</b-button>&nbsp;
                                <b-button variant="warning" @click="selected_element = null">Back</b-button>
                            </b-col>
                        </b-row>
                        
                    </div>-->
                </b-col>
            </b-row>
        <template #modal-footer>
            <div style="width: 100%;">
                <b-button v-on:click="$bvModal.hide('editor');" style="float:right">Close</b-button>
                <b-button v-on:click="save" variant="success" style="float:right; margin-right: 5px;">Save</b-button>
                
                
            </div>
        </template>
    </b-modal>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import * as Quill from 'quill'
import {available_elements} from './reportElements.js'
import { clientViewComputed, clientViewMethods, clientComputed } from '@/state/helpers'
import _ from 'lodash';
export default {
    data:()=>({
        editor: null,
        content: '<h2>Example</h2>',
        editorOption: {},
        available_elements:[],
        selected_element: null,
        collapsed: false,
    }),
    props:{
        page:{}
    },
    computed:{
        ...clientComputed,
        ...clientViewComputed,
        elementHasOptions(){
            if(this.selected_element.options != null){
                return true;
            }
            else{
                return false;
            }
        }
    },
    mounted(){
        setTimeout(()=>{
            this.available_elements = available_elements;
            if(this.editor == null){
                try{
                    this.initQuill()
                    .then(()=>{
                        if(this.page.page_content == '<p>Empty</p>'){
                            this.insertBaseTemplate();
                        }
                        else{
                            this.editor.root.innerHTML = this.page.page_content;
                        }
                        
                    })
                }
                catch(error){
                    console.log(error)
                }
            }
        }, 100)
    },
    methods:{
        ...clientViewMethods,
        insertBaseTemplate(){
            var template =
`
<template #header>
    *
</template>


<template #content>
    *
</template>


<template #footer>
    *
</template>

`           ;
            var selection = this.editor.getSelection(true);
            this.editor.insertText(selection.index, template)
        },
        insertTemplateString(){
            var selection = this.editor.getSelection(true);
            let templateString = this.selected_element.element;
            let returnString = '';
            if(this.selected_element.id == 0){
                 //*COLUMNS - define columns and insert child column-container component
                returnString = templateString.replace("##columns", this.selected_element.options.columns)
                let column_inner = '';
                for(var i = 0; i < Number(this.selected_element.options.columns); i++ ){
                    column_inner += `${this.available_elements[1].element}
    `;
                }
                returnString = returnString.replace("##containers", column_inner);
            }
            else if(this.selected_element.id == 2 || this.selected_element.id == 3 || this.selected_element.id == 6){
                //* Adds view uid to selected view plugin
                var uid = this.selected_element.options.uid;
                returnString = templateString.replace("##id", uid);
                returnString = returnString.replace("##ref", uid);
            }
            else{
                //* Insert element as is
                returnString = templateString;
            }
            this.$nextTick(()=>{
                this.editor.insertText(selection.index, returnString)
            })
            
        },
        isElementSelected(id){
            var style = '';
            if(this.selected_element != null){
                if(id == this.selected_element.id){
                    return `border-radius: 0.2rem; background: gainsboro;`
                }
            }
            return style;
        },
        selectElement(element){
            this.selected_element = _.cloneDeep(element);
            this.collapsed = true;
            //this.insertTemplateString();
        },
        initQuill(){
            return new Promise((resolve) => {
                var toolbarOptions = [['blockquote', 'code-block']];
                var options = {
                    placeholder: 'Select elements to start building',
                    modules:{
                        toolbar: toolbarOptions,
                    },
                    theme: 'snow'
                };
                var container = document.getElementById('abcdefg')
                this.editor = new Quill(container, options);

                resolve();
            })
        },
        save(){
            var page = {
                id: this.page.id,
                client_app_report_id: this.page.client_app_report_id,
                page_number: 5,
                page_content: this.editor.root.innerHTML,
            }
            this.saveReportPage(page)
            .then( response => {
                console.log(response)
                //this.getReports(this.selected_app.id)
                //.then(()=>{
                    this.$eventHub.$emit('refreshReportPreview', this.page.client_app_report_id);
                //})
                this.$nextTick(()=>{
                    this.$bvModal.hide('editor');
                })
            })
        },
        savePage(){
            var report_id = this.selected_report.id;
            var params = {
                id: -1,
                client_app_report_id: report_id,
                page_number: this.selected_report.builder_pages.length + 1,
                page_content:'<p>Empty</p>',
            }
            this.saveReportPage(params)
            .then( response => {
                console.log(response)
                //this.getReports(this.selected_app.id)
                //.then(()=>{
                    this.$eventHub.$emit('refreshReportPreview', report_id);
                //})
            })
        },
        returnViews(type){
            var returnViews = [];
            this.view_groups.forEach((group)=>{
                group.views.forEach(view => {
                    if(view.type == type){
                        returnViews.push(view);
                    }
                })
            })
            return returnViews;
        }
    }
}
</script>

<style>
#editor > div {
    max-width: 80vw !important;
}
#editor___BV_modal_body_{
    height: 75vh;
}
#editor > div > div > div > div > div > div{
    border: 1px solid rgb(90, 90, 90);
}
#abcdefg{
    font-size: 1.3rem;
}
.mail-list-border > div{
    border: 1px solid white;
    border-radius: 0.2rem;
}
.mail-list-border > div:hover{
    border: 1px solid grey;
}
</style>