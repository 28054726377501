<template>
    <div id="builderMain" style="height: 100v">
        <div><!-- report builder -->
            <b-row>
                <b-col cols="2" class="builder-column column-left">
                    <!-- //* LEFT COLUMN -->
                    <div class="card column-div">
                        <view-list style="overflow-y: visiv" v-if="currentViewType == 'views'" />
                        <report-list v-if="currentViewType == 'report'" />
                    </div>
                </b-col>
                    <!-- //* RIGHT COLUMN -->
                <b-col cols="10" class="builder-column column-left">
                    <!--<table-display v-if="currentViewType == 'views'"></table-display>-->
                    <view-manager v-if="currentViewType == 'views'"/>
                    <report-layout v-if="currentViewType == 'report'"/>
                </b-col>
            </b-row>
        </div>
    </div>
    
</template>

<script>
import CellOptions from './components/table/cell.vue'
import ColumnOptions from './components/table/column.vue'
import RowOptions from './components/table/row.vue'
import TableOptions from './components/table/tableSetting.vue'
import TableDisplay from './components/table/tableDisplay.vue'
import ViewList from './components/table/viewList.vue'

import ViewManager from './viewManager.vue'

//*Report Screen
import ReportList from './components/reports/reportList.vue'
import ReportLayout from './components/reports/reportLayout.vue'
import ReportSettings from './components/reports/reportSettings.vue'

import { clientViewMethods, clientViewComputed } from '@/state/helpers'

export default {
    data:()=>({
        showStyles: true,
        showViews: true,

        selectionType: '', // cell  row  column  table

        //todo - move the view type flag (report, page)
        //currentViewType: ''
    }),
    computed:{
        ...clientViewComputed,
        currentViewType(){
            if(this.designer_mode != null){
                return this.designer_mode;
            }
            else{
                return ''
            }
        }
    },
    components:{
        //eslint-disable-next-line
        CellOptions,
        //eslint-disable-next-line
        ColumnOptions,
        //eslint-disable-next-line
        RowOptions,
        //eslint-disable-next-line
        TableOptions, TableDisplay, ViewList,

        //*View Manager
        ViewManager,
        
        //*Report
        //eslint-disable-next-line
        ReportList, ReportLayout, ReportSettings
    },
    methods:{
        ...clientViewMethods,
        changedViewEvent(newType){
            //this.currentViewType = newType;
            this.setDesignerMode(newType);
        },
    },
    mounted(){
        this.$eventHub.$on('changedViewType', this.changedViewEvent);
    },
    beforeDestroy(){
        this.$eventHub.$off('changedViewType');
    }
}
</script>

<style>
.builder-column{
    height: 80vh;
    /*padding: 0.3em;*/
}
.column-div{
    border: 1px solid grey;
    border-radius: 0.3em;
    /*padding: 0.15em;*/
}
.column-left{}
.column-right{}

.column-heading{
    border-radius: 0.35em;
    background: lightGrey;
    cursor: pointer;
    padding: 0.2em;
}
</style>