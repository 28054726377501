<!--
@Author: Calvin Green <calvin>
@Date:   2021-06-30T15:19:06+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-07-05T13:12:16+02:00
@Copyright: Diversitude 2021
-->



<template>
    <div>
        <div class="left-heading" style="display: flex">
            <h2 style="margin: 0px; width: 75%;">
                Reports
            </h2>
            <b-button v-on:click="showAddReportModal" style="width: 20%; margin: auto" size="sm" variant="outline-info">Add</b-button>
        </div>
        <div v-if="client_reports.length > 0" style="margin-top: 0.6em;">
            <b-list-group>
                <b-list-group-item class="list-group-padding"  :active="selectedReportID == report.id" v-for="report in client_reports" :key="report.id">
                    <b-row>
                        <b-col v-on:click="selectedReport(report.id)" cols="9">
                            {{report.name}}
                        </b-col>
                        <b-col cols="3">
                            <div style="float: right">
                                <b-icon style class="editReportIcon" @click="editReport(report.id)" icon="pencil"></b-icon>
                                &nbsp;
                                <b-icon @click="deleteReport(report.id)" icon="trash"></b-icon>
                            </div>
                        </b-col>
                    </b-row>
                </b-list-group-item>
            </b-list-group>
        </div>
        <div v-else>
            <h6 v-if="isLoading" class="text-center" style="margin-top: 0.6em;">Loading Reports</h6>
            <h6 v-else class="text-center" style="margin-top: 0.6em;">No Reports Added</h6>
        </div>
        <!-- Properties End -->

        <!-- Modals -->
        <AddReportModal v-if="toggleAddReportModal"/>
    </div>
</template>

<script>
import AddReportModal from './modals/addReportModal.vue'
import { clientViewMethods, clientViewComputed, clientComputed, clientMethods } from '@/state/helpers'
import _ from 'lodash'
import Swal from "sweetalert2";
export default {
    components:{
        AddReportModal,
    },
    data:()=>({
        client_reports:[],
        selectedReportID: null,
        isLoading: true,

        /* modal */
        toggleAddReportModal: false,
    }),
    computed:{
        ...clientViewComputed,
        ...clientComputed,
    },
    methods:{
        ...clientViewMethods,
        ...clientMethods,
        selectedReport(id){
            this.$eventHub.$emit('changedViewType', 'report');
            this.$nextTick(()=>{
                var report = _.find(this.client_reports, {id: id})
                if(report != undefined){
                    this.selectedReportID = id;
                    this.selectReport(report)
                    .then(()=>{
                        this.$eventHub.$emit('selectedNewReport');
                    })
                }
            })

        },
        showAddReportModal(){
            this.toggleAddReportModal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('addReportModal');
                this.$root.$once('bv::modal::hidden',(event)=>{
                    if(event.type == 'hidden' && event.componentId == 'addReportModal'){
                        this.toggleAddReportModal = false;
                        this.loadEditData(null);
                    }
                })
            })
        },
        editReport(id){
            var report = _.find(this.client_reports, {id: id});
            if(report != undefined){
                this.loadEditData(report)
                .then(()=>{
                    this.showAddReportModal();
                })
            }
        },
        deleteReport(id) {
            Swal.fire({
                title: "Are you sure you want to delete this report?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!"
            }).then(result => {
                if (result.value) {
                    window.axios.post('api/clientapp/deletereport', {id: id})
                    .then(()=>{
                        this.loadReports();
                        Swal.fire("Deleted!", "Report has been deleted.", "success");
                    })
                    .catch((e)=>{
                      // eslint-disable-next-line no-console
                        console.log(e);
                    })
                }
            });
        },
        loadReports(){
            return new Promise((resolve, reject)=>{
                this.isLoading = true;
                this.getReports(this.selected_app.id)
                .then(()=>{
                    this.client_reports = _.cloneDeep(this.reports);
                    this.isLoading = false;
                    resolve();
                })
                .catch((e)=>{
                    reject(e);
                })
            })
            
        },

        //* EVENT METHODS
        editReportModalEvent(id){
            this.editReport(id);
        },

        refreshPreviewEvent(id){
            alert('Refresh')
            this.loadReports()
            .then(()=>{
                this.selectedReport(id);
            })
            .catch((e)=>{
                console.log(e)
            })
        }
    },
    mounted(){
        this.loadReports();

        this.$eventHub.$on('openEditReportModal', this.editReportModalEvent)
        this.$eventHub.$on('loadReports', this.loadReports);
        this.$eventHub.$on('refreshReportPreview', this.refreshPreviewEvent);

    },
    beforeDestroy(){
        this.$eventHub.$off('openEditReportModal');
        this.$eventHub.$off('loadReports');
        this.$eventHub.$off('refreshReportPreview');
    }
}
</script>

<style>
    .left-heading{
        background:#f7f7f7;
        border-top-left-radius: 0.3em;
        border-top-right-radius: 0.3em;
        padding: 0.4em;
    }
    .left-body{
        background: #fff;
    }

    .list-group-item:hover{
        color: dodgerblue;
    }
    .list-group-item.active{
        font-weight: bolder;
        color:  #ffffff;

        background-color: var(--bs-primary);
    }
    .list-group-padding.list-group-item{
        border-radius: 0.3em;
        margin-left: 4px;
        margin-right: 4px;
        margin-bottom: 2px;
        border: 1px solid rgb(179, 179, 179);
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .list-group-padding > div > div{
        padding-top:10px;
        padding-bottom:10px;
    }
</style>
