<template>
    <b-modal id="newTableModal" size="lg" title="Table Creation">
        <b-container>
            <b-form>
                <b-form-group v-if="choice == ''">
                    <b-row>
                        <b-col style="text-align: center;">
                            <b-button size="lg" v-on:click="choice = 'new'" variant="primary">Create New Table</b-button>
                            &nbsp;
                            <b-button size="lg" v-on:click="choice = 'copy'" variant="primary">Copy Existing Table</b-button>
                        </b-col>
                    </b-row>
                </b-form-group>


                <!-- COPY SCREEN -->
                <b-form-group v-if="choice == 'copy'">
                    <b-row>
                        <b-col>
                            <b-dropdown class="viewCopyDropdown" style="width: 25%;" :text="getViewName">
                                <b-dropdown-item :class="showActiveItem(idx)" class="dropdownItem viewCopyDropdown" v-for="(view, idx) in returnViews" v-on:click="setSelectedIndex(idx)" :key="view.id">{{view.name}}</b-dropdown-item>
                            </b-dropdown>
                        </b-col>
                    </b-row>
                    <hr>
                    <div v-if="selectedViewIndex != -1" style="; margin: 0.5rem padding: 0.5rem;">
                    <b-row>
                        <b-col cols="12"><strong>Preview</strong></b-col>
                        <b-col cols="12" v-if="returnViews[selectedViewIndex].config_json != null" style="overflow:auto;">
                            <div class="zoomOut">
                                <webix-ui  :config="previewConfig" v-model="previewConfig.data.data"/>
                            </div>
                        </b-col>
                    </b-row>
                    </div>
                </b-form-group>


                <!-- NEW VIEW SCEEEN -->
                <b-form-group v-if="choice == 'new'">
                    <b-row>
                        <b-col>
                            <label for="">Columns</label>
                            <b-form-input v-model="table_data.cols"></b-form-input>
                        </b-col>
                        <b-col>
                            <label for="">Rows</label>
                            <b-form-input v-model="table_data.rows"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="formRow">
                        <b-col cols="6">
                            <label for="">Add Empty Cells</label>
                            <b-form-checkbox class="" size="lg" id="showCheckbox" v-model="table_data.emptyCells">
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-form-group>
            </b-form>
        </b-container>
        <template #modal-footer>
            <div style="width: 100%;">
                <b-button v-on:click="close" style="float:right">Close</b-button>
                <b-button v-if="choice != ''" v-on:click="save" variant="success" style="float:right; margin-right: 5px;">Save</b-button>
                
            </div>
        </template>
    </b-modal>
</template>

<script>
import _ from 'lodash'
import { clientViewComputed } from '@/state/helpers'
export default {
    data(){
        return{
            table_data:{
                cols: 5,
                rows:5,
                emptyCells: true,
            },
            choice:'', //new or copy
            selectedViewIndex: -1,

        }
    },
    computed:{
        ...clientViewComputed,
        previewConfig(){
            var config = null;
            if(this.returnViews[this.selectedViewIndex].config_json != null){
                config = JSON.parse(this.returnViews[this.selectedViewIndex].config_json);
                config.id = 'tablePreview'
            }
            return config;
        },
        getViewName(){
            if(this.selectedViewIndex == -1){
                return 'Select View';
            }
            else{
                return this.returnViews[this.selectedViewIndex].name
            }
        },
        returnViews(){
            var group = _.find(this.view_groups, {id : this.selected_group_id});
            if(group != undefined){
                var filtered = [];
                group.views.forEach(view => {
                    if(view.type == 'table'){
                        filtered.push(view);
                    }
                })
                return filtered;
            }
            else{
                return []
            }
        }
    },
    methods:{
        save(){
            if(this.choice == 'new'){
                this.$eventHub.$emit('createNewTable', this.table_data);
            }
            else if(this.choice == 'copy'){
                var table = _.cloneDeep(this.returnViews[this.selectedViewIndex]);
                this.$eventHub.$emit('createCopyFromTable', table);
            }
            this.close();
        },
        close(){
            this.$bvModal.hide('newTableModal');
        },

        setSelectedIndex(idx){
            this.selectedViewIndex = -1;
            this.$nextTick(()=>{
                this.selectedViewIndex = idx;
            })
        },
        showActiveItem(idx){
            if(idx == this.selectedViewIndex){
                return 'dropdownItemActive'
            }
            else{
                return '';
            }
        }
    }
}
</script>

<style>
.zoomOut{
    
    transform: scale(0.75);
    transform-origin: 0% 0% 0px;
    max-height: 20rem;
    width: 100%;

}
</style>