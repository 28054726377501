<template>
    <b-dropdown size="lg" ref="columnForm" variant="outline-primary" class="dropdownThin" text="Add New Column">
        <template #button-content>
            Add Column <b-icon size="sm" icon="caret-down-fill"></b-icon>
        </template>
        <b-dropdown-form style="padding: 5px;">
            <b-form-group submit="save">
                <b-row>
                    <!--<b-col cols="12">
                        <label for="">Column ID (required)</label>
                        <b-form-input v-model="column.id"></b-form-input>
                    </b-col>-->
                    <b-col cols="12">
                        <label for="">Header Text (optional)</label>
                        <b-form-input v-model="column.header"></b-form-input>
                    </b-col>
                </b-row>
            </b-form-group>
            <hr>
            <b-button v-on:click="saveColumn" variant="success">Save</b-button>
        </b-dropdown-form>
    </b-dropdown>
</template>

<script>
export default {
    data(){
        return{
            column:{
                id:'',
                header:'',
                width:'',
                css:'',
                fillspace: 1,
            },
        }
    },
    methods:{
        saveColumn(){
            this.$eventHub.$emit('savedColumn', this.column);
            this.column = { id:'', header:'', width:'', css:'', fillspace: 1, }
            this.$refs.columnForm.hide(true);
        },
        save(e){
            e.preventDefault();
            alert('save')
        }
    }
}
</script>

<style>

</style>