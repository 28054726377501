<template>
    <div>
        <b-overlay :show="isDeleting">
        <div class="left-heading" style="display: flex; padding-top: 1em;">
            <h2 style="margin: 0px; padding-left:0.2em; width: 90%;">
                Groups
            </h2>
            <!--<b-button v-on:click="nextView" style="margin-right: 4px;" size="sm" variant="outline-primary" ><b-icon scale="1.2" icon="caret-down-fill"></b-icon></b-button>
            <b-button v-on:click="previousView" style="margin-right: 4px;" size="sm" variant="outline-primary" ><b-icon scale="1.2" icon="caret-up-fill"></b-icon></b-button>-->
            <b-dropdown v-if="hasGroupSelected" style="margin-right: 0.2rem;" class="addItemDropdown" size="sm" ref="editGroupForm" variant="outline-primary">
                <template #button-content>
                    <i class="fas fa-pen"></i>
                </template>
                <!--<template #button-content>
                    <b-button v-on:click="addTable" style="width: 20%; margin: auto" size="sm" variant="outline-info">Add</b-button>
                </template>-->
                <b-dropdown-form style="padding: 5px;">
                    <b-overlay :show="isSaving">
                        <b-form-group submit="save">
                            <b-row >
                                <b-col cols="12">
                                    <label for="">Group Name</label>
                                    <b-form-input v-model="editGroup.name"></b-form-input>
                                </b-col>
                                <b-col cols="12">
                                    <label for="">Description</label>
                                    <b-form-input v-model="editGroup.description"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-form-group>
                    </b-overlay>
                    <hr>
                    <div style="text-align:center;">
                        <b-button v-on:click="updateGroup" variant="success" class="btn-label">
                            <i class="bx bx-check-double label-icon"></i> Update Group
                        </b-button>
                    </div>
                    
                </b-dropdown-form>
            </b-dropdown>
            <b-dropdown class="addItemDropdown" size="sm" ref="newGroupForm" text="Add" variant="outline-primary">
                <template #button-content>
                    <i class="fas fa-plus"></i>
                </template>
                <!--<template #button-content>
                    <b-button v-on:click="addTable" style="width: 20%; margin: auto" size="sm" variant="outline-info">Add</b-button>
                </template>-->
                <b-dropdown-form style="padding: 5px;">
                    <b-overlay :show="isSaving">
                        <b-form-group submit="save">
                            <b-row>
                                <b-col cols="12">
                                    <label for="">Group Name</label>
                                    <b-form-input v-model="newGroup.name"></b-form-input>
                                </b-col>
                                <b-col cols="12">
                                    <label for="">Description</label>
                                    <b-form-input v-model="newGroup.description"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-form-group>
                    </b-overlay>
                    <hr>
                    <div style="text-align:center;">
                        <b-button v-on:click="saveNewGroup" variant="success" class="btn-label">
                            <i class="bx bx-check-double label-icon"></i> Save Group
                        </b-button>
                    </div>
                    
                </b-dropdown-form>
            </b-dropdown>
        </div>
        <div v-if="groupedViews.length > 0" style="margin-top: 0.6em; height: 100%; overflow-y: scroll;">
                <b-list-group>
                    <b-list-group-item class="list-group-padding" :active="selectedGroupID == group.id" v-for="(group, idx) in groupedViews" :key="idx" style="cursor: pointer;">
                        <b-row>
                            <b-col v-on:click="clickViewGroup(group.id)" cols="10">
                                {{group.name}}
                            </b-col>
                            <b-col cols="2">
                                <b-icon style="float:right;" v-on:click="deleteSelectedViewGroup(group.id)" icon="trash"></b-icon>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </b-list-group>
        </div>
        
        <div v-else>
            <!--<h6 v-if="isLoading" class="text-center" style="margin-top: 0.6em;">Loading Views</h6>-->
            <h6 class="text-center" style="margin-top: 0.6em;">No Groups Added</h6>
        </div>

        </b-overlay>
        <!-- Properties End -->

        <!-- Modals -->
        <AddViewModal v-if="toggleNewTableModal"/>
        <GraphWizardModal v-if="showGraphWizardModal"/>
    </div>
</template>

<script>
import GraphWizardModal from '../../modals/graphWizardModal.vue'
import AddViewModal from '../../modals/newTableModal.vue'
import {clientViewMethods, clientViewComputed, clientMethods, clientComputed } from '@/state/helpers'
import { builderMethods, builderComputed} from '@/state/helpers';
import _ from 'lodash'

import Swal from 'sweetalert2'

export default {
    components:{
        AddViewModal,
        GraphWizardModal
    },

    data:()=>({
        selectedGroupName:'',
        selectedGroupID: null,
        selectedViewID: null,
        isLoading: true,
        isDeleting:false,
        toggleNewTableModal: false,
        showGraphWizardModal: false,
        newGroup:{
            group_id: -1,
            client_app_id: '',
            name: '',
            description: '',
        },
        editGroup:{
            name: '',
            description: '',
        },
        newView:{ //! DELETE-ME
            id: -1,
            client_app_id: '',
            name:'',
            description:'',
            type:'',
        },
        isSaving: false,
    }),
    computed:{
        ...clientViewComputed,
        ...clientComputed,
        ...builderComputed,
        hasGroupSelected(){
            if(this.selected_group_id == null){
                return false;
            }
            else{
                return true;
            }
        },
        groupedViews(){
            /*var grouped = _.mapValues(
                _.groupBy(this.views, 'type'),
                g => g.map(view => _.omit(view, 'type'))
            );
            console.log(grouped);*/
            if(this.view_groups == null){
                return [];
            }
            else{
                return this.view_groups;
            }
        },
        returnSelectedGroup(){
            if(this.selectedGroupName != ''){
                return this.groupedViews[this.selectedGroupName]
            }
            else{
                return {};
            }
        },

        displayNewViewType(){
            switch(this.newView.type){
                case 'table':{
                    return 'Table'
                }
                case 'dynamic_text':{
                    return 'Dynamic Text'
                }
                default:{
                    return 'Select View Type'
                }
            }
        }
    },
    methods:{
        ...clientViewMethods,
        ...clientMethods,
        ...builderMethods,
        saveNewGroup(){
            if(this.newGroup.name == '' || this.newGroup.description == ''){
                alert('Please fill in all the fields')
            }
            else{
                this.isSaving = true;
                this.newGroup.id = -1;
                this.newGroup.client_app_id = this.selected_app.id;
                this.saveViewGroup(this.newGroup)
                .then((response)=>{
                    console.log(response);
                    this.newGroup = {
                        group_id: -1,
                        client_app_id: '',
                        name:'',
                        description:'',
                    }
                    this.getViewGroups(this.selected_app.id)
                    .then(()=>{
                        this.isSaving = false;
                        this.$nextTick(()=>{
                            //this.selectedView(response.id);
                            /*if(showGraphWizard){
                                this.openGraphWizardModal();
                            }*/
                            this.$refs.newGroupForm.hide(true);
                        })
                    })
                    .catch(()=>{ this.isSaving = false });
                })
                .catch(()=>{ this.isSaving = false });
            }
        },
        saveNewView(){//! DELETE-ME
            if(this.newView.name == '' || this.newView.description == '' || this.newView.type == ''){
                //Swal.fire("Error!", "Please fill in all the fields", "info");
                alert('Please fill in all the fields')
            }
            else{
                this.isSaving = true;
                this.newView.id = -1;
                this.newView.client_app_id = this.selected_app.id;
                this.saveView(this.newView)
                .then((response)=>{
                    console.log(response);
                    this.newView = {
                        id: -1,
                        client_app_id: '',
                        name:'',
                        type:'',
                        description:'',
                    }
                    this.getViews(this.selected_app.id)
                    .then(()=>{
                        this.isSaving = false;
                        this.$nextTick(()=>{
                            //this.selectedView(response.id);
                            /*if(showGraphWizard){
                                this.openGraphWizardModal();
                            }*/
                            this.$refs.newViewForm.hide(true);
                        })
                    })
                    .catch(()=>{
                        this.isSaving = false;
                    })
                })
                .catch(()=>{
                    this.isSaving = false;
                })
            }
        },
        addEditData(){
            var index = _.findIndex(this.view_groups, {id: this.selected_group_id});
            this.editGroup.name = this.view_groups[index].name;
            this.editGroup.description = this.view_groups[index].description;
        },
        updateGroup(){
            if(this.editGroup.name == '' || this.editGroup.description == ''){
                alert('Please fill in all the fields')
            }
            else{
                this.isSaving = true;
                var index = _.findIndex(this.view_groups, {id: this.selected_group_id});
                console.log(this.view_groups[index])
                //eslint-disable-next-line
                var params = { //group_id, client_app_id, name, description
                    group_id : this.selected_group_id,
                    client_app_id : this.view_groups[index].client_app_id,
                    name : this.editGroup.name,
                    description : this.editGroup.description,

                }
                this.saveViewGroup(params)
                .then((response)=>{
                    console.log(response);
                    this.getViewGroups(this.view_groups[index].client_app_id)
                    .then(()=>{
                        this.isSaving = false;
                        this.$nextTick(()=>{
                            //this.selectedView(response.id);
                            this.$refs.editGroupForm.hide(true);
                        })
                    })
                    .catch(()=>{ this.isSaving = false });
                })
                .catch(()=>{ this.isSaving = false });
            }
        },
        addTable(){
            this.showNewTableModal();
        },
        showNewTableModal(){//*Show modal for creating a new table
            this.toggleNewTableModal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('newTableModal');
                this.$root.$once('bv::modal::hidden',(event)=>{
                    if(event.type == 'hidden' && event.componentId == 'newTableModal'){
                        this.toggleNewTableModal = false;
                        //this.showTable = true;
                    }
                })
            });
        },
        clickViewGroup(id){
            //this.addEditData();
            if(this.hasMadeChanges){
                //* Current view has unsaved changes
                Swal.fire({
                    title: 'Unsaved Changes',
                    text: 'Save or Discard changes?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Save',
                    cancelButtonText: 'Discard'
                })
                .then((result)=>{
                    console.log(result)
                    if(result.isConfirmed){
                        //*Selected Yes
                        this.$eventHub.$emit('forceSaveConfiguration');
                    }
                    else if(result.dismiss == 'cancel'){
                        //*Selected Discard
                        this.setMadeChangesFlag(false);
                        /*this.selectedView = id;
                        this.setSelectedView(id);
                        this.$eventHub.$emit('selectedView');*/
                        this.selectedViewGroup(id);
                        this.$nextTick(()=>{
                            this.addEditData();
                        })
                    }
                    else if(result.dismiss == 'backdrop'){
                        //* Clicked backdrop -- modal closed without executing any action
                        console.log('alert dismissed');
                    }
                })
            }
            else{
                this.selectedViewGroup(id);
                this.$nextTick(()=>{
                    this.addEditData();
                })
            }
            /*if(this.hasMadeChanges){
                alert('Save ME');
            }
            else{
                this.selectedViewGroup(id);
            }*/
        },
        selectedViewGroup(id){
            //this.selectedGroupName = name;
            this.selectedGroupID = id;
            this.setSelectedGroupID(id);
            this.$eventHub.$emit('changeSelectedViewGroup', this.selectedGroupID);
            this.$eventHub.$emit('changeSelectedViewType', 'group_table')
        },
        selectedView(id){
            this.$nextTick(()=>{
                var view = _.find(this.views, {id: id})
                if(view != undefined){
                    this.$eventHub.$emit('changeSelectedViewType', view.type);
                    this.selectedViewID = id;
                    this.setSelectedView(id)
                    .then(()=>{
                        this.$eventHub.$emit('selectedView');
                        if(this.selectedViewData.config_json == null && this.selectedViewData.type == "graph"){
                            this.openGraphWizardModal();
                        }
                    })
                }
            })
        },
        editView(id){
            alert('Edit'+id);
        },
        openGraphWizardModal(){
            this.showGraphWizardModal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('graphWizardModal');
                this.$root.$once('bv::modal::hidden',(event)=>{
                    if(event.type == 'hidden' && event.componentId == 'graphWizardModal'){
                        this.showGraphWizardModal = false;
                        //this.showTable = true;
                    }
                })
            })
        },
        deleteSelectedViewGroup(id){
            var group = _.find(this.view_groups, {id:id});
            if(group != undefined){
                Swal.fire({
                    title: 'Delete View Group?',
                    text: group.name,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                })
                .then((result)=>{
                    if(result.isConfirmed){
                        this.isDeleting = true;

                        this.deleteViewGroup(id)
                        .then(()=>{
                            this.getViewGroups(this.selected_app.id)
                            .then(()=>{
                                this.isDeleting = false;
                            })
                        })
                        .catch(()=>{
                            console.log('Error deleting view');
                            this.isDeleting = false;
                        })
                    }
                })
            }
        },
        deleteSelectedView(id){
            var selectedView = _.find(this.views, {id: id})
            if(selectedView != undefined){
                Swal.fire({
                    title: 'Delete View?',
                    text: selectedView.name,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                })
                .then((result)=>{
                    if(result.isConfirmed){
                        this.isDeleting = true;

                        this.deleteView(id)
                        .then(()=>{
                            this.getViews(this.selected_app.id)
                            .then(()=>{
                                this.isDeleting = false;
                            })
                        })
                        .catch(()=>{
                            console.log('Error deleting view');
                            this.isDeleting = false;
                        })
                    }
                })
            }

        },
        nextView(){
            var index = _.findIndex(this.views, {id: this.selectedViewData.id});
            if(index != -1){
                if(index < (this.views.length -1)){
                    let next_id = this.views[index+1].id;
                    this.selectedView(next_id);
                }
            }
        },
        previousView(){
            var index = _.findIndex(this.views, {id: this.selectedViewData.id});
            if(index != -1){
                if(index > 0){
                    let previous_id = this.views[index-1].id;
                    this.selectedView(previous_id);
                }
            }
        }

    },
    mounted(){
        this.setSelectedGroupID(null)
    }
}
</script>

<style>

</style>