<template>
    <div class="card column-div" :style="showOverlay" style="height: 100%; overflow: hidden;">
        <div v-if="currentGroup != null" class="left-heading" style="display: flex; width: 100%; padding-top: 1em;">
            <h2 style="margin: 0px; padding-left:0.2em; margin-right: 0.5rem;">
                {{getViewName}}&nbsp;
                <span style="font-weight: 100; background: rgb(237 237 237); border-radius: 16px; padding-left: 0.4rem; padding-right: 0.4rem;" v-if="this.type_filter != null">Filtered by: <i :class="getIconType"></i></span>
                &nbsp;
                <span @click="enableFilter(-1)" title="Clear Filter" style="cursor: pointer; font-weight: 100; background: rgb(255 182 182); border-radius: 16px; padding-left: 0.4rem; padding-right: 0.4rem;" v-if="this.type_filter != null"><i class="fas fa-times"></i></span>
            </h2>
            
                    <b-dropdown class="addItemDropdown" size="sm" ref="newViewForm" text="Create New View" variant="primary">
                        <b-dropdown-form style="padding: 5px;">
                            <b-overlay :show="isLoading">
                                <b-form-group submit="save">
                                    <b-row>
                                        <b-col cols="12">
                                            <label for="">View Name</label>
                                            <b-form-input v-model="newView.name"></b-form-input>
                                        </b-col>
                                        <b-col cols="12">
                                            <label for="">Description</label>
                                            <b-form-input v-model="newView.description"></b-form-input>
                                        </b-col>
                                        <b-col cols="12">
                                            <label for="" style="width: 100%;">Type</label>
                                            <select v-model="newView.type" class="form-select">
                                                <option disabled value="">Select View Type</option>
                                                <option value="data_table">Data Table</option>
                                                <option value="table">Table</option>
                                                <option value="dynamic_text">Dynamic Text</option>
                                                <option value="graph">Graph</option>
                                                <option value="form">Form</option>
                                                <option value="header">Header</option>
                                                <option value="footer">Footer</option>
                                            </select>
                                        </b-col>                         
                                    </b-row>
                                </b-form-group>
                            </b-overlay>
                            <hr>
                            <div style="text-align:center;">
                                <b-button v-on:click="saveNewView" variant="success">Save</b-button>
                            </div>
                            
                        </b-dropdown-form>
                    </b-dropdown>
                    
                    
                    
        </div>
        <div style="width: 100%;">
            
        </div>
        <div v-if="group == ''">
            <!--<h2 class="pt-4 mt-4 text-center">No Group Selected</h2>-->
            <b-alert show variant="primary" style="margin: 0.2rem;">
                <h3 class="p-0 m-0 text-center">No Group Selected</h3>
            </b-alert>
        </div>
        <div>
            <webix-ui ref="webixDatatable" style="width: 100%;" v-if="showTable" :config="config" v-model="groupedViews" />
            <div id="group_paging"></div>
        </div>

        <!-- EDIT VIEW MODAL -->
        <b-modal v-if="showEditModal" id="editModal" title="Edit View">
            <form ref="form">
                <b-form-group label="Name" label-for="name-input">
                    <b-form-input id="name-input" v-model="edit_params.name" required></b-form-input>
                </b-form-group>
                <b-form-group class="mt-4" label="Description" label-for="description-input">
                    <b-form-input id="description-input" v-model="edit_params.description" required></b-form-input>
                </b-form-group>
                <b-form-group class="mt-4" label="Type" label-for="type-input" >
                    <select id="type-input" v-model="edit_params.type" required class="form-select">
                        <option disabled value="">Select View Type</option>
                        <option value="table">Table</option>
                        <option value="dynamic_text">Dynamic Text</option>
                        <option value="graph">Graph</option>
                        <option value="form">Form</option>
                    </select>
                </b-form-group>
            </form>
            <template #modal-footer>
                <div class="w-100">
                    <b-button variant="success" @click="saveEditView" type="button" style="margin-left: 5px;float: right;">
                        Save
                    </b-button>
                    <b-button variant="light" style="float: right;" @click="$bvModal.hide('editModal');">
                        Close
                    </b-button>
                </div>
            </template>
        </b-modal>

        <!-- CHANGE GROUP MODAL -->
        <b-modal v-if="showChangeGroupModal" id="groupModal" title="Change Group">
            <form ref="form">
                <b-form-group label="Current Group" label-for="group-input">
                    <h4>{{currentGroup.name}}</h4>
                </b-form-group>
                <b-form-group class="mt-4" label="New Group" label-for="type-input" >
                    <select id="type-input" v-model="edit_group.new_group_id" required class="form-select">
                        <option disabled value=null >Select New Group</option>
                        <option v-for="group in filteredGroups" :key="group.id" :value="group.id">{{group.name}}</option>
                    </select>
                </b-form-group>
            </form>
            <template #modal-footer>
                <div class="w-100">
                    <b-button variant="success" @click="saveGroupChange" type="button" style="margin-left: 5px;float: right;">
                        Save
                    </b-button>
                    <b-button variant="light" style="float: right;" @click="$bvModal.hide('groupModal');">
                        Close
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
    
</template>

<script>
//TODO: RESET FILTER ON GROUP CHANGE
import Swal from 'sweetalert2'
import _ from 'lodash'
import {clientViewMethods, clientViewComputed, clientMethods, clientComputed } from '@/state/helpers'

export default {
    props:{
        group:{

        }
    },
    data:()=>({
        //toggle view types filter for webix table
        enabled_filter: null,
        type_filter: null,

        //edit view var
        edit_params:{},
        edit_group: {
            new_group_id: null,
            current_view_id: null
        },

        //new view object, used by add dropdown
        newView:{
            id: -1,
            client_app_id: '',
            name:'',
            description:'',
            type:'',
        },

        showChangeGroupModal: false,
        showEditModal:false,
        clickedAction: false,
        config:{
            animate:{
                subtype:"in"
            },
            id: 'groupViewTable',
            view: 'datatable',
            css: 'webix_header_border webix_data_border',
            header:true,
            spans:true,
            select:"row",
            scrollY: false,
            height: 626,
            //autoHeight: true,
            yCount:15,
            //scroll: 'y',
            pager:{
                view: 'pager',
                template:"{common.prev()} {common.pages()} {common.next()}",
                container:"group_paging",
                size:15,
                group:5
            },
            scrollX:false,
            fixedRowHeight:true,
            multiselect: true,
            columns:[
                { id: 'index', header:['', {content:"textFilter", placeholder:"Type here to search name/description", colspan:5}], width: 50 },
                { id: 'action', header:'Actions',
                    template: `
                            <span>
                                <div class="icon-row row text-center">
                                    <div class="col">
                                        <span title="Delete View" class="webix_icon_hover webix_icon delete_icon fas fa-trash"></span></div>
                                    <div class="col">
                                        <span title="Edit View" class="webix_icon_hover webix_icon edit_icon fas fa-pen"></span></div>
                                    <div class="col">
                                        <span title="Change Group" class="webix_icon_hover webix_icon group_icon fas fa-link"></span></div>
                                    <div class="col">
                                        <span title="Copy UID" class="webix_icon_hover webix_icon copy_uid_icon far fa-copy"></span></div>
                                </div>
                            </span>`, width: 150},
                { id: 'type', header: 'Type', width: 70, template: function(obj){
                    if(obj.type == 'dynamic_text'){
                        return '<div style="width: 100%;" title="Dynamic Text" class="text-center"><span class="webix_icon_hover webix_icon filter_icon fas fa-paragraph"/></div>';//far fa-chart-bar
                    }
                    if(obj.type == 'data_table'){
                        return '<div style="width: 100%;" title="Data Table Plugin" class="text-center"><span class="webix_icon_hover webix_icon fas filter_icon fa-database"/></div>'
                    }
                    if(obj.type == 'table'){
                        return '<div style="width: 100%;" title="Table Plugin" class="text-center"><span class="webix_icon_hover webix_icon fas filter_icon fa-table"/></div>'
                    }
                    if(obj.type == 'graph'){
                        return '<div style="width: 100%;" title="Graph Plugin" class="text-center"><span class="webix_icon_hover webix_icon far filter_icon fa-chart-bar"/></div>'
                    }
                    if(obj.type == 'form'){
                        return '<div style="width: 100%;" title="Form Plugin" class="text-center"><span class="webix_icon_hover webix_icon filter_icon fab fa-wpforms"/></div>'
                    }
                    if(obj.type == 'header'){
                        return '<div style="width: 100%;" title="Document Header" class="text-center"><span class="webix_icon_hover webix_icon filter_icon mdi mdi-page-layout-header"/></div>'
                    }
                    if(obj.type == 'footer'){
                        return '<div style="width: 100%;" title="Document Footer" class="text-center"><span class="webix_icon_hover webix_icon filter_icon mdi mdi-page-layout-footer"/></div>'
                    }
                } },
                { id: 'name', header: 'Name', width: 400 },
                { id: 'description', header: 'Description', fillspace: true },
            ],
            /*data:{
                data:[
                    {id:'1', name: 'Name Me', description:'Talk to me'}
                ]
            },*/
            on:{//eslint-disable-next-line
                onItemClick(id, e, node){
                    var el = this.$scope.$parent;
                    if(el.clickedAction == false){
                        el.displayView(id.row);
                    }
                },
                /*onMouseMoving:function(event){
                    try{
                        var row = this.locate(event).row;
                        if (row != this.last_used_row)
                            this.removeRowCss(this.last_used_row, "hover");
                        this.addRowCss(row, "hover");
                        this.last_used_row = row;
                    }
                    //eslint-disable-next-line
                    catch(ex){}
                },*/
            },
            //eslint-disable-next-line
            //onMouseMove:{},
            onClick:{
                delete_icon: function(e, id){
                    console.log(e, id);
                    var el = this.$scope.$parent;
                    el.clickedAction = true;
                    el.deleteSelectedView(id.row);
                    setTimeout(()=>{
                        el.clickedAction = false;
                    }, 200)

                },
                edit_icon: function(e, id){
                    console.log(e, id);
                    var el = this.$scope.$parent;
                    el.clickedAction = true;
                    el.editView(id.row);
                    setTimeout(()=>{
                        el.clickedAction = false;
                    }, 200)
                },
                //eslint-disable-next-line
                group_icon: function(e, id){
                    var el = this.$scope.$parent;
                    el.clickedAction = true;
                    el.moveView(id.row);
                    setTimeout(()=>{
                        el.clickedAction = false;
                    }, 200)
                },
                filter_icon:function(e, id){
                    var el = this.$scope.$parent;
                    el.clickedAction = true;
                    el.enableFilter(id.row);
                    setTimeout(()=>{
                        el.clickedAction = false;
                    }, 200)
                    
                },
                copy_uid_icon:function(e, row){
                    var el = this.$scope.$parent;
                    el.clickedAction = true;
                    el.copyUID(row.row);
                    setTimeout(()=>{
                        el.clickedAction = false;
                    }, 200)
                }
            }
        },
        showTable: true,
        isLoading: false,
    }),
    computed:{
        ...clientViewComputed,
        ...clientComputed,
        getIconType(){
            if(this.type_filter != null){
                alert(this.type_filter)
                switch(this.type_filter){
                    case 'data_table':{
                        return 'fas fa-database'
                    }
                    case 'table':{
                        return 'fas fa-table'
                    }
                    case 'graph':{
                        return 'far fa-chart-bar'
                    }
                    case 'dynamic_text':{
                        return 'fas fa-paragraph'
                    }
                    case 'form':{
                        return 'fab fa-wpforms'
                    }
                    default:{
                        return ''
                    }
                }
            }
            else{
                return '';
            }
        },
        hasGroup(){
            if(this.group != ''){
                return true;
            }
            else{
                return false;
            }
        },
        currentGroup(){
            var item = null;
            if(this.group != ''){
                var group_item = _.find(this.view_groups,{id: this.group});
                if(group_item != undefined){
                    item = group_item;
                }
            }
            return item;
        },
        filteredGroups(){
            var groups = _.cloneDeep(this.view_groups);
            var index = _.findIndex(this.view_groups, {id: this.currentGroup.id});
            if(index != -1){
                groups.splice(index, 1);
            }
            return groups;
            
        },
        groupedViews(){
            if(this.currentGroup != null){
                var indexed_views = [];
                var idx = 1;
                this.currentGroup.views.forEach((item)=>{
                    if(this.enabled_filter == null){
                        let indexed_view = item;
                        indexed_view['index'] = idx;
                        indexed_views.push(indexed_view);
                        idx++;
                    }
                    else if(this.item.type == this.enabled_filter){
                        let indexed_view = item;
                        indexed_view['index'] = idx;
                        indexed_views.push(indexed_view);
                        idx++;
                    }
                });
                return indexed_views;
            }
            else{
                return []
            }
        },
        getViewName(){
            if(this.currentGroup != null){
                return this.currentGroup.name;
            }
            else{
                return '';
            }
        },
        returnSelectedGroup(){
            if(this.group != ''){
                return [{id:'1', name: 'Name Me22', description:'Talk to me22'}]
                //return this.groupedViews[this.group]
                //return [{id:'1', name: 'Name Me', description:'Talk to me'}, {id:'2', name: 'Name Me 2', description:'Talk to me'}]
            }
            else{
                return [];
            }
        },
        showOverlay(){
            if(this.isLoading){
                return 'border: 2px dodgerblue solid; pointer-events: none;'
            }
            else{
                return ''
            }
        }
    },
    


    methods:{
        ...clientViewMethods,
        ...clientMethods,

        saveGroupChange(){
            if(this.edit_group.current_view_id != null){
                var unlink_params = {
                    group_id: this.currentGroup.id,
                    view_id: this.edit_group.current_view_id
                };
                this.unlinkViewGroup(unlink_params)
                .then(()=>{
                    var link_params = {
                        group_id: this.edit_group.new_group_id,
                        view_id: this.edit_group.current_view_id
                    }
                    this.linkViewGroup(link_params)
                    .then(()=>{
                        this.$bvModal.hide('groupModal');
                        this.edit_group = { new_group_id: null,current_view_id: null };
                        
                        this.getViewGroups(this.selected_app.id)
                    })
                    .catch(()=>{
                        alert('LINK FAIL');
                        this.edit_group = { new_group_id: null,current_view_id: null };
                    })
                })
                .catch(()=>{
                    alert('FAILED UNLINK');
                    this.edit_group = { new_group_id: null,current_view_id: null };
                })

                
            }
            else{
                Swal.fire({
                    toast: true,
                    position: 'top',
                    iconColor: 'red',
                    icon: 'error',
                    title:'No Group Selected',
                    customClass: {
                        popup: 'error-toast'
                    },
                    showConfirmButton: false,
                    timer: 4000,
                    timerProgressBar: true
                })
            }

        },

        saveNewView(){
            if(this.newView.name == '' || this.newView.description == '' || this.newView.type == ''){
                alert('Please fill in all the fields')
            }
            else{
                this.isLoading = true;
                this.newView.id = -1;
                this.newView.client_app_id = this.selected_app.id;
                this.saveView(this.newView)
                .then((response)=>{
                    console.log(response);
                    this.newView = {
                        id: -1,
                        client_app_id: '',
                        name:'',
                        type:'',
                        description:'',
                    }
                    var link_params = {
                        group_id: this.group,
                        view_id: response.id
                    }
                    this.linkViewGroup(link_params)
                    .then(()=>{
                        Swal.fire({ toast: true, position: 'top', iconColor: 'white', icon: 'success', title:'Success', customClass: { popup: 'colored-toast' }, showConfirmButton: false, timer: 2000, timerProgressBar: true})
                        this.getViewGroups(this.selected_app.id)
                        .then(()=>{
                            this.isLoading = false;
                            this.$nextTick(()=>{
                                //this.selectedView(response.id);
                                /*if(showGraphWizard){
                                    this.openGraphWizardModal();
                                }*/
                                this.$refs.newViewForm.hide(true);
                            })
                        })
                        .catch(()=>{
                            this.isLoading = false;
                        })
                    })
                    .catch(()=>{
                        this.isLoading = false;
                        alert('LINKING ERROR!')
                    })
                })
                .catch(()=>{
                    this.isLoading = false;
                })
            }
        },

        saveEditView(){
            this.isLoading = true;
            this.saveView(this.edit_params)
            .then(()=>{
                this.getViewGroups(this.selected_app.id)
                .then(()=>{
                    this.$bvModal.hide('editModal');
                    this.isLoading = false;
                })
                .catch(()=>{
                    this.isLoading = false;
                })
            })
            .catch(()=>{
                this.isLoading = false;
            })
        },

        moveView(id){
            this.edit_group.current_view_id = id;
            this.showChangeGroupModal = true;
            this.$nextTick(()=>{
                this.$nextTick(()=>{
                    this.$bvModal.show('groupModal');
                    this.$root.$once('bv::modal::hidden',(event)=>{
                        if(event.type == 'hidden' && event.componentId == 'groupModal'){
                            this.showChangeGroupModal = false;
                        }
                    })
                })
            })
        },

        editView(id){
            console.log('edit'+id)
            /*var newView = {
                id: id,
                client_app_id: 1,
                name:'Rename Test',
                description:'Testing rename',
                type:'dynamic_text',
            }
            this.saveView(newView);*/
            var index = _.findIndex(this.groupedViews, {id: id});
            if(index != -1){
                this.edit_params['name'] = this.groupedViews[index].name;
                this.edit_params['description'] = this.groupedViews[index].description;
                this.edit_params['type'] = this.groupedViews[index].type;
                this.edit_params['id'] = this.groupedViews[index].id;
                this.edit_params['render_view_id'] = this.groupedViews[index].id;
                this.edit_params['client_app_id'] = this.groupedViews[index].client_app_id;

                

                this.showEditModal = true;
                this.$nextTick(()=>{
                    this.$bvModal.show('editModal');
                    this.$root.$once('bv::modal::hidden',(event)=>{
                        if(event.type == 'hidden' && event.componentId == 'editModal'){
                            this.showEditModal = false;
                        }
                    })
                })
                
            }
        },
        deleteSelectedView(id){
            var selectedView = _.find(this.groupedViews, {id: id})
            if(selectedView != undefined){
                Swal.fire({
                    title: 'Delete View?',
                    text: selectedView.name,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                })
                .then((result)=>{
                    if(result.isConfirmed){
                        this.isLoading = true;
                        Swal.fire({ toast: true, position: 'top', iconColor: 'white', icon: 'success', title:'Deleting View', customClass: { popup: 'white-toast' }, showConfirmButton: false, timer: 2000, timerProgressBar: true})

                        this.deleteView(id)
                        .then(()=>{
                            this.getViewGroups(this.selected_app.id)
                            .then(()=>{
                                this.isLoading = false;
                                Swal.fire({ toast: true, position: 'top', iconColor: 'white', icon: 'success', title:'Success', customClass: { popup: 'colored-toast' }, showConfirmButton: false, timer: 2000, timerProgressBar: true})
                            })
                        })
                        .catch(()=>{
                            console.log('Error deleting view');
                            this.isLoading = false;
                        })
                    }
                })
            }
        },
        copyUID(id){
            var view = _.find(this.groupedViews, {id: id})
            if(view != undefined){
                //this.display_uid = view.uid;
                this.textToClipboard(view.uid);
            }
            
        },
        displayView(id){
            var view = _.find(this.groupedViews, {id: id})
                if(view != undefined){
                    this.$eventHub.$emit('changeSelectedViewType', view.type);
                    this.selectedViewID = id;
                    this.setSelectedView({group: this.group, id: id})
                    .then(()=>{
                        this.$eventHub.$emit('selectedView');
                        /**if(this.selectedViewData.config_json == null && this.selectedViewData.type == "graph"){
                            this.openGraphWizardModal();
                        }*/
                    })
                }
                else{
                    alert('undefined');
                }
        },
        runFilterCheck(){
            if(this.type_filter != null){
                this.$nextTick(()=>{
                    //eslint-disable-next-line
                    var table = $$("groupViewTable");
                    table.filter("#type#", '');
                    table.filter("#type#", this.type_filter);
                })
            }
        },
        runSizeCheck(){
            this.$nextTick(()=>{
                if(this.$refs.webixDatatable.$el.children[0].clientWidth == 0){
                    //eslint-disable-next-line
                    $$("groupViewTable").refresh();
                }
            })
        },
        updateView(id){
            var view = _.find(this.groupedViews, {id: id})
            if(view != undefined){
                this.selectedViewID = id;
                this.setSelectedView({group: this.group, id: id})
                .then(()=>{
                    this.$eventHub.$emit('updatedView');
                    /**if(this.selectedViewData.config_json == null && this.selectedViewData.type == "graph"){
                        this.openGraphWizardModal();
                    }*/
                })
            }
            else{
                alert('undefined');
            }
        },
        //eslint-disable-next-line
        enableFilter(id){
            //eslint-disable-next-line
            var table = $$("groupViewTable");

            if(this.type_filter == null){
                table.filter("#type#", '');
                var item = _.find(this.groupedViews, {id:id});
                this.type_filter = item.type;
                table.filter('#type#', item.type);
            }
            else{
                table.filter("#type#", '');
                this.type_filter = null;
            }
        },

        refresh(){
            /*
            //eslint-disable-next-line no-undef
            var table = $$('groupViewTable');
            console.log(table);
            table.refresh();
            */
            this.showTable = false;
            this.$nextTick(()=>{
                this.showTable = true;
            })
        },
        textToClipboard (text) {
            var dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);

            Swal.fire({ toast: true, position: 'top', iconColor: 'success', icon: 'success', title:'Copied: ' + text, customClass: { popup: 'green-toast' }, showConfirmButton: false, timer: 2000, timerProgressBar: true})
}

    },
    mounted(){
        this.$eventHub.$on('runSizeCheck', this.runSizeCheck);
        this.$eventHub.$on('runFilterCheck', this.runFilterCheck);
        this.$eventHub.$on('displaySelectedView', this.updateView);
        setTimeout(()=>{
            //eslint-disable-next-line
            function isEq(a,b){
                if(a == null){
                    return false;
                }
                a = a.toString().toLowerCase();
                return a.indexOf(b) !== -1;
            }
            //eslint-disable-next-line
            $$("groupViewTable").filterByAll=function(){
                    //get filter values
                    if(this.getFilter("index") == null){
                        return false;
                    }
                    else{
                        var text = this.getFilter("index").value.toString().toLowerCase();
                        //unfilter for empty search text
                        if (!text) return this.filter();

                        //filter using OR logic
                        this.filter(function(obj){

                            if (isEq(obj.name, text)){
                                return true;
                            }
                            if (isEq(obj.description, text)) {
                                return true;
                            }
                            return false;
                        });
                    }
                }
        }, 100)
    },
    beforeDestroy(){
        this.$eventHub.$off('runFilterCheck');
        this.$eventHub.$off('runSizeCheck');
        this.$eventHub.$off('displaySelectedView');
    }
}
</script>

<style>
.icon-row > .col{
    padding-left: 0px;
    padding-right: 0px;
}
.webix_icon_hover:hover{
    font-size: large;
    cursor:pointer;
}
.hover{
      background:lightGrey;
  }


  .delete_icon:hover{
      color: crimson;
  }
  .edit_icon:hover{
      color: dodgerblue;
  }
  .group_icon:hover{
      color:limegreen;
  }
  .filter_icon:hover{
      color:mediumblue
  }
  .copy_uid_icon:hover{
      color: palevioletred;
  }
</style>