<template>
<div>
    <b-row v-if="config != null">
        <b-col cols="9">
            <div class="column-div">
                <div class="heading-background" >
                    <b-row v-if="selectedViewData != null">
                        <b-col>
                            <!--<h3>Element UID: <span><b-row><b-col cols="3"><b-form-input size="lg" style="width 200px;" :value="selectedViewData.uid" ref="copyUID"></b-form-input></b-col><b-col><b-button size="" v-on:click="copyText"><b-icon font-scale="1.8" icon="clipboard-plus"></b-icon></b-button></b-col></b-row></span></h3>-->
                            
                            <h3 v-if="editTable != undefined" style="padding-top: 0.8rem; padding-left: 0.2rem; padding-right: 0.2rem;" >
                                <b-button @click="navigateBack()" style="padding-top: 5px; padding-bottom: 5px;" variant="outline-primary">
                                    <b-icon icon="arrow-left" class="label-icon"></b-icon> Back
                                </b-button>
                                &nbsp;
                                {{editTable.name}}</h3>
                        </b-col>
                    </b-row>
                    <div v-if="config.columns != undefined">
                    <div v-if="config.columns.length > 0">
                        <b-row>
                            <b-col cols="12">
                                <b-button-group style="margin: 2px;">
                                    <b-button size="lg" style="color: black; background: #92d36e;'" class="btnThin" variant="outline-dark" >Inside</b-button>
                                    <b-button size="lg" class="btnThin" variant="outline-primary" >Outside</b-button>
                                </b-button-group>
                                <b-button-group style="margin: 2px;">
                                    <b-button size="lg" class="btnThin" v-on:click="changeCellSelectionMode('table')" :style="cellSelectionMode('table')" variant="outline-primary">Table</b-button>
                                    <!--<b-button size="lg" v-on:click="changeCellSelectionMode('cell')" :style="cellSelectionMode('cell')" class="btnThin" variant="outline-primary" >Cell</b-button>-->
                                    <b-button size="lg" v-on:click="changeCellSelectionMode('column')" :style="cellSelectionMode('column')" class="btnThin" variant="outline-primary" >Column</b-button>
                                    <!--<b-button size="lg" v-on:click="changeCellSelectionMode('row')" :style="cellSelectionMode('row')" class="btnThin" variant="outline-primary" >Row</b-button>-->
                                </b-button-group>
                                <b-overlay rounded opacity="0.6" spinner-small spinner-variant="success" class="d-inline-block" :show="isSavingConfig">
                                    <b-button size="lg" variant="success" style="margin: 3px;" class="btnThin" v-on:click="saveTableConfig"><b-icon font-scale="1.2" icon="check"></b-icon> Save Configuration</b-button>
                                </b-overlay>
                                <span v-if="config.select != ''" style="margin: 2px;">
                                    <b-button size="lg" variant="primary" class="btnThin" v-if="showToolsMenu" v-on:click="showToolsMenu = !showToolsMenu">Hide Tools</b-button>
                                    <b-button size="lg" variant="outline-primary" class="btnThin" v-else v-on:click="showToolsMenu = !showToolsMenu">Show Tools</b-button>
                                </span>
                                <span style="margin: 2px;">
                                    <b-button size="lg" variant="primary" @click="insertColumn" class="btnThin" >Add Column</b-button>
                                </span>
                                <b-button-group style="margin: 2px; float: right;">
                                    <b-button size="lg" class="btnThin" variant="outline-primary">Design</b-button>
                                    <b-button size="lg" class="btnThin" variant="outline-primary" >Preview</b-button>
                                </b-button-group>
                            </b-col>
                            <!--<b-col cols="2">
                                <b-button-group style="margin: 2px;">
                                    <b-button size="lg" style="color: black; background: #92d36e;'" class="btnThin" variant="outline-dark" >Inside</b-button>
                                    <b-button size="lg" class="btnThin" variant="outline-primary" >Outside</b-button>
                                </b-button-group>
                            </b-col>
                            <b-col cols="3">
                                <b-button-group style="margin: 2px;">
                                    <b-button size="lg" class="btnThin" v-on:click="changeCellSelectionMode('table')" :style="cellSelectionMode('table')" variant="outline-primary">Table</b-button>
                                    <b-button size="lg" v-on:click="changeCellSelectionMode('cell')" :style="cellSelectionMode('cell')" class="btnThin" variant="outline-primary" >Cell</b-button>
                                    <b-button size="lg" v-on:click="changeCellSelectionMode('column')" :style="cellSelectionMode('column')" class="btnThin" variant="outline-primary" >Column</b-button>
                                    <b-button size="lg" v-on:click="changeCellSelectionMode('row')" :style="cellSelectionMode('row')" class="btnThin" variant="outline-primary" >Row</b-button>
                                </b-button-group>
                            </b-col>
                            <b-col cols="3">
                                <b-overlay rounded opacity="0.6" spinner-small spinner-variant="success" class="d-inline-block" :show="isSavingConfig">
                                    <b-button size="lg" variant="success" style="margin: 3px;" class="btnThin" v-on:click="saveTableConfig"><b-icon font-scale="1.2" icon="check"></b-icon> Save Configuration</b-button>
                                </b-overlay>
                            </b-col>
                            <b-col cols="2">
                                <div v-if="config.select != ''" style="margin: 2px;">
                                    <b-button size="lg" variant="primary" class="btnThin" v-if="showToolsMenu" v-on:click="showToolsMenu = !showToolsMenu">Hide Tools</b-button>
                                    <b-button size="lg" variant="outline-primary" class="btnThin" v-else v-on:click="showToolsMenu = !showToolsMenu">Show Tools</b-button>
                                </div>
                            </b-col>
                            
                            <b-col cols="2">
                                <b-button-group style="margin: 2px; float: right;">
                                    <b-button size="lg" class="btnThin" variant="outline-primary">Design</b-button>
                                    <b-button size="lg" class="btnThin" variant="outline-primary" >Preview</b-button>
                                </b-button-group>
                            </b-col>-->
                        </b-row>
                        <b-row v-if="config.select != ''">
                            <b-col cols="12">
                                <b-collapse id="accordion-tools" v-model="showToolsMenu">
                                    <b-row v-if="hasSelectedCell == true">
                                        <b-col>
                                            <div style="border: 1px solid grey; border-radius: 0.2rem; margin: 0.4em; padding: 1em;">
                                                <b-form>
                                                    <b-row>
                                                        <b-col>
                                                            <!--<b-button-group style="margin: 2px;">
                                                                <b-button size="lg" class="btnThin" :style="cellSelectionMode('table')" variant="outline-primary">Table</b-button>
                                                                <b-button size="lg" v-on:click="changeCellSelectionMode('cell')" :style="cellSelectionMode('cell')" class="btnThin" variant="outline-primary" >Cell</b-button>
                                                                <b-button size="lg" v-on:click="changeCellSelectionMode('column')" :style="cellSelectionMode('column')" class="btnThin" variant="outline-primary" >Column</b-button>
                                                                <b-button size="lg" v-on:click="changeCellSelectionMode('row')" :style="cellSelectionMode('row')" class="btnThin" variant="outline-primary" >Row</b-button>
                                                            </b-button-group>-->
                                                            <b-dropdown ref="addRowDropdown" size="lg" variant="outline-primary" class="dropdownThin"  style="margin: 2px;">
                                                                <template #button-content>
                                                                    Add Row <b-icon size="sm" icon="caret-down-fill"></b-icon>
                                                                </template>
                                                                <b-dropdown-item v-on:click="addNewRow('empty')" class="dropdownItem">Empty Row</b-dropdown-item>
                                                                <b-dropdown-item v-on:click="addNewRow('data')" class="dropdownItem">Row With Data</b-dropdown-item>
                                                            </b-dropdown>
                                                            <!--<ColumnForm style="margin: 3px;" />-->
                                                            <b-button size="lg" class="btnThin" variant="outline-primary"  style="margin: 3px; color: crimson;" v-on:click="clearSelection"><b-icon icon="x-square" style="margin-right: 5px;"></b-icon>Clear Selection</b-button>
                                                            <b-button style="margin: 3px; color: crimson;" size="lg" class="btnThin" variant="outline-danger" v-on:click="deleteAction"><b-icon icon="trash" style="margin-right: 5px;"></b-icon>Delete {{config.select}}</b-button>

                                                            <!--<StylePainter />-->
                                                            
                                                            <b-button size="lg" class="btnThin" :variant="toggleEditDataCell ? 'primary' : 'outline-primary'" style="margin: 3px;" v-on:click="toggleEditDataCell = !toggleEditDataCell"><b-icon icon="intersect" style="margin-right: 5px;"></b-icon>Toggle Data Cell Editor</b-button>

                                                            <b-button size="lg" class="btnThin" :variant="showCreateSpan ? 'primary' : 'outline-primary'" style="margin: 3px;" v-on:click="toggleCreateSpanElement(false)"><b-icon icon="grid1x2" style="margin-right: 5px;"></b-icon>Create Span</b-button>
                                                            <b-dropdown class="dropdownThin" text="Edit Spans">
                                                                <b-dropdown-item class="dropdownItem" v-on:click="highlightSpan(span[0], span[1], span[2], span[3])" v-for="(span, index) in config.data.spans" :key="span[0]+span[1]" > <!-- @mouseenter="highlightSpan(span[0], span[1], span[2], span[3])" @mouseleave="clearSpanSelection"-->
                                                                    <span><strong>{{index+1}}.</strong>Text: {{span[4]}}</span>
                                                                </b-dropdown-item>
                                                            </b-dropdown>
                                                            <!--<b-button style="margin: 3px;" size="lg" disabled class="btnThin" variant="outline-primary" ><b-icon icon="brush" style="margin-right: 5px;"></b-icon>Style Painter</b-button>-->
                                                            <b-button style="margin: 3px;" size="lg" v-on:click="reloadTable" class="btnThin" variant="outline-dark"><b-icon style="margin-right: 5px;" font-scale="1.2" icon="arrow-repeat"></b-icon>Hard Reload</b-button>
                                                            <b-dropdown ref="tableConfigurationDropdown" style="margin: 3px;" size="lg" variant="outline-primary" class="dropdownThin">
                                                                <template #button-content>
                                                                    <b-icon size="sm" icon="gear"></b-icon> Table Configuration
                                                                </template>
                                                                <b-dropdown-form style="padding: 10px;">
                                                                    <b-form-group>
                                                                        <b-row >
                                                                            <b-col cols="12">
                                                                                <b-form-checkbox class="configCheckbox" v-model="config.header">&nbsp;Table Header</b-form-checkbox>
                                                                            </b-col>
                                                                            <b-col cols="12">
                                                                                <b-form-checkbox class="configCheckbox" v-model="config.fillspace">&nbsp;Fillspace</b-form-checkbox>
                                                                            </b-col>
                                                                            <b-col cols="12">
                                                                                <b-form-checkbox class="configCheckbox" v-model="config.autowidth">&nbsp;Auto Width </b-form-checkbox>
                                                                            </b-col>
                                                                        </b-row>
                                                                    </b-form-group>
                                                                    <b-button variant="primary" v-on:click="applySettings">Apply Settings</b-button>
                                                                    <!--<div class="text-center"><b-overlay :show="success" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block"><b-button variant="primary" v-on:click="reloadTable">Apply Settings</b-button></b-overlay></div>-->
                                                                </b-dropdown-form>
                                                            </b-dropdown>

                                                            <b-button size="lg" class="btnThin" variant="outline-primary" style="margin: 3px;" v-on:click="showGenerateTokenModal"><b-icon icon="key" style="margin-right: 5px;"></b-icon>Generate Token</b-button>

                                                            <b-button size="lg" class="btnThin" variant="outline-danger" style="margin: 3px;" v-on:click="convertToMultilineHeader">Convert To Multiline</b-button>
                                                            <b-button size="lg" class="btnThin" variant="outline-danger" style="margin: 3px;" v-on:click="undoMultiline">Undo Multiline</b-button>

                                                            <b-overlay rounded opacity="0.6" spinner-small spinner-variant="success" class="d-inline-block" :show="isSavingConfig">
                                                                <b-button size="lg" variant="success" style="margin: 3px;" class="btnThin" v-on:click="saveTableConfig"><b-icon font-scale="1.2" icon="check"></b-icon> Save Configuration</b-button>
                                                            </b-overlay>
                                                        </b-col>
                                                    </b-row>
                                                    <div v-if="showCreateSpan">
                                                        <div v-if="editCell.span.width == 0 || editCell.span.height == 0">
                                                            <hr>
                                                            <h4 style="text-align: center;">Draw the COLUMN or ROW span on the table</h4>
                                                        </div>
                                                        <div v-else>
                                                            <hr>
                                                            <b-row>
                                                                <b-col cols="2">
                                                                    <label for="" style="width: 100%;">Columns Width</label>
                                                                    <b-button-group>
                                                                        <b-button variant="primary" style="min-width: 3rem; pointer-events: none;">{{editCell.span.width}}</b-button>
                                                                        <b-button v-on:click="spanChangeWidth" variant="outline-info" style="padding: 0px;"><b-icon font-scale="2.6" icon="plus"></b-icon></b-button>
                                                                        <b-button :disabled="editCell.span.width <= 1" v-on:click="editCell.span.width--" variant="outline-info" style="padding: 0px;"><b-icon font-scale="2.6" icon="dash"></b-icon></b-button>
                                                                    </b-button-group>
                                                                </b-col>
                                                                <b-col cols="2">
                                                                    <label style="width: 100%;">Rows Height</label>
                                                                    <b-button-group>
                                                                        <b-button variant="primary" style="min-width: 3rem; pointer-events: none;">{{editCell.span.height}}</b-button>
                                                                        <b-button v-on:click="spanChangeHeight" variant="outline-info" style="padding: 0px;"><b-icon font-scale="2.6" icon="plus"></b-icon></b-button>
                                                                        <b-button :disabled="editCell.span.height <= 1" v-on:click="editCell.span.height--" variant="outline-info" style="padding: 0px;"><b-icon font-scale="2.6" icon="dash"></b-icon></b-button>
                                                                    </b-button-group>
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <label for="">Span Text</label>
                                                                    <b-form-input v-model="editCell.span.value"></b-form-input>
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <label for="">Span Style</label>
                                                                    <b-form-input v-model="editCell.span.css"></b-form-input>
                                                                </b-col>
                                                                <b-col cols="2">
                                                                    <label style="visibility: hidden; width: 100%;" for="">hidden</label>
                                                                    <b-button variant="success" v-on:click="saveSpanData">
                                                                        <b-icon icon="check"></b-icon>
                                                                    </b-button>
                                                                    &nbsp;
                                                                    <b-button variant="danger" v-on:click="deleteSpan(editCell.span.row, editCell.span.column)">
                                                                        <b-icon icon="trash"></b-icon>
                                                                    </b-button>
                                                                </b-col>
                                                            </b-row>
                                                        </div>
                                                    </div>
                                                </b-form>
                                                <!--<b-button variant="success" v-on:click="showColumnModal">Add Column</b-button>-->
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-collapse>
                            </b-col>
                        </b-row>
                        
                    </div>
                </div>
            </div>
                            <!-- ## Table Preview ## -->
            <div>
                <b-row>
                    <b-col>
                        <div style="text-align: center; margin: 20px;" v-if="config.columns.length == 0">
                            <!--<b-button size="lg" variant="outline-primary" v-on:click="showNewTableModal">
                                <b-icon font-scale="1.5" icon="plus-square"></b-icon>&nbsp;
                                New Table
                            </b-button>-->
                            <h4>No Table Selected</h4>
                        </div>
                        <div ref="tableBuilderContainer" v-else style="overflow: auto; height: 30rem;">
                            <div :style="setElementWidth" style="height: 1px; width: 1px;background: blue;"></div>
                            <div ref="preventResizeSnap" :style="setElementHeight" style="width: 0.05%; background: red;"></div>
                            <div ref="webixDatatable" style="width: 99.95%;">
                                <webix-ui v-if="showTable" :config="config" v-model="config.data"/>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
            </div>
        </b-col>
        <b-col cols="3" v-if="selectedViewData != null">
            <div class="column-div" v-if="selectedViewData.config_json != null">
                <div v-if="hasSelectedCell == false">
                    <b-row>
                        <b-col>
                            <div style="margin-bottom: 0.5rem;">
                                <div class="column-heading" v-on:click="toggleCollapseMenu('properties')"> <!--  -->
                                    <h4 style="margin: 0px;">Configuration <b-icon style="float: right;" :icon="table_properties.expanded ? 'dash' : 'plus'"></b-icon></h4>
                                </div>
                                <b-collapse id="accordion-styles" v-model="table_properties.expanded">
                                    <b-form class="p-1">
                                        <b-row class="btnRow">
                                            <b-col cols="3">
                                                <label class="col-form-label">Name</label>
                                            </b-col>
                                            <b-col>
                                                <b-form-input v-model="selectedViewData.name"></b-form-input>
                                            </b-col>
                                        </b-row>
                                        <b-row class="btnRow">
                                            <b-col cols="3">
                                                <label class="col-form-label">Description</label>
                                            </b-col>
                                            <b-col>
                                                <b-form-textarea rows="3" max-rows="6" v-model="selectedViewData.description"></b-form-textarea>
                                            </b-col>
                                        </b-row>
                                        <b-row class="btnRow">
                                            <b-col cols="3">
                                                <label class="col-form-label">Rows</label>
                                            </b-col>
                                            <b-col cols="3">
                                                <b-form-input min=1 type="number" v-model="selectedViewData.config_json.columns.length"></b-form-input>
                                            </b-col>
                                        </b-row>
                                        <b-row class="btnRow">
                                            <b-col cols="3">
                                                <label class="col-form-label">Cols</label>
                                            </b-col>
                                            <b-col cols="3">
                                                <b-form-input min=1 type="number" v-model="selectedViewData.config_json.data.data.length"></b-form-input>
                                            </b-col>
                                        </b-row>
                                        <!--<b-row>
                                            <b-col cols="3">
                                                <label for="template">Template</label>
                                            </b-col>
                                            <b-col>
                                                <b-form-input id="template" v-model="getTemplateName"></b-form-input>
                                            </b-col>
                                            <b-col cols="2">
                                                <div @click="editReport(report.id)" style="height: 100%; display: flex; align-items: center; justify-content: center;  border: 0px; border-radius: 0.3em; background:lightGrey; cursor:pointer;">
                                                    <b-icon icon="file-earmark-code-fill"></b-icon>
                                                </div>
                                            </b-col>
                                        </b-row>-->
                                        
                                    </b-form>
                                </b-collapse>
                            </div>
                            <!-- Spans -->
                            <div style="margin-bottom: 0.5rem;">
                                <div class="column-heading" v-on:click="toggleCollapseMenu('span')"> <!--  -->
                                    <h4 style="margin: 0px;">Spans <b-icon style="float: right;" :icon="table_spans.expanded ? 'dash' : 'plus'"></b-icon></h4>
                                </div>
                                <b-collapse id="accordion-styles" v-model="table_spans.expanded">
                                    <div v-if="selectedViewData.config_json.data.spans.length > 0" style="margin-bottom: 0.25rem;">
                                        <b-row v-for="(span, index) in config.data.spans" :key="span[0]+span[1]" style="margin-top: 0.25rem; margin-left: 0.25rem; margin-right: 0.25rem; border:1px solid grey;">
                                            <b-col cols="10" style="cursor: pointer; height: 1.5em; overflow: hidden;" v-on:click="highlightSpan(span[0], span[1], span[2], span[3])"  > <!-- @mouseenter="highlightSpan(span[0], span[1], span[2], span[3])" @mouseleave="clearSpanSelection"-->
                                                <span :title="span[4]"><strong>{{index+1}}.</strong>Text: {{span[4]}}</span>
                                            </b-col>
                                            <b-col>
                                                <b-icon style="float: right; margin-top: 0.25rem;" v-on:click="deleteSpan(span[0], span[1])" scale="1" icon="trash"></b-icon>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <div v-else style="margin: 0.5rem;">
                                        No Spans Added.
                                    </div>
                                </b-collapse>
                            </div>
                            <!-- css -->
                            <div class="column-heading" v-on:click="toggleCollapseMenu('styling')"> <!--  -->
                                <h4 style="margin: 0px;">Styling <b-icon style="float: right;" :icon="table_styling.expanded ? 'dash' : 'plus'"></b-icon></h4>
                            </div>
                            <b-collapse id="accordion-styles" v-model="table_styling.expanded">
                                <b-row class="btnRow">
                                    <b-col cols="3">
                                        <label class="col-form-label">Table CSS</label>
                                    </b-col>
                                    <b-col cols="9">
                                        <b-form-input v-model="config.css" ></b-form-input>
                                    </b-col>
                                </b-row>
                                <b-row class="btnRow">
                                    <b-col>
                                        <b-button style="margin: 0.2rem;" variant="success" @click="updateThemeChange()">Save Theme</b-button>
                                    </b-col>
                                </b-row>
                            </b-collapse>
                        </b-col>
                    </b-row>
                </div>

<!--            ################
                # CELL OPTIONS #
                ################-->

                <div v-if="hasSelectedCell">
                    <div class="animated fadeIn" v-if="editCell.selectedRowID == null" style="text-align:center; padding-top:30px; padding-bottom: 30px;">
                        <h4>No {{config.select}} selected</h4>
                    </div>

                    <div v-if="showTable && this.editCell.selectedRowID != null">
                        <div v-if="config.select == 'cell'">
                            <div class="column-heading" v-on:click="table_spans.expanded = !table_spans.expanded"> <!--  -->
                                <h4 style="margin: 0px;">Properties <b-icon style="float: right;" :icon="table_spans.expanded ? 'dash' : 'plus'"></b-icon></h4>
                            </div>
                            <b-collapse style="padding:5px;" id="accordion-styles" v-model="table_spans.expanded">
                                <b-form @submit="savedFromEnterKey" id="cellConfigForm">
                                    <b-row class="btnRow">
                                        <b-col cols="4">
                                            <label class="col-form-label">Type</label>
                                        </b-col>
                                        <b-col>
                                            <b-dropdown variant="light" class="cssDropdownList" style="border-radius: 4px; border: 1px solid grey;">
                                                <template #button-content>
                                                    {{upperCase(editCell.cell.type)}}  <b-icon icon="caret-down-fill"></b-icon>
                                                </template>
                                                <b-dropdown-item class="dropdownItem" v-on:click="switchCellDataType('static')">Static Text</b-dropdown-item>
                                                <b-dropdown-item class="dropdownItem" v-on:click="switchCellDataType('data')">Data Cell</b-dropdown-item>
                                            </b-dropdown>
                                        </b-col>
                                    </b-row>
                                    <b-row class="btnRow" v-if="editCell.cell.type == 'static'">
                                        <b-col cols="4">
                                            <label class="col-form-label">Cell Text</label>
                                        </b-col>
                                        <b-col>
                                            <b-form-input ref="currentCellInput" v-model="editCell.cell.text"></b-form-input>
                                        </b-col>
                                    </b-row>
                                    <b-row class="btnRow" v-if="editCell.cell.type == 'data'">
                                        <b-col cols="4">
                                            <label class="col-form-label">Data Source</label>
                                        </b-col>
                                        <b-col>
                                            <b-dropdown variant="light" class="cssDropdownList" style="border-radius: 4px; border: 1px solid grey;">
                                                <template #button-content>
                                                    {{displayDataSource(editCell.cell.data_source)}} <b-icon icon="caret-down-fill"></b-icon>
                                                </template>
                                                <b-dropdown-item class="dropdownItem" v-on:click="setDataType('spreadsheet')">Spreadsheet</b-dropdown-item>
                                                <b-dropdown-item class="dropdownItem" v-on:click="setDataType('spreadify_view')">Spreadify View</b-dropdown-item>
                                            </b-dropdown>
                                        </b-col>
                                    </b-row>
                                    <b-row class="btnRow">
                                        <b-col cols="4">
                                            <label class="col-form-label">Inline Edit</label>
                                        </b-col>
                                        <b-col class="pt-2">
                                            <b-form-checkbox v-model="editCell.editable" class="mt-1" switch></b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row class="btnRow" v-if="isCellEditable">
                                        <b-col cols="4">
                                            <label class="col-form-label">Cell Variable</label>
                                        </b-col>
                                        <b-col>
                                            <b-form-input v-model="editCell['cellVariable']"></b-form-input>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </b-collapse>

                            <div class="column-heading" v-on:click="table_styling.expanded = !table_styling.expanded"> <!--  -->
                                <h4 style="margin: 0px;">Styling <b-icon style="float: right;" :icon="table_styling.expanded ? 'dash' : 'plus'"></b-icon></h4>
                            </div>
                            <b-collapse style="padding:5px;" id="accordion-styles" v-model="table_styling.expanded">
                                <b-row class="btnRow">
                                    <b-col>
                                        <b-dropdown variant="info" class="cssDropdownList">
                                            <template #button-content>
                                                Add CSS Class <b-icon size="sm" icon="caret-down-fill"></b-icon>
                                            </template>
                                            <b-dropdown-item class="dropdownItem" v-on:click="addCss('a_heading')">Heading A</b-dropdown-item>
                                            <b-dropdown-item class="dropdownItem" v-on:click="addCss('heading')">Heading</b-dropdown-item>
                                            <b-dropdown-item class="dropdownItem" v-on:click="addCss('total')">Total</b-dropdown-item>
                                            <b-dropdown-item class="dropdownItem" v-on:click="addCss('center')">Center</b-dropdown-item>
                                            <b-dropdown-item class="dropdownItem" v-on:click="addCss('left')">Left</b-dropdown-item>
                                            <b-dropdown-item class="dropdownItem" v-on:click="addCss('right')">Right</b-dropdown-item>
                                            <b-dropdown-item class="dropdownItem" v-on:click="addCss('merged')">Merge</b-dropdown-item>
                                            <b-dropdown-item class="dropdownItem" v-on:click="addCss('bold')">Bold</b-dropdown-item>
                                            <b-dropdown-item class="dropdownItem" v-on:click="addCss('wrap_text')">Wrap</b-dropdown-item>
                                        </b-dropdown>
                                    </b-col>
                                </b-row>
                                <div class='btnRow' style="display: flex; flex-wrap: wrap; background: white; border-radius: 6px;">
                                    <div style="margin: 5px;" v-for="(item, idx) in selectedCellCss" :key="idx">
                                        <b-dropdown class="cssDropdownList buttonItem" block variant="outline-primary" :text="item">
                                            <b-dropdown-item class="cssDropdown" v-on:click="deleteCss(item)">Delete <b-icon icon="trash"></b-icon></b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                    <!--<b-form-tag input-id="css-tags" v-model="selectedCellCss"></b-form-tag>-->
                                </div>
                                <!--<b-row class="btnRow">
                                    <b-col>
                                        <b-button variant="primary" v-on:click="saveCellConfig">Save</b-button>
                                    </b-col>
                                </b-row>-->
                            </b-collapse>
<!-- ######################################################################-->
                            <div v-if="editCell.cell.type == 'data' && editCell.cell.data_source != ''">
                                <div class="column-heading"> <!--  -->
                                    <h4 style="margin: 0px;">Mapping <b-icon v-on:click="table_spans.expanded = !table_spans.expanded" style="float: right;" :icon="table_spans.expanded ? 'dash' : 'plus'"></b-icon></h4>
                                </div>
                                <b-collapse id="accordion-styles" style="padding:5px;" v-model="table_spans.expanded">
                                    <b-form @submit="savedFromEnterKey" id="cellConfigForm">
                                        <div v-if="editCell.cell.data_source == 'spreadsheet'">
                                            <b-row class="btnRow">
                                                <b-col cols="4">
                                                    <label>Sheet</label>
                                                </b-col>
                                                <b-col>
                                                    
                                                    <b-dropdown size="sm" variant="light" style="border-radius: 4px; border: 1px solid grey;">
                                                        <template #button-content>
                                                            {{returnSheetNameFromId(editCell.cell.sheetID)}} <b-icon size="sm" icon="caret-down-fill"></b-icon>
                                                        </template>
                                                        <b-dropdown-item v-on:click="editCell.cell.sheetName = sheet.name; editCell.cell.sheetID = sheet.id;" v-for="sheet in spreadsheets" :key="sheet.id">{{sheet.name}}</b-dropdown-item>
                                                    </b-dropdown>
                                                </b-col>
                                            </b-row>
                                            <b-row class="btnRow">
                                                <b-col cols="4">
                                                    <label>Tab</label>
                                                </b-col>
                                                <b-col>
                                                    <b-dropdown variant="light" style="border-radius: 4px; border: 1px solid grey;" size="sm">
                                                        <template #button-content>
                                                            {{returnTabName(editCell.cell.tabName)}} <b-icon size="sm" icon="caret-down-fill"></b-icon>
                                                        </template>
                                                        <b-dropdown-item v-for="(tab, idx) in available_tabs" :key="idx" v-on:click="editCell.cell.tabName = tab" >{{tab}}</b-dropdown-item>
                                                    </b-dropdown>
                                                </b-col>
                                            </b-row>
                                            <b-row class="btnRow">
                                                <b-col cols="4">
                                                    <label>Format</label>
                                                </b-col>
                                                <b-col>
                                                    <b-dropdown size="sm" variant="light" style="border-radius: 4px; border: 1px solid grey;">
                                                        <template #button-content :formatter="upperCase" >
                                                            {{returnDataType()}}  <b-icon icon="caret-down-fill"></b-icon>
                                                        </template>
                                                        <b-dropdown-item v-on:click="editCell.cell.dataType = ''">None</b-dropdown-item>
                                                        <b-dropdown-item v-for="(type, index) in cellDataTypes" :key="index" v-on:click="editCell.cell.dataType = type">{{type}}</b-dropdown-item>
                                                    </b-dropdown>
                                                </b-col>
                                            </b-row>
                                            <b-row class="btnRow">
                                                <b-col cols="4">
                                                    <label>Source Cell</label>
                                                </b-col>
                                                <b-col>
                                                    <b-form-input :formatter="upperCase" ref="currentCellMappingInput" v-model="editCell.cell.cellID"></b-form-input>
                                                </b-col>
                                            </b-row>
                                        </div>
                                        <div v-if="editCell.cell.data_source == 'spreadify_view'">
                                            <b-row class="btnRow">
                                                <b-col cols="4">
                                                    <label>Source View</label>
                                                </b-col>
                                                <b-col>
                                                    <b-dropdown size="sm" variant="light" style="border-radius: 4px; border: 1px solid grey;">
                                                        <template #button-content >
                                                            {{returnDataSource()}}  <b-icon icon="caret-down-fill"></b-icon>
                                                        </template>
                                                        <b-dropdown-item disabled>Select Dynamic View</b-dropdown-item>
                                                        <b-dropdown-item v-for="(view, index) in returnAllDynamicViews" :key="index" v-on:click="editCell.cell.source_view_id = view.uid">{{view.name}}</b-dropdown-item>
                                                    </b-dropdown>
                                                    <!--<b-form-input :formatter="upperCase" ref="currentCellViewInput" v-model="editCell.cell.source_view_id"></b-form-input>-->
                                                </b-col>
                                            </b-row>
                                        </div>
                                        <b-row class="btnRow">
                                            <b-col cols="12" v-if="editCell.cell.type == 'data'">
                                                <label style="visibility: hidden; width: 100%;" for="">hidden</label>
                                                <b-button v-on:click="saveCellMapping" variant="success">Save</b-button>
                                                &nbsp;
                                                <b-button v-on:click="deleteCellMapping(editCell.cell.mapID)" variant="danger">Delete</b-button>
                                                &nbsp;
                                                <b-button v-on:click="lockSheetAndTabValues()" :variant="lockedSheetAndTab ? 'info' : 'outline-info'">Lock Sheet & Tab</b-button>
                                            </b-col>
                                        </b-row>
                                    </b-form>
                                </b-collapse>
                            </div>
                            <b-button style="margin: 5px;" variant="success" v-if="editCell.cell.type=='static'" v-on:click="saveCellConfig">Save</b-button>
                        </div>

<!--                    #######################
                        #        COLUMN       #
                        ####################### -->

                        <div v-if="config.select == 'column'">
                            <div class="column-heading"> <!--  -->
                                <h4 style="margin: 0px;">Styling <b-icon v-on:click="table_styling.expanded = !table_styling.expanded" style="float: right;" :icon="table_styling.expanded ? 'dash' : 'plus'"></b-icon></h4>
                            </div>
                            <b-collapse style="padding:5px;" id="accordion-styles" v-model="table_styling.expanded">
                                <b-row class="btnRow">
                                    <b-col cols="4">
                                        <label class="col-form-label" for="">Header Name</label>
                                    </b-col>
                                    <b-col cols="8">
                                        <b-form-input v-model="editCell.column.header"></b-form-input>
                                    </b-col>
                                    <b-col cols="12">
                                        <b-button @click="applyHeader" variant="success">Apply</b-button>
                                    </b-col>
                                </b-row>
                                <hr>
                                <b-row class="btnRow">
                                    <b-col cols="4">
                                        <label class="col-form-label" for="">Width (min: 25)</label>
                                    </b-col>
                                    <b-col cols="4">
                                        <b-form-input type="number" min=25 v-model="editCell.column.width"></b-form-input>
                                    </b-col>
                                    <b-col cols="3">
                                        <b-form-input readonly placeholder="Pixels" t></b-form-input>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-row>
                                            <b-col>
                                                <label style="visibility: hidden;width: 100%;" for="">Hidden Label</label>
                                                <b-dropdown variant="info">
                                                    <template #button-content>
                                                        Add CSS Class <b-icon size="sm" icon="caret-down-fill"></b-icon>
                                                    </template>
                                                    <b-dropdown-item class="dropdownItem" v-on:click="addCss('a_heading')">Heading A</b-dropdown-item>
                                                    <b-dropdown-item class="dropdownItem" v-on:click="addCss('heading')">Heading</b-dropdown-item>
                                                    <b-dropdown-item class="dropdownItem" v-on:click="addCss('total')">Total</b-dropdown-item>
                                                    <b-dropdown-item class="dropdownItem" v-on:click="addCss('center')">Center</b-dropdown-item>
                                                    <b-dropdown-item class="dropdownItem" v-on:click="addCss('left')">Left</b-dropdown-item>
                                                    <b-dropdown-item class="dropdownItem" v-on:click="addCss('right')">Right</b-dropdown-item>
                                                    <b-dropdown-item class="dropdownItem" v-on:click="addCss('merged')">Merge</b-dropdown-item>
                                                    <b-dropdown-item class="dropdownItem" v-on:click="addCss('bold')">Bold</b-dropdown-item>
                                                </b-dropdown>
                                            </b-col>
                                    </b-row>
                                    <div class='btnRow' style="display: flex; background: white; border-radius: 6px;">
                                        <div style="margin: 5px;" v-for="(item, idx) in selectedColumnCss" :key="idx">
                                            
                                            <b-dropdown class="cssDropdownList" variant="outline-primary" :text="item">
                                                <b-dropdown-item v-on:click="deleteCss(item)" class="cssDropdown">Delete {{item}} <b-icon icon="trash"></b-icon></b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                        <!--<b-form-tag input-id="css-tags" v-model="selectedCellCss"></b-form-tag>-->
                                    </div>
                                    </b-col>
                                </b-row>
                                <b-row class="btnRow">
                                    <b-col>
                                        <b-button variant="success" v-on:click="saveColumn">Save Column</b-button>
                                        &nbsp;
                                        <b-button variant="danger" v-on:click="deleteColumn">Delete Column</b-button>
                                    </b-col>
                                </b-row>
                            </b-collapse>

                            <!-- -->
                            <div class="column-heading"> <!--  -->
                                <h4 style="margin: 0px;">Data Source <b-icon v-on:click="table_styling.expanded = !table_styling.expanded" style="float: right;" :icon="table_styling.expanded ? 'dash' : 'plus'"></b-icon></h4>
                            </div>
                            <b-collapse style="padding:5px;" id="accordion-styles" v-model="table_styling.expanded">
                                <b-row class="btnRow">
                                    <b-col cols="4">
                                        <label class="col-form-label" for="">Variable Key</label>
                                    </b-col>
                                    <b-col cols="8" v-if="refreshValue">
                                        <b-form-input v-model="editCell.column.key"></b-form-input>
                                    </b-col>
                                    <b-col cols="8" v-else>
                                        <b-form-input></b-form-input>
                                    </b-col>
                                </b-row>
                                
                                <b-row class="btnRow">
                                    <b-col>
                                        <b-button variant="success" v-on:click="saveJSONMapping">Save Variable</b-button>
                                    </b-col>
                                </b-row>
                            </b-collapse>
                        </div>
                        <div v-if="config.select == 'row'">
                            <div class="column-heading"> <!--  -->
                                <h4 style="margin: 0px;">Styling <b-icon v-on:click="table_styling.expanded = !table_styling.expanded" style="float: right;" :icon="table_styling.expanded ? 'dash' : 'plus'"></b-icon></h4>
                            </div>
                            <b-collapse style="padding:5px;" id="accordion-styles" v-model="table_styling.expanded">
                                <b-row class="btnRow">
                                    <b-col cols="4">
                                        <label class="col-form-label" for="">Height</label>
                                    </b-col>
                                    <b-col cols="4">
                                        <b-form-input type="number" v-model.number="editCell.row.height"></b-form-input>
                                    </b-col>
                                    <b-col cols="3">
                                        <b-form-input readonly placeholder="Pixels" t></b-form-input>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-row>
                                        <b-col>
                                            <label style="visibility: hidden;width: 100%;" for="">Hidden Label</label>
                                            <b-dropdown variant="info">
                                                <template #button-content>
                                                    Add CSS Class <b-icon size="sm" icon="caret-down-fill"></b-icon>
                                                </template>
                                                <b-dropdown-item class="dropdownItem" v-on:click="addCss('a_heading')">Heading A</b-dropdown-item>
                                                <b-dropdown-item class="dropdownItem" v-on:click="addCss('heading')">Heading</b-dropdown-item>
                                                <b-dropdown-item class="dropdownItem" v-on:click="addCss('total')">Total</b-dropdown-item>
                                                <b-dropdown-item class="dropdownItem" v-on:click="addCss('center')">Center</b-dropdown-item>
                                                <b-dropdown-item class="dropdownItem" v-on:click="addCss('left')">Left</b-dropdown-item>
                                                <b-dropdown-item class="dropdownItem" v-on:click="addCss('right')">Right</b-dropdown-item>
                                                <b-dropdown-item class="dropdownItem" v-on:click="addCss('merged')">Merge</b-dropdown-item>
                                                <b-dropdown-item class="dropdownItem" v-on:click="addCss('bold')">Bold</b-dropdown-item>
                                            </b-dropdown>
                                        </b-col>
                                    </b-row>
                                    <div class='btnRow' style="display: flex; background: white; border-radius: 6px;">
                                        <div style="margin: 5px;" v-for="(item, idx) in selectedRowCss" :key="idx">
                                            
                                            <b-dropdown class="cssDropdownList" variant="outline-primary" :text="item">
                                                <b-dropdown-item v-on:click="deleteCss(item)" class="cssDropdown">Delete {{item}} <b-icon icon="trash"></b-icon></b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                        <!--<b-form-tag input-id="css-tags" v-model="selectedCellCss"></b-form-tag>-->
                                    </div>
                                    </b-col>
                                </b-row>
                                <b-row class="btnRow">
                                    <b-col>
                                        <b-button v-on:click="saveCellConfig" variant="success">Save Row Config</b-button>
                                    </b-col>
                                </b-row>
                            </b-collapse>
                        </div>
                    </div>
                </div>
            </div>
        </b-col>
        <b-col cols="12">
            <pre></pre>
        </b-col>
    </b-row>
    <!--<NewTableModal v-if="toggleNewTableModal"/>-->
</div>
</template>

<script>
//import NewTableModal from '../../modals/newTableModal.vue';

import Swal from 'sweetalert2'
//import ColumnForm from './forms/columnDropdownForm.vue'
//import StylePainter from './forms/stylePainterForm.vue'
import _ from 'lodash'
import { clientMethods, clientComputed, clientViewMethods, clientViewComputed, builderComputed, builderMethods } from '@/state/helpers'
export default {
    components:{
        /*ColumnForm,
        StylePainter,
        NewTableModal,*/
    },
    computed:{
        ...clientComputed,
        ...clientViewComputed,
        ...builderComputed,

        isCellEditable(){
            let value = false;
            if(this.editCell.editable != undefined){
                value = this.editCell.editable;
            }

            return value;
        },

        returnAllDynamicViews(){
            var available_views = [];
            this.view_groups.forEach((group)=>{
                group.views.forEach((view)=>{
                    if(view.type == 'dynamic_text'){
                        available_views.push(view);
                    }
                })
            })
            return available_views;
        },

        returnPainterMode(){
            return this.isPainterModeEnabled;
        },
        
        selectedRowID(){
            return this.editCell.selectedRowID;
        },
        selectedCellCss(){//* Return class names as an array
            var array = [];
            if(this.editCell.selectedRowID != null && this.config.select == 'cell'){
                if(this.editCell.cell.css != ''){
                    array = this.editCell.cell.css.split(' ');
                }
            }
            return array;
        },
        selectedRowCss(){//* Return class names as an array
            var array = [];
            if(this.editCell.selectedRowID != null && this.config.select == 'row'){
                if(this.editCell.row.css != ''){
                    array = this.editCell.row.css.split(' ');
                }
            }
            return array;
        },
        selectedColumnCss(){//* Return class names as an array
            var array = [];
            if(this.editCell.selectedRowID != null && this.config.select == 'column'){
                if(this.editCell.column.css != ''){
                    array = this.editCell.column.css.split(' ');
                }
            }
            return array;
        },
        showTableBuilder(){
            if(this.selected_view_id && this.selected_app.id){
                return true;
            }
            else{
                return false;
            }
        },
        hasConfig(){
            if(this.selectedViewData.config_json == null){
                return false;
            }
            else{
                return true;
            }
        },
        setElementHeight(){
            return `height: ${this.datatableHeight}px;`;
        },
        setElementWidth(){
            return `width: ${this.datatableWidth}px;`;
        }
    },
    methods:{
        ...clientMethods,
        ...clientViewMethods,
        ...builderMethods,

        debugRowHeight(){

        },
        //eslint-disable-next-line
        toggleEdit(value){
            console.log(this.editCell)
            this.editCell['editable'] = true
        },

        copyText() {
            var copy = this.$refs.copyUID;
            //copy.innerHTML = window.location.href;
            copy.select();
            document.execCommand("copy");
            Swal.fire({
                        toast: true,
                        position: 'top',
                        iconColor: 'white',
                        icon: 'success',
                        title:`Copied ${copy.value}`,
                        customClass: {
                            popup: 'colored-toast'
                        },
                        showConfirmButton: false,
                        timer: 4000,
                        timerProgressBar: true
                    })
        },

        navigateBack(){
            if(this.hasMadeChanges){
                //* Current view has unsaved changes
                Swal.fire({
                    title: 'Unsaved Changes',
                    text: 'Save or Discard changes?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Save',
                    cancelButtonText: 'Discard'
                })
                .then((result)=>{
                    console.log(result)
                    if(result.isConfirmed){
                        //*Selected Yes
                        //this.$eventHub.$emit('forceSaveConfiguration');
                        this.saveTableConfig();
                    }
                    else if(result.dismiss == 'cancel'){
                        //*Selected Discard
                        this.setMadeChangesFlag(false);
                        /*this.selectedView = id;
                        this.setSelectedView(id);
                        this.$eventHub.$emit('selectedView');*/
                        //this.selectedViewGroup(id)
                        this.$eventHub.$emit('changeSelectedViewType', 'group_table');
                    }
                    else if(result.dismiss == 'backdrop'){
                        //* Clicked backdrop -- modal closed without executing any action
                        console.log('alert dismissed');
                    }
                })
            }
            else{
                //* No un-saved changes found
                this.$eventHub.$emit('changeSelectedViewType', 'group_table');
            }
        },

        //* TABLE METHODS */
        convertToMultilineHeader(){
            this.config.columns.forEach( col => {
                let header = col.header;
                col.header = {
                    'text': header,
                    'css': 'multiline'
                }
                delete col.fillspace;
            });
            this.config.autowidth = true;
            this.config.fillspace = false;
            this.reloadTable();
        },
        undoMultiline(){
            this.config.columns.forEach( col => {
                let header = col.header.text;
                col.header = header;
            })
            this.reloadTable();
        },
        switchCellDataType(newType){
            if(newType == 'data'){
                this.editCell.cell.type = 'data';
                /*if(this.editCell.cell.data_source != ''){
                    this.$nextTick(()=>{
                        this.$refs.currentCellMappingInput.focus();
                        this.$refs.currentCellMappingInput.select();
                    });
                }*/
            }
            else{
                this.editCell.cell.type = 'static';
                setTimeout(()=>{
                    this.$refs.currentCellInput.focus();
                    this.$refs.currentCellInput.select();
                }, 100);
            }
        },
        setDataType(data_source){
            this.editCell.cell.data_source = data_source;
            if(data_source == 'spreadsheet'){
                this.$nextTick(()=>{
                    this.$refs.currentCellMappingInput.focus();
                    this.$refs.currentCellMappingInput.select();
                });
            }
            else if(data_source == 'spreadify_view'){
                this.$nextTick(()=>{
                    /*this.$refs.currentCellViewInput.focus();
                    this.$refs.currentCellViewInput.select();*/
                });
            }
        },
        displayDataSource(source){
            if(source == 'spreadsheet'){
                return 'SpreadSheet';
            }
            else if(source == 'spreadify_view'){
                return 'Spreadify View';
            }
            else{
                return 'Select Source Type';
            }
        },
        savedFromEnterKey(e){
            console.log(e)
            e.preventDefault();
            if(e.target.id == "cellConfigForm"){
                if(this.editCell.cell.type == 'static'){
                    this.saveCellConfig();    
                }
                else if(this.editCell.cell.type == 'data'){
                    this.saveCellMapping();
                }
            }
            
        },

        returnTabName(name){
            if(name != ''){
                return name;
            }
            else{
                return 'Select Tab';
            }
        },

        returnSheetNameFromId(id){
            if(id == ''){
                return 'Select Sheet';
            }
            else{
                var index = _.findIndex(this.spreadsheets, {id: id});
                if(index != -1){
                    return this.spreadsheets[index].name;
                }
            }
        },
        returnDataSource(){
            if(this.editCell.cell.source_view_id == '' || this.editCell.cell.source_view_id == null){
                return 'Select View';
            }
            else{
                var index = _.findIndex(this.returnAllDynamicViews, {uid:this.editCell.cell.source_view_id})
                if(index != -1){
                    return this.returnAllDynamicViews[index].name;
                }
                else{
                    return 'err';
                }
            }
        },
        returnDataType(){
            if(this.editCell.cell.dataType == '' || this.editCell.cell.dataType == null){
                return 'Select Data Type';
            }
            else{
                return this.editCell.cell.dataType
            }
        },

        upperCase(value){
            return _.toUpper(value);
        },

        //* Paint one or more cells/rows/columns when the painter mode is enabled 
        paintCells(selectedArray, type){
            //eslint-disable-next-line no-undef
            var table = $$('tableBuilder');
            switch (type){
                case 'cell':
                    selectedArray.forEach((item)=>{
                        var index = _.findIndex(this.config.data.data, {id: item.row});
                        if(index != -1){
                            this.config.data.data[index]['$cellCss'][item.column] = this.painterStyles;
                        }
                    })
                    break;
                case 'column':
                    selectedArray.forEach((item)=>{
                        var index = _.findIndex(this.config.columns, {id: item.column});
                        if(index != -1){
                            this.config.columns[index].css = this.painterStyles;
                        }
                    })
                    break;
                case 'row': 
                    selectedArray.forEach((item)=>{
                        var index = _.findIndex(this.config.data.data, {id: item.row});
                        if(index != -1){
                            this.config.data.data[index]['$css'] = this.painterStyles; 
                        }
                    })
            }
            
            table.refresh();
        },

        clearSelectionType(selectedType){
            if(selectedType != 'cell'){
                this.editCell.cell.text = '';
                this.editCell.cell.css = '';
                this.editCell.cell.type = '';
                this.editCell.cell.dataType = '';
                this.editCell.cell.cellID = '';
                this.editCell.cell.sheetName = '';
                this.editCell.cell.sheetID = '';
                this.editCell.cell.tabName = '';
                this.editCell.cell.view_source_id;
                this.editCell.cell.data_source;
            }
            if(selectedType != 'row'){
                this.editCell.row.css = '';
            }
            if(selectedType != 'column'){
                this.editCell.row.id = '';
                this.editCell.row.header = '';
                this.editCell.row.width = '';
                this.editCell.row.css = '';
                this.editCell.row.fillspace = '';
            }
        },

        cellSelectionMode(type){//* Returns style for button group to indicate the active option
            if(!this.hasSelectedCell && type == 'table'){
                return 'font-weight: bold; color: white; background: #556ee6;';
            }
            else if(this.config.select == type){
                return 'font-weight: bold; color: white; background: #556ee6;';
            }
        },

        changeCellSelectionMode(type){//* Set the selection mode for the table ( cell / column / row )
            if(type == 'table'){
                this.hasSelectedCell = false;
                this.config.select = '';
            }
            else{
                this.hasSelectedCell = true;
                this.config.select = type;
                this.reloadTable();
                if(type == 'cell'){
                    this.table_styling.expanded = true;
                    this.table_spans.expanded = true;
                }
                if(type == 'row' || type == 'column'){
                    this.table_styling.expanded = true;
                }
            }
            this.clearSelection();
        },
        applyHeader(){
            let index = _.findIndex(this.config.columns, {id: this.editCell.selectedColumnID})
            if(index != -1){
                this.config.columns[index].header = this.editCell.column.header;
            }
            
            this.reloadTable();
        },

        showNewTableModal(){//*Show modal for creating a new table
            this.toggleNewTableModal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('newTableModal');
                this.$root.$once('bv::modal::hidden',(event)=>{
                    if(event.type == 'hidden' && event.componentId == 'newTableModal'){
                        this.toggleNewTableModal = false;
                        //this.showTable = true;
                    }
                })
            });
        },
        showGenerateTokenModal(){//*Show modal with input fields to create a basic new column
            this.toggleGenerateTokenModal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('tokenModal');
                this.$root.$once('bv::modal::hidden',(event)=>{
                    if(event.type == 'hidden' && event.componentId == 'tokenModal'){
                        this.toggleGenerateTokenModal = false;
                    }
                })
            });
        },
        showColumnModal(){//*Show modal with input fields to create a basic new column
            this.toggleColumnModal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('columnModal');
                this.$root.$once('bv::modal::hidden',(event)=>{
                    if(event.type == 'hidden' && event.componentId == 'columnModal'){
                        this.toggleColumnModal = false;
                    }
                })
            });
        },
        showRowModal(){//*Show modal with input fields to add data for each column that exists
            this.toggleRowModal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('rowModal');

                //send column data once modal has been shown
                this.$root.$once('bv::modal::shown',(event)=>{
                    if(event.type == 'shown' && event.componentId == 'rowModal'){
                        this.$nextTick(()=>{
                            this.$eventHub.$emit('addingNewRow', this.config.columns);
                        })
                    }
                })
                this.$root.$once('bv::modal::hidden',(event)=>{
                    if(event.type == 'hidden' && event.componentId == 'rowModal'){
                        this.toggleRowModal = false;
                    }
                });
            });
        },
        showTableDisplayModal(){
            if(this.hasConfig){
                this.toggleTableDisplayModal = true;
                this.$nextTick(()=>{
                    this.$bvModal.show('tableDisplayModal');
                    this.$root.$once('bv::modal::hidden',(event)=>{
                        if(event.type == 'hidden' && event.componentId == 'tableDisplayModal'){
                            this.toggleTableDisplayModal = false;
                        }
                    });
                });
            }
            else{
                alert("Save a table config first!");
            }
            
        },
        applySettings(){
            if(this.config.fillspace){
                this.config.autowidth = false;
                this.config.columns.forEach((column, idx)=>{
                    if(idx == 0){
                        column['fillspace'] = true;
                    }
                    else{
                        column['fillspace'] = 1;
                    }
                })
            }
            else{
                this.config.autowidth = true;
                this.config.columns.forEach((column)=>{
                    delete column.fillspace;
                })
            }
            this.$refs.tableConfigurationDropdown.hide(true);
            this.reloadTable();
        },

        reloadTable(){//* Called when changes to the table config are made
            //! fix for page jumping position when hiding/displaying the table for refresh.
            if(this.$refs.webixDatatable != undefined){
                this.datatableHeight = (this.$refs.webixDatatable.clientHeight) + 5;
                this.datatableWidth = this.$refs.webixDatatable.scrollWidth;
            }
            if(this.showTable != false){
                this.$refs.tableBuilderContainer.style.overflow = 'hidden';
            }
            
            this.success = true;
            this.showTable = false;
            
            this.$nextTick(()=>{
                this.showTable = true;
                this.$nextTick(()=>{
                    this.datatableHeight = 1;
                    this.datatableWidth = 1;
                    this.$refs.tableBuilderContainer.style.overflow = 'auto';
                })
                setTimeout(()=>{
                    this.success = false;
                    
                }, 50);
            })
        },
        saveColumnVariable(){//* save the column header/key */
            //let index = _.findIndex(this.)
        },
        saveColumn(){//* Save config for column
            var index = _.findIndex(this.config.columns, {id:this.editCell.selectedColumnID});
            if(index != -1){
                this.config.columns[index].id = this.editCell.column.id;
                this.config.columns[index].header = this.editCell.column.header;
                this.config.columns[index].width = this.editCell.column.width;
                this.config.columns[index].css = this.editCell.column.css;
                //this.config.columns[index].fillspace = this.editCell.column.fillspace;
                this.reloadTable();

                //eslint-disable-next-line no-undef
                /*var table = $$("tableBuilder");
                table.refresh();*/
            }
        },
        deleteColumn(){//* Delete the selected column
            var index = _.findIndex(this.config.columns, {id:this.editCell.selectedColumnID});
            if(index != -1){
                this.config.columns.splice(index, 1);
                this.reloadTable();
                this.setMadeChangesFlag(true);
            }
        },
        addNewRow(type){
            this.$refs.addRowDropdown.hide(true);
            if(type == 'empty'){
                this.insertRow();
            }
            else{
                //this.$swal('Hello', 'Hi', 'warning');
                this.showRowModal();
            }
        },
        insertRow(rowData = []){//* Add roww using webix method - set config data to the serialized table, to save newly added row
            var row = {};
            if(rowData == []){
                this.config.columns.forEach((data)=>{
                    //row[data.id] = 'n'+index;
                    row[data.id] = '';
                })
            }
            else{
                rowData.forEach(item => {
                    row[item.colID] = item.rowText;
                })
            }

            row['id'] = 'row'+window.webix.uid();
            
            //eslint-disable-next-line no-undef
            $$('tableBuilder').add(row, this.config.data.data.length);
            //eslint-disable-next-line no-undef
            this.config.data.data = $$('tableBuilder').serialize(true);
            //eslint-disable-next-line no-undef
            $$('tableBuilder').refresh();
        },
        deleteRow(){
            var index = _.findIndex(this.config.data.data, {id:this.editCell.selectedRowID});
            if(index != -1){
                this.config.data.data.splice(index, 1);
                this.reloadTable();
                this.setMadeChangesFlag(true);
            }
        },

        //## WEBIX DATATABLE METHODS ##//
        deleteAction(){
            if(this.editCell.selectedRowID == null && this.editCell.selectedColumnID == null){
                Swal.fire('Nothing selected.');
            }
            else{
                //eslint-disable-next-line
                var table = $$('tableBuilder');
                //var spans = table.getSpan();

                switch(this.config.select){
                    case 'cell':
                        Swal.fire({
                            title: 'Remove Cell Text and Formatting?',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No'
                        })
                        .then((result)=>{
                            if(result.isConfirmed){
                                var rowIndex = _.findIndex(this.config.data.data, {id: this.editCell.selectedRowID});
                                if(rowIndex != -1){
                                    this.config.data.data[rowIndex][this.editCell.selectedColumnID] = '';
                                    this.clearSelection();
                                }
                            }
                        })
                        break;
                    case 'row':
                        Swal.fire({
                            title: 'Delete Row?',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No'
                        })
                        .then((result)=>{
                            if(result.isConfirmed){
                                var isInSpan = false;
                                _.forEach(this.config.data.spans, (data => {//? [0]:row [1]:col [2]:width [3]:height
                                    var startIndex = _.findIndex(this.config.data.data, ( item => {
                                        return item.id == data[0]
                                    }))
                                    if(startIndex != -1){
                                        var endIndex = Number(startIndex) + Number(data[3])-1;
                                        for(var i = startIndex; i <= endIndex; i++){
                                            if(this.editCell.selectedRowID == this.config.data.data[i].id){
                                                isInSpan = true;
                                                break;
                                            }
                                        }
                                    }
                                    //exit loop if 
                                    if(isInSpan){
                                        return false;
                                    }
                                }));
                                if(isInSpan){
                                    Swal.fire('Error!','This row belongs to a span.');
                                }
                                else{
                                    //* Row not found in any spans, proceed to delete
                                    this.deleteRow();
                                    this.clearSelection();
                                    Swal.fire({
                                        toast: true,
                                        position: 'top',
                                        iconColor: 'white',
                                        icon: 'success',
                                        title:'Row Deleted!',
                                        customClass: {
                                            popup: 'success-toast'
                                        },
                                        showConfirmButton: false,
                                        timer: 2000,
                                        timerProgressBar: true
                                    });
                                }
                            }
                        })
                        break;
                    case 'column':
                        Swal.fire({
                            title: 'Delete Column?',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No'
                        })
                        .then((result)=>{
                            if(result.isConfirmed){
                                var isInSpan = false;
                                _.forEach(this.config.data.spans, (data => {//? [0]:row [1]:col [2]:width [3]:height
                                    var startIndex = _.findIndex(this.config.columns, ( item => {
                                        return item.id == data[1]
                                    }))
                                    if(startIndex != -1){
                                        var endIndex = Number(startIndex) + Number(data[2])-1;
                                        for(var i = startIndex; i <= endIndex; i++){
                                            if(this.editCell.selectedColumnID == this.config.columns[i].id){
                                                isInSpan = true;
                                                break;
                                            }
                                        }
                                    }
                                    //exit loop if 
                                    if(isInSpan){
                                        return false;
                                    }
                                }));
                                if(isInSpan){
                                    Swal.fire('Error!','This column belongs to a span.');
                                }
                                else{
                                    //* Column not found in any spans, proceed to delete
                                    this.deleteColumn();
                                    this.clearSelection();
                                    Swal.fire({
                                        toast: true,
                                        position: 'top',
                                        iconColor: 'white',
                                        icon: 'success',
                                        title:'Column Deleted!',
                                        customClass: {
                                            popup: 'success-toast'
                                        },
                                        showConfirmButton: false,
                                        timer: 2000,
                                        timerProgressBar: true
                                    })
                                }
                            }
                        })
                        break;
                }
            }
        },

        clearSpanSelection(){
            
        },
        highlightSpan(startRow, startColumn, width, height){
            //console.log(startRow, startColumn, width, height);
            //eslint-disable-next-line no-undef
            var table = $$('tableBuilder');
            /*table.define('areaselect', true);
            table.refresh();*/
            this.toggleCreateSpanElement(true)
            .then(()=>{
                var columnIndex = _.findIndex(this.config.columns, {id: startColumn})
                var rowIndex = _.findIndex(this.config.data.data, {id: startRow})

                var startID = {
                    row: startRow,
                    column: startColumn,
                }
                var endID = {
                    row: this.config.data.data[rowIndex + (height - 1)].id,
                    column: this.config.columns[columnIndex + (width - 1)].id
                }
                //console.log("Params",this.config.columns[columnIndex + (width - 1)], this.config.data.data[rowIndex + (height - 1)])
                table.addSelectArea(startID, endID, false, '', 'background: red;', false);
                //alert(this.config.columns[])
                var spanIndex = _.findIndex(this.config.data.spans, (span) => {
                    return (span[0] == startRow && span[1] == startColumn)
                })
                this.editCell.span.row = startRow;
                this.editCell.span.column = startColumn;
                this.editCell.span.width = width;
                this.editCell.span.height = height;
                this.editCell.span.value = this.config.data.spans[spanIndex][4];
                this.editCell.span.css = this.config.data.spans[spanIndex][5];
            })
        },
        spanChangeWidth(){
            var startIndex = _.findIndex(this.config.columns, {id: this.editCell.span.column})
            
            if(startIndex != -1){
                var colLength = this.config.columns.length;
                var currentColIndex = Number(this.editCell.span.width) + Number(startIndex);

                if((currentColIndex + 1) > colLength){
                    alert('Maximum width.');
                }
                else{
                    this.editCell.span.width++;
                    
                }
            }
        },
        toggleCreateSpanElement(forceEnable){
            return new Promise(resolve =>{
                if(forceEnable){
                    //force true
                    this.showCreateSpan = true;
                }
                else{
                    //disable span creation and area-selection
                    this.showCreateSpan = !this.showCreateSpan;
                }
                //eslint-disable-next-line no-undef
                var table = $$('tableBuilder');

                if(this.showCreateSpan == false){
                    table.removeSelectArea();
                    this.editCell.span.width = 0;
                    this.editCell.span.height = 0;
                    table.define('areaselect', false);
                    table.refresh();
                    table.define('select', 'cell');
                    //this.reloadTable();
                }
                else{
                    table.define('areaselect', true);
                }
                
                this.$nextTick(()=>{
                    table.refresh();
                    resolve();
                });
            })
            
        },
        saveSpanData(){//*Save span to datatable and hide span creation div
            //eslint-disable-next-line no-undef
            var table = $$('tableBuilder');
            //**************************************************************************** */
            //*Clear the text of any cells that become part of the span
            var rowStart = _.findIndex(this.config.data.data, {id:this.editCell.span.row});
            var rowEnd = rowStart + Number(this.editCell.span.height)-1;
            var colStart = _.findIndex(this.config.columns, {id:this.editCell.span.column});
            var colEnd = colStart + Number(this.editCell.span.width)-1;
            for(var r = rowStart; r <= rowEnd; r++){
                for(var c = colStart; c <= colEnd; c++){
                    this.config.data.data[r][this.config.columns[c].id] = ''
                }
            }
            //**************************************************************************** */
            table.addSpan(this.editCell.span.row, this.editCell.span.column, Number(this.editCell.span.width), Number(this.editCell.span.height), this.editCell.span.value, this.editCell.span.css);
            this.processSpanObject();
            this.$nextTick(()=>{
                this.toggleCreateSpanElement(false);
                this.reloadTable();
            })
        },
        spanChangeHeight(){
            var startIndex = _.findIndex(this.config.data.data, {id: this.editCell.selectedRowID})
            
            var rowLength = this.config.data.data.length;

            var currentRowIndex = this.editCell.span.height + startIndex;
            if((currentRowIndex + 1) > rowLength){
                alert('Maximum Height');
            }
            else{
                this.editCell.span.height++;
            }
        },
        createSpanRow(){
            //eslint-disable-next-line no-undef
            var table = $$('tableBuilder');
            table.addSpan( //variables for span array 
                this.editCell.selectedRowID,
                this.editCell.span.column,
                Number(this.editCell.span.width),
                Number(this.editCell.span.height),
                this.editCell.span.value,
                this.editCell.span.css
            );
            this.processSpanObject();
            this.$nextTick(()=>{
                this.clearSelection();
            }) 

            //*create new span array
            //var span = [ this.editCell.selectedRowID, this.editCell.span.column, Number(this.editCell.span.width), Number(this.editCell.span.height), this.editCell.span.value, this.editCell.span.css]
            //this.config.data.spans.push(span);
            /*
            //* Check if span exists - save over existing span or create new entry
            var index = _.findIndex(this.config.data.spans, (data => {
                return (data[0] == this.editCell.selectedRowID && data[1] == this.editCell.span.column);
            }))
            if(index != -1){
                this.config.data.spans[index] = span;
            }
            else{
                this.config.data.spans.push(span);
            }*/
            //this.reloadTable();
        },
        processSpanObject(){
            //eslint-disable-next-line no-undef
            var table = $$('tableBuilder');
            var spans = [] // Populate array from getSpan() and save to config
            var tableSpans = table.getSpan();
            console.log('SPAN', tableSpans);

            //*Process the span object from getSpan() and save to config
            for(var row in tableSpans){
                for(var col in tableSpans[row]){
                    if(col == '$selected' || col == '$time'){
                        // todo -- remove selected and time props
                    }
                    else{
                        //this.config.data.data[row][col] = '';
                        var span = [];
                        span.push(row);
                        span.push(col);
                        tableSpans[row][col].forEach(i=>{
                            span.push(i);
                        });
                        spans.push(span);
                    }
                }
            }
            this.config.data.spans = spans;
            table.refresh();
        },
        deleteSpan(row, col){
            var index = _.findIndex(this.config.data.spans, (span)=>{
                return (span[0] == row && span[1] == col)
            })
            if(index != -1){
                //eslint-disable-next-line no-undef
                var table = $$('tableBuilder');
                table.removeSpan(row, col);
                this.processSpanObject();
                this.toggleCreateSpanElement(false);
                this.setMadeChangesFlag(true);
                this.$nextTick(()=>{
                    //*using hard reload because after using $$('tableBuilder').refresh the table still returns the deleted span id
                    this.reloadTable();
                    this.clearSelection();
                })
            }
            else{
                alert('Error! Cant delete span.');
            }
            
        },
        editSpan(row, col){
            var index = _.findIndex(this.config.data.spans, (data =>{
                return (data[0] == row && data[1] == col);
            }))
            if(index != -1){
                var span = this.config.data.spans[index]
                this.editCell.selectedRowID = span[0];
                this.editCell.selectedColID = span[1];
                this.editCell.span.column = span[1];
                this.editCell.span.width = span[2];
                this.editCell.span.height = span[3];
                this.editCell.span.value = span[4];
                this.editCell.span.css = span[5];
            }
            //this.reloadTable();
        },
        addCss(name){//* Add css string to the editCell object (used to display added css items before saving)
            if(this.config.select == 'cell'){
                if(this.selectedCellCss.length == 0){
                    this.editCell.cell.css = name;
                }
                else{
                    this.editCell.cell.css += ' '+name;
                }
            }
            else if(this.config.select == 'row'){
                if(this.selectedRowCss.length == 0){
                    this.editCell.row.css = name;
                }
                else{
                    this.editCell.row.css += ' '+name;
                }
            }
            else if(this.config.select == 'column'){
                if(this.selectedColumnCss.length == 0){
                    this.editCell.column.css = name;
                }
                else{
                    this.editCell.column.css += ' '+name;
                }
            }
        },
        deleteTheme(class_name){
            var cssArray = this.splitCss(this.selectedViewData.config_json.css)
            var index = _.findIndex(cssArray, (item)=>{
                return class_name == item;
            })
            if(index != -1){
                cssArray.splice(index, 1);
            }
        },
        deleteCss(name){
            var cssArray = [];
            if(this.config.select == 'cell'){
                if(this.editCell.cell.css.includes(name)){
                    cssArray = this.editCell.cell.css.split(' ');
                    var index = _.findIndex(cssArray, (data)=>{
                        return data == name;
                    })
                    if(index != -1){
                        cssArray.splice(index, 1);
                        if(cssArray.length == 0){
                            this.editCell.cell.css = "";
                        }
                        else if(cssArray.length == 1){
                            this.editCell.cell.css = cssArray[0];
                        }
                        else{
                            cssArray.forEach((data, idx) =>{
                                if(idx == 0){
                                    this.editCell.cell.css = data;
                                }
                                else{
                                    this.editCell.cell.css += ' '+data;
                                }
                            })
                        }
                    }
                }
            }
            else if(this.config.select == 'row'){
                if(this.editCell.row.css.includes(name)){
                    cssArray = this.editCell.row.css.split(' ');
                    var r_index = _.findIndex(cssArray, (data)=>{
                        return data == name;
                    })
                    if(r_index != -1){
                        cssArray.splice(r_index, 1);
                        if(cssArray.length == 0){
                            this.editCell.row.css = "";
                        }
                        else if(cssArray.length == 1){
                            this.editCell.row.css = cssArray[0];
                        }
                        else{
                            cssArray.forEach((data, idx) =>{
                                if(idx == 0){
                                    this.editCell.row.css = data;
                                }
                                else{
                                    this.editCell.row.css += ' '+data;
                                }
                            })
                        }
                    }
                }
            }
            else if(this.config.select == 'column'){
                if(this.editCell.column.css.includes(name)){
                    cssArray = this.editCell.column.css.split(' ');
                    var c_index = _.findIndex(cssArray, (data)=>{
                        return data == name;
                    })
                    if(c_index != -1){
                        cssArray.splice(c_index, 1);
                        if(cssArray.length == 0){
                            this.editCell.column.css = "";
                        }
                        else if(cssArray.length == 1){
                            this.editCell.column.css = cssArray[0];
                        }
                        else{
                            cssArray.forEach((data, idx) =>{
                                if(idx == 0){
                                    this.editCell.column.css = data;
                                }
                                else{
                                    this.editCell.column.css += ' '+data;
                                }
                            })
                        }
                    }
                }
            }
        },

        saveTableConfig(){
            if(this.selected_view_id != null){
                this.hasSelectedCell = false;
                this.config.select = ''
                this.isSavingConfig = true;
                if(this.cellMAppings.length > 0){
                    //*Remove display values for cells that are mapped
                    _.forEach(this.cellMAppings, (cell => {
                        var rowIndex = _.findIndex(this.config.data.data, {id: cell.row_id});
                        if(rowIndex != -1){
                            this.config.data.data[rowIndex][cell.col_id] = '';
                        }
                    }))
                }

                var conf = _.cloneDeep(this.config);

                //*remove properties that aren't needed
                delete conf.on;
                delete conf.multiselect;
                conf.select = '';
                this.hasSelectedCell = false;

                var params = {
                    render_view_id: this.selected_view_id,
                    config_json: JSON.stringify(conf),
                }
                this.saveViewConfig(params)
                .then(()=>{
                    Swal.fire({ toast: true, position: 'top', iconColor: 'white', icon: 'success', title:'Saved Table', customClass: { popup: 'colored-toast' }, showConfirmButton: false, timer: 2000, timerProgressBar: true})
                    this.isSavingConfig = false;
                    this.getViewGroups(this.selected_app.id)
                    .then(()=>{
                        this.$eventHub.$emit('displaySelectedView', this.selectedViewData.id)
                        this.setSelectedView(this.selected_view_id);
                    })
                    .catch(()=>{
                        alert(this.selected_view_id)
                    })
                    this.setMadeChangesFlag(false);
                })
                .catch(()=>{
                    alert('Failed');
                    this.isSavingConfig = false;
                })
            }
        },
        deleteCellMapping(id){
            this.deleteViewMapping(id)
            .then(()=>{
                //*Refresh the selected view data with updated mappings
                this.getViewGroups(this.selected_app.id)
                .then(()=>{
                    this.$eventHub.$emit('displaySelectedView', this.selectedViewData.id)
                    this.setSelectedView(this.selected_view_id)
                    .then(()=>{
                        this.processCellMapping();
                    })
                })
                this.setMadeChangesFlag(true);
            })
            .catch(()=>{ alert("Error deleting mapping"); })
        },
        insertColumn(){
            let index = this.config.columns.length + 1;
            this.config.columns.push(
                {
                    id:"col" + new Date().valueOf(),
                    header:"col-" + index,
                    css:""
                });
            
            this.$nextTick(()=>{
                this.reloadTable();
            })
        },
        saveJSONMapping(){
            if(this.selectedViewData.config_json == null){

                alert('Please save your table first!');
            }
            else{
                var params = {
                    client_app_sheet_id: this.editCell.cell.sheetID,
                    render_view_id: this.selected_view_id,
                    tab_name: '',//this.editCell.cell.tabName,
                    //sheet_co_ords: this.editCell.cell.cellID,
                    json_key: this.editCell.column.key,
                    mapped_cell_id: this.editCell.selectedColumnID,
                    data_type: '',//this.editCell.cell.dataType,
                    data_source: 'json_key',//this.editCell.cell.data_source,
                    source_view_uid: this.selected_view_id,
                }
                //console.log('DEBUG DATA', params)
                this.saveViewMapping(params)
                .then(()=>{
                    //*Refresh the selected view data with updated mappings
                    this.getViewGroups(this.selected_app.id)
                    .then(()=>{
                        this.$eventHub.$emit('displaySelectedView', this.selectedViewData.id)
                        this.setSelectedView(this.selected_view_id)
                        .then(()=>{

                            //this.processCellMapping();
                            //alert('SUCCESS')
                        })
                    })
                    this.setMadeChangesFlag(true);
                    
                    Swal.fire({
                        toast: true,
                        position: 'top',
                        iconColor: 'white',
                        icon: 'success',
                        title:'Saved Mapping',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true
                    })
                })
                .catch((e)=>{ 
                    console.log(e);
                    Swal.fire({
                        toast: true,
                        position: 'top',
                        iconColor: 'red',
                        icon: 'error',
                        title:'Error saving mapping.',
                        customClass: {
                            popup: 'error-toast'
                        },
                        showConfirmButton: false,
                        timer: 4000,
                        timerProgressBar: true
                    })
                })
            }
        },
        saveCellMapping(){
            if(this.selectedViewData.config_json == null){

                alert('Please save your table first!');
            }
            else if(this.editCell.cell.type == 'data'){
                /*this.cellMAppings.push({
                    row: this.editCell.selectedRowID,
                    column: this.editCell.selectedColumnID,
                    mapped_id: this.editCell.selectedRowID +'|'+this.editCell.selectedColumnID,
                    cell_id: this.editCell.cell.cellID,
                    client_app_spreadsheet_id: this.editCell.cell.sheetName,
                    sheet_name: 'tab1',
                })*/

                var params = {
                    client_app_sheet_id: this.editCell.cell.sheetID,
                    render_view_id: this.selected_view_id,
                    tab_name: this.editCell.cell.tabName,
                    sheet_co_ords: this.editCell.cell.cellID,
                    mapped_cell_id: this.editCell.selectedRowID +'|'+this.editCell.selectedColumnID,
                    data_type: this.editCell.cell.dataType,
                    data_source: this.editCell.cell.data_source,
                    source_view_uid: this.editCell.cell.source_view_id,
                }
                console.log(params);
                
                this.saveViewMapping(params)
                .then(()=>{
                    //*Refresh the selected view data with updated mappings
                    this.getViewGroups(this.selected_app.id)
                    .then(()=>{
                        this.$eventHub.$emit('displaySelectedView', this.selectedViewData.id)
                        this.setSelectedView(this.selected_view_id)
                        .then(()=>{

                            this.processCellMapping();
                        })
                    })
                    this.setMadeChangesFlag(true);
                    
                    Swal.fire({
                        toast: true,
                        position: 'top',
                        iconColor: 'white',
                        icon: 'success',
                        title:'Saved Mapping',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true
                    })
                })
                .catch((e)=>{ 
                    console.log(e);
                    Swal.fire({
                        toast: true,
                        position: 'top',
                        iconColor: 'red',
                        icon: 'error',
                        title:'Error saving mapping.',
                        customClass: {
                            popup: 'error-toast'
                        },
                        showConfirmButton: false,
                        timer: 4000,
                        timerProgressBar: true
                    })
                })
            }
        },
        saveCellConfig(){ //* Save the configuration of a cell / column / row
            //eslint-disable-next-line no-undef
            var table = $$("tableBuilder");

            if(this.config.select == 'cell'){
                var index = _.findIndex(this.config.data.data, (data)=>{
                    return data.id == this.editCell.selectedRowID;
                })
                if(index != -1){
                    //Check if current cell belongs to span and then update span
                    //Else save as cell
                    var spanIndex = _.findIndex(this.config.data.spans, (data => {
                        return ( data[0] == this.editCell.selectedRowID && data[1] == this.editCell.selectedColumnID )
                    }))
                    if(spanIndex != -1){
                        var spanData = _.cloneDeep(this.config.data.spans[spanIndex]);
                        spanData[4] = this.editCell.cell.text;
                        //spanData[5] = this.editCell.cell.css;
                        if(this.selectedCellCss.length == 0){
                            //* css index in array
                            spanData[5] = '';
                        }
                        else{
                            this.selectedCellCss.forEach((item, idx)=>{
                                if(idx == 0){
                                    spanData[5] = item;
                                }
                                else{
                                    spanData[5] += ' '+item;
                                }
                            });
                        }
                        
                        //this.config.data.data[index]['$cellCss'][this.editCell.selectedColumnID] = spanData[5];
                        table.addSpan(spanData[0],spanData[1],spanData[2],spanData[3],spanData[4],spanData[5]);
                        this.processSpanObject();
                    }
                    else{
                        this.config.data.data[index][this.editCell.selectedColumnID] = this.editCell.cell.text;
                        if(this.selectedCellCss.length == 0){
                            //* Save empty css string
                            this.config.data.data[index]['$cellCss'][this.editCell.selectedColumnID] = "";
                        }
                        else{
                            //* Process added css array for cell
                            this.selectedCellCss.forEach((item, idx)=>{
                                if(idx == 0){
                                    this.config.data.data[index]['$cellCss'][this.editCell.selectedColumnID] = item;
                                }
                                else{
                                    this.config.data.data[index]['$cellCss'][this.editCell.selectedColumnID] += ' '+item;
                                }
                            });
                        }
                        
                        //* Check if editable flag is active
                        if(this.editCell.editable){
                            if(this.config.editableCells == undefined){
                                //* Create empty array for editable cells list
                                this.config.editableCells = [];
                            }

                            //* Create id from row_id and col_id
                            let rowColumn = this.editCell.selectedRowID + '|' + this.editCell.selectedColumnID;

                            //* Check if cell exists in array
                            let cell_index = _.findIndex(this.config.editableCells, { id : rowColumn });
                            if(cell_index != -1){
                                this.config.editableCells[cell_index] = {
                                    id: rowColumn,
                                    key: this.editCell.cellVariable,
                                    value: ''
                                }
                            }
                            else{
                                this.config.editableCells.push({
                                    id: rowColumn,
                                    key: this.editCell.cellVariable,
                                    value: '',
                                })
                            }

                            
                            this.config.editableCells[rowColumn] = true;
                        }
                    }
                    
                    this.setMadeChangesFlag(true);
                }
            }
            else if(this.config.select == 'row'){
                var rowIndex = _.findIndex(this.config.data.data, (data)=>{
                    return data.id == this.editCell.selectedRowID;
                })
                if(rowIndex != -1){
                    this.config.data.data[rowIndex]['$css'] = this.editCell.row.css;
                    this.config.data.data[rowIndex]['$height'] = this.editCell.row.height;

                    this.setMadeChangesFlag(true);
                }
            }
            table.refresh();
            //this.reloadTable();
        },

        clearSelection(){
            //eslint-disable-next-line no-undef
            var table = $$("tableBuilder");
            if(table.clearSelection === 'function'){
                table.clearSelection();
            }
            
            this.editCell.selectedColumnID = null;
            this.editCell.selectedRowID = null;
        },
        processCellMapping(){
            //todo -- maybe add toggle to display data cell mapped id?
            var mapping = _.cloneDeep(this.selectedViewData.mapping);
            mapping.forEach(item => {
                var [row, col] = item.mapped_cell_id.split('|');
                item['row_id'] = row;
                item['col_id'] = col;
                var rowIndex = _.findIndex(this.config.data.data, {id : row});
                if(rowIndex != -1){
                    var printMapping = '';
                    if(item.data_source == 'spreadsheet'){
                        printMapping = item.sheet_coordinates;

                        if(item.data_type != null){
                            if(item.data_type == 'Percentage'){
                                printMapping += ' (%)';
                            }
                            if(item.data_type == 'Currency'){
                                printMapping += ' ($)';
                            }
                            if(item.data_type == 'Decimal'){
                                printMapping += ' (D)';
                            }
                        }
                        else{
                            printMapping += ' (**)';
                        }
                    }
                    else if(item.data_source == 'spreadify_view'){
                        printMapping = 'uid'+item.source_view_uid;
                    }
                    this.config.data.data[rowIndex][col] = printMapping;
                    
                }
            })
            this.cellMAppings = mapping;
            if(this.$refs.tableBuilderContainer != undefined){
                console.log(this.$refs.tableBuilderContainer.style.overflow)
                this.$refs.tableBuilderContainer.style.overflow = 'hidden';
                console.log(this.$refs.tableBuilderContainer.style.overflow)
            }
            //eslint-disable-next-line no-undef
            var table = $$("tableBuilder");
            if(table != undefined){
                table.refresh();
            }
            else{
                this.reloadTable();
            }
            this.$nextTick(()=>{
                this.$refs.tableBuilderContainer.style.overflow = 'auto';
            })
        },

        updateView(){
            //used to update the name and description of the view
            var params = {
                id: this.selectedViewData.id,
                client_app_id: this.selectedViewData.client_app_id,
                name: this.editTable.name,
                description: this.editTable.description,
                type: this.selectedViewData.type,
            }
            this.saveView(params);

        },
        serializeTableToConfig(){
            //eslint-disable-next-line no-undef
            var table = $$("tableBuilder");
            this.config.data.data = table.serialize(true);
        },
        splitCss(css){
            var styles = css.split(' ');
            return styles;
        },
        updateThemeChange(){
            this.saveTableConfig();
            this.showTable = false;
            this.$nextTick(()=>{
                this.showTable = true;
            })
        },




        //* Events
        selectViewEvent(){
            this.hasSelectedCell = false;
            this.editCell.selectedRowID = null,
            this.editCell.selectedColumnID = null,
            this.showTable = false;
            var configuration = _.cloneDeep(this.selectedViewData);
            if(configuration.config_json == null){
                //this.showNewTableModal();
                if(this.config.columns.length == 0){
                    this.createNewTableEvent({cols: 1, rows: 10, emptyCells: true})
                }
            }
            else{
                this.editTable.name = configuration.name;
                this.editTable.description = configuration.description;
                this.config = configuration.config_json;
                this.config['on'] = this.webixOnClickMethods;
                this.config['multiselect'] = true;
                this.config['areaselect'] = false;
                this.config['select'] = '';
                this.processCellMapping();
                setTimeout(()=>{
                    this.showTable = true;
                }, 200)
            }
        },
        selectAppEvent(id){
            if(id != null){
                this.config = _.cloneDeep(this.default_config);
            }
        },

        savedNewRowEvent(row){
            this.insertRow(row);
        },

        savedColumnEvent(column){
            var col = {id: 'col'+window.webix.uid(), header: column.header}
            this.config.columns.push(col);
            this.addColumn = { id: '', header: ''};
            this.reloadTable();
        },

        createCopyFromTableEvent(view){
            this.showTable = false;
            if(view.config_json != null){
                this.cellMAppings = [];
                var config_copy = JSON.parse(view.config_json);
                this.config = config_copy;
                this.config['on'] = this.webixOnClickMethods;
                this.config['multiselect'] = true;
                this.$nextTick(()=>{
                    this.showTable = true;
                    this.saveTableConfig()
                })
                
            }
        },

        createNewTableEvent(table_data){
            this.config = _.cloneDeep(this.default_config);

            //*Create basic column objects for the given column count
            for( var x = 1; x <= table_data.cols; x++){
                var newID = 'col'+window.webix.uid();
                if(x == 1){
                    this.config.columns.push({id: newID , header:'col-'+x, css:'',})
                }
                else{
                    this.config.columns.push({id: newID , header:'col-'+x, css:'',})
                }
            }

            //*Create basic rows with optional dummy data
            for( var y = 1; y <= table_data.rows; y++){
                var rowID = 'row'+window.webix.uid();
                var row = {id: rowID, $cellCss:{}} //$height:40
                for(var col of this.config.columns){
                    if(table_data.emptyCells){
                        row[col.id] = '';
                    }
                    else{
                        row[col.id] = y+'-'+col.id;
                    }
                }
                this.config.data.data.push(row);

                //*add event listeners to config
                this.config['on'] = this.webixOnClickMethods;
                this.config['multiselect'] = true;
                this.config['hover'] = 'webixHover'
            }
            this.showTable = true;
            this.saveTableConfig();
        },

        forceSaveEvent(){
            this.saveTableConfig();
        },

        lockSheetAndTabValues(){
            if(this.lockedSheetAndTab == true){
                this.lockedSheetAndTab = false;
                this.lockedSheet = '';
                this.lockedTab = '';
            }
            else{
                this.lockedSheetAndTab = true;
                this.lockedSheet = this.editCell.cell.sheetID;
                this.lockedTab = this.editCell.cell.tabName;
            }
        },
        toggleCollapseMenu(item){
            switch(item){
                case 'span':{
                    this.table_spans.expanded = !this.table_spans.expanded
                    if(this.table_spans.expanded){
                        this.table_properties.expanded = false;
                        this.table_styling.expanded = false;
                    }
                    break;
                }
                case 'properties':{
                    this.table_properties.expanded = !this.table_properties.expanded
                    if(this.table_properties.expanded){
                        this.table_spans.expanded = false;
                        this.table_styling.expanded = false;
                    }
                    break;
                }
                case 'styling':{
                    this.table_styling.expanded = !this.table_styling.expanded
                    if(this.table_styling.expanded){
                        this.table_spans.expanded = false;
                        this.table_properties.expanded = false;
                    }
                    break;
                }
            }
        },

        /*switchCellDataType(newType){
            if(newType == 'data'){
                this.editCell.cell.type = 'data';
                setTimeout(()=>{
                    this.$refs.currentCellMappingInput.focus();
                    this.$refs.currentCellMappingInput.select();
                }, 100);
            }
            else{
                this.editCell.cell.type = 'static';
                setTimeout(()=>{
                    this.$refs.currentCellInput.focus();
                    this.$refs.currentCellInput.select();
                }, 100);
            }
        },*/

        /*clearSelectionType(selectedType){
            if(selectedType != 'cell'){
                this.editCell.cell.text = '';
                this.editCell.cell.css = '';
                this.editCell.cell.type = '';
                this.editCell.cell.dataType = '';
                this.editCell.cell.cellID = '';
                this.editCell.cell.sheetName = '';
                this.editCell.cell.sheetID = '';
                this.editCell.cell.tabName = '';
            }
            if(selectedType != 'row'){
                this.editCell.row.css = '';
            }
            if(selectedType != 'column'){
                this.editCell.row.id = '';
                this.editCell.row.header = '';
                this.editCell.row.width = '';
                this.editCell.row.css = '';
                this.editCell.row.fillspace = '';
            }
        },*/
    },
    data:()=>({
        refreshValue: true,

        table_properties:{
            expanded: true,
        },
        table_spans:{
            expanded: false,
        },
        table_styling:{
            expanded: false,
        },

        table_data:{
            name: 'Test Table',
            description:'Describe this table using as many words as possible.',
            rows:1,
            cols:2,
            theme:'',
        },
        showToolsMenu: false,

        hasSelectedCell: false,
        //* TABLE BUILDER START *//

        freezeDataType:'',
            //* sample sheet names *//
            available_tabs: [
                'Scorecard', 'OneScore',
                'BaseData','Yes','MCTable','TargetsV','SpendTables',
                'SpendTables(YES)','SpendTables(NonY)','BCDV',
                'SpendV','TMPSV','OneTarV','LevAmtV'
            ],

            //*mimic webix datatable width and height when hard refreshing
            datatableHeight: 1,
            datatableWidth: 1,

            //* Modal toggles
            toggleNewTableModal: false,
            toggleColumnModal:false,
            toggleRowModal: false,
            toggleTableDisplayModal: false,
            toggleGenerateTokenModal: false,

            showCreateSpan: false,
            isSavingConfig: false,

            lockedSheetAndTab: false,
            lockedSheet: '',
            lockedTab: '',
            toggleEditDataCell: false,

            addColumn:{
                id:'',
                header:'',
            },
            success:false,
            showTable: false,

            editTable:{
                name:'',
                description:'',
            },
            editCell:{//* Object for editing sell/row/column data
                selectedRowID: null,
                selectedColumnID: null,
                multiselect: false,
                editable: false,
                cellCss:'',
                cell:{
                    text:'',
                    css:'',
                    type:'',
                    dataType:'',
                    cellID: '',
                    sheetName: '',
                    sheetID:'',
                    tabName:'',
                    source_view_id:'',
                    data_source:'',
                    key: '',
                },
                span:{
                    column:'',
                    row:'',
                    width:0,
                    height:0,
                    value:'',
                    css:'',
                },
                column:{
                    id: '',
                    header:'',
                    width:'',
                    css:'',
                    fillspace:'',
                },
                row:{
                    css:'',
                }
            },
            cellMAppings:[],//? {}
            default_config:{
                id: 'tableBuilder',
                view: 'datatable',
                css: 'webix_data_border',
                header:true,
                spans:true,
                select:"cell",
                autoheight:true,
                autowidth: true,
                scrollX:false,
                fixedRowHeight:false,
                multiselect: true,
                columns:[],
                data:{
                    data:[],
                    spans:[]
                },
                
            },
            config:{
                id: 'tableBuilder',
                view: 'datatable',
                css: 'webix_data_border',
                header:true,
                spans:true,
                select:"cell",
                autoheight:true,
                autowidth: true,
                scrollX:false,
                fixedRowHeight:false,
                multiselect: true,
                columns:[],
                data:{
                    data:[],
                    spans:[]
                },
                
            },
            cellDataTypes:['Decimal','Percentage','Currency',],

            webixOnClickMethods:{
                onSelectChange(){
                    //eslint-disable-next-line no-undef
                    var text = $$('tableBuilder').getSelectedId(true);
                    if(text == ''){//* Nothing selected
                        this.editCell.selectedRowID = null;
                        this.editCell.selectedColumnID = null;
                    }
                },
                //eslint-disable-next-line no-unused-vars
                onItemDblClick(id, e, node){
                    var el = this.$scope.$parent;
                    el.$nextTick(()=>{
                        el.editCell.cell.type = 'data';
                        el.$nextTick(()=>{
                            if(el.lockedSheetAndTab == true){
                                el.editCell.cell.sheetID = el.lockedSheet;
                                el.editCell.cell.tabName = el.lockedTab;
                            }
                            el.$refs.currentCellMappingInput.focus();
                            el.$refs.currentCellMappingInput.select();
                        })
                        
                    })
                },
                //eslint-disable-next-line no-unused-vars
                onItemClick(id, e, node){
                    var el = this.$scope.$parent;
                    
                    var type = el.config.select;
                    if(type == ''){
                        console.log('Table mode active')
                    }
                    else{
                        //eslint-disable-next-line no-undef
                        var table = $$("tableBuilder");
                        //console.log(table.getSelectedId(false, true))
                        var selectionArray = table.getSelectedId(true);
                        el.hasSelectedCell = true;
                        //* Check if multi select support should be enabled for the config editor
                        if(selectionArray.length > 1){
                            el.editCell.multiselect = true;
                        }
                        else{
                            el.editCell.multiselect = false;
                        }

                        //* If painter mode is enabled then paint cells, 
                        //* If not enabled, resume normal cell selection actions
                        if(el.isPainterModeEnabled){
                            el.paintCells(selectionArray, type);
                        }
                        else{
                            //* Populate the editCell object based on selection type
                            if(type == 'cell'){
                                //this.edit(id)
                                el.editCell.selectedRowID = id.row;
                                el.editCell.selectedColumnID = id.column;
                                el.editCell.span.column = id.column;
                                var row = table.getItem(id.row);
                                el.editCell.cell.text = row[id.column]

                                //* Check if rowID|columnID exists in editableCells array
                                if(el.config.editableCells != undefined){
                                    
                                    let rowColumn = id.row + '|' + id.column;
                                    let cell_index = _.findIndex(el.config.editableCells, { id:rowColumn });
                                    if(cell_index != -1){
                                        
                                        el.editCell['editable'] = true;
                                        el.editCell['cellVariable'] = el.config.editableCells[cell_index].key;
                                    }
                                    else{
                                        
                                        el.editCell['editable'] = false;
                                        el.editCell['cellVariable'] = ''
                                    }
                                }
                                else{
                                    
                                    el.editCell['editable'] = false;
                                    el.editCell['cellVariable'] = ''
                                }

                                

                                el.clearSelectionType('cell');

                                //*Check if the selected cell is saved as a data cell
                                var index = _.findIndex(el.cellMAppings, {row_id: id.row, col_id: id.column})
                                if(index != -1){
                                    el.editCell.cell.type = 'data';
                                    el.editCell.cell.mapID = el.cellMAppings[index].id;
                                    el.editCell.cell.cellID = el.cellMAppings[index].sheet_coordinates;
                                    el.editCell.cell.tabName = el.cellMAppings[index].tab_name;
                                    el.editCell.cell.sheetName = '';
                                    el.editCell.cell.sheetID = el.cellMAppings[index].client_app_spreadsheet_id;
                                    el.editCell.cell.dataType = el.cellMAppings[index].data_type;
                                    el.editCell.cell.data_source = el.cellMAppings[index].data_source;
                                    el.editCell.cell.source_view_id = el.cellMAppings[index].source_view_uid;
                                    if(el.freezeDataType != ''){
                                        el.editCell.cell.dataType = el.freezeDataType
                                    }
                                    el.$nextTick(()=>{
                                        //el.$refs.currentCellMappingInput.focus();
                                        //el.$refs.currentCellMappingInput.select();
                                        if(el.freezeDataType != ''){
                                            el.editCell.cell.dataType = el.freezeDataType;
                                            el.$nextTick(()=>{
                                                el.saveCellMapping();
                                            })
                                        }
                                    })
                                    
                                    /*if(this.lockedSheetAndTab){
                                        el.$refs.currentCellMappingInput.focus();
                                        el.editCell.cell.sheetID = el.lockedSheet;
                                        el.editCell.cell.tabName = el.lockedTab;
                                    }*/
                                }
                                else{
                                    el.editCell.cell.type = 'static';
                                    el.editCell.cell.mapID = '';
                                    el.editCell.cell.cellID = '';
                                    el.editCell.cell.sheetName = '';
                                    el.editCell.cell.tabName = '';
                                    el.editCell.cell.sheetID = '';
                                    el.editCell.cell.data_source = '';
                                    el.editCell.cell.source_view_id = '';
                                    if(el.toggleEditDataCell){
                                        el.editCell.cell.type = 'data';
                                        el.$nextTick(()=>{
                                            el.editCell.cell.sheetID = el.lockedSheet;
                                            el.editCell.cell.tabName = el.lockedTab;
                                            el.$refs.currentCellMappingInput.focus();
                                            el.$refs.currentCellMappingInput.select();
                                            
                                        })
                                    }
                                }

                                //* If the cell is part of a span, display the spann css for the cell
                                var spanIndex = _.findIndex(this.config.data.spans, (data) => {
                                    return (data[0] == id.row && data[1] == id.column)
                                })

                                if(spanIndex != -1){
                                    //* Selected cell belongs to span
                                    //el.editCell.cell.text = row[id.column]
                                    el.editCell.cell.text = el.config.data.spans[spanIndex][4];
                                    el.editCell.cell.css = el.config.data.spans[spanIndex][5];
                                    
                                }
                                else{
                                    //* Cell not in span
                                    //*find css class names for individual cell
                                    if(row.$cellCss == undefined){
                                        el.editCell.cell.css = '';
                                    }
                                    else if(row.$cellCss[id.column] == undefined){
                                        el.editCell.cell.css = '';
                                    }
                                    else{
                                        el.editCell.cell.css = row.$cellCss[id.column]
                                    }
                                }

                                //if
                                
                                el.$nextTick(()=>{
                                    if(el.editCell.cell.type == 'static'){
                                        el.$refs.currentCellInput.focus();
                                        el.$refs.currentCellInput.select();
                                    }
                                    
                                    
                                })
                            }
                            else if(type == 'row'){
                                el.editCell.selectedRowID = id.row;
                                el.editCell.selectedColumnID = id.column;
                                el.editCell.span.column = id.column;

                                el.clearSelectionType('row');

                                //*find css classes for selected row
                                var record = table.getItem(id.row);
                                if(record.$css == undefined){
                                    el.editCell.row.css = "";
                                }
                                else{
                                    el.editCell.row.css = record.$css;
                                }

                                //*Row height
                                if(record.$height == undefined){
                                    el.editCell.row.height = 36;
                                }
                                else{
                                    el.editCell.row.height = record.$height;
                                }
                            }
                            else if(type == 'column'){
                                el.editCell.selectedRowID = id.row;
                                el.editCell.selectedColumnID = id.column;

                                el.clearSelectionType('column');
                                
                                if(el.selectedViewData.type === 'data_table'){
                                    let col_index = _.findIndex(el.config.columns, {id: id.column});
                                    if(col_index != -1){
                                        el.editCell.column.header = el.config.columns[col_index].header;
                                    }


                                    let index = _.findIndex(el.cellMAppings, {row_id: id.column});
                                    console.log(index);
                                    if(index != -1){
                                        el.refreshValue = false;
                                        let key = el.cellMAppings[index].json_key;
                                        el.editCell.column.key = key;
                                        el.$nextTick(()=>{
                                            el.editCell.column.key = key;
                                            console.log(key, el.editCell.column.key);
                                            
                                        });
                                        setTimeout(()=>{
                                            el.refreshValue = true;
                                        },200)
                                        
                                    }
                                    else{
                                        el.editCell.column.key = '';
                                        console.log('No value');
                                    }
                                }
                                else{
                                    //*Set column config options
                                    var col = table.getColumnConfig(id.column);
                                    el.editCell.column.id = col.id;
                                    el.editCell.column.header = col.header[0].text;
                                    el.editCell.column.width = col.width;
                                    if(col.css != undefined){
                                        el.editCell.column.css = col.css;
                                    }
                                    else{
                                        el.editCell.column.css = "";
                                    }
                                    /*if(col.fillspace != undefined){
                                        el.editCell.column.fillspace = col.fillspace;
                                    }
                                    else{
                                        el.editCell.column.fillspace = "1";
                                    }*/
                                }
                            }
                        }
                    }
                },
                onBeforeAreaAdd(area){
                    console.log('onB4', area)
                },
                onAfterAreaAdd(area){
                    var el = this.$scope.$parent;
                    var startColIndex = _.findIndex(this.config.columns, {id: area.start.column})
                    var startRowIndex = _.findIndex(this.config.data.data, {id: area.start.row})

                    var endColIndex = _.findIndex(this.config.columns, {id: area.end.column})
                    var endRowIndex = _.findIndex(this.config.data.data, {id: area.end.row})

                    var width = (endColIndex-startColIndex) + 1
                    var height = (endRowIndex-startRowIndex) + 1
                    el.editCell.span.row = area.start.row;
                    el.editCell.span.column = area.start.column;
                    el.editCell.span.width = width;
                    el.editCell.span.height = height;

                    //*Add optional check if area was added by code or user
                    //el.editCell.span.height = height;
                    //el.editCell.span.height = height;

                },
            }
    }),
    mounted(){
        this.setMadeChangesFlag(false);
        this.$eventHub.$on('selectedView', this.selectViewEvent);
        this.$eventHub.$on('savedNewRow', this.savedNewRowEvent);
        this.$eventHub.$on('savedColumn', this.savedColumnEvent);
        this.$eventHub.$on('createNewTable', this.createNewTableEvent);
        this.$eventHub.$on('createCopyFromTable', this.createCopyFromTableEvent);
        this.$eventHub.$on('selectedApp', this.selectAppEvent);
        this.$eventHub.$on('forceSaveConfiguration', this.forceSaveEvent);

        
            setTimeout(()=>{
                if(this.config.columns.length > 0){
                    this.changeCellSelectionMode('column')
                }
            }, 500);
    },
    beforeDestroy(){
        this.$eventHub.$off('savedNewRow');
        this.$eventHub.$off('savedColumn');
        this.$eventHub.$off('createNewTable');
        this.$eventHub.$off('createCopyFromTable');
        this.$eventHub.$off('selectedView');
        this.$eventHub.$off('selectedApp');
        this.$eventHub.$off('forceSaveConfiguration');
    }
}
</script>

<style>
.webix_axis_item_y{
  height: 32px;  
  white-space: normal;
}
.multiCss{
    word-wrap: break-word;
}
</style>