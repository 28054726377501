<template>
    <b-modal id="overlayGraph" ref="overlayGraph" title="Add Overlay Graph">
        <div v-if="hasMounted">
            <b-form @submit="save">
                <b-row>
                <b-col>
                    <span class="card-title">Placeholder data</span>
                </b-col>
            </b-row>
            <b-row  v-for="item, idx in magic" :key="idx" class="btnRow">
                <b-col cols="2">
                    Bar {{idx + 1}}
                </b-col>
                <b-col cols="2">
                    <b-form-input readonly size="sm" v-model="magic[idx]"></b-form-input>
                </b-col>
                <b-col cols="3">
                    <b-form-input size="sm" v-model="data[idx]"></b-form-input>
                </b-col>
            </b-row>
            </b-form>
        </div>
        <div>
        </div>
        <template #modal-footer>
        <div class="w-100">
          <b-button @click="saveConfig()" style="float: right;" variant="success" class="float-left" >Save</b-button>
          <b-button @click="$bvModal.hide('overlayGraph')" variant="default" style="float: right;" >Close</b-button>&nbsp;
        </div>
      </template>
    </b-modal>
</template>

<script>
import _ from 'lodash'
export default {
    props:{
        barCount:{},
        config:{}
    },
    data:() => ({
        hasMounted: false,
        magic:[],
        data:[],
        config_file:{},
        orientation: null,
    }),
    methods:{
        save(e){
            e.preventDefault();
            this.saveConfig();
        },
        saveConfig(){
            if(this.orientation == 'horizontal'){
                let scatter = {"type":"scatter","value":"#magic#","color":"#36abee","item":{"color":"#ffcf4d","borderColor":"#ff4000","radius":5,"type":"t"},"tooltip":{"template":"#magic_data#"},"line":{"color":"#ffcf4d","width":1}}
                this.config_file.config_json.data.forEach( (item,idx) => {
                    item['magic'] = this.magic[idx];
                    item['magic_data'] = this.data[idx];
                })
                this.config_file.config_json.xValue = "#magic_data#"

                this.config_file.config_json.series.push(scatter);

                this.$eventHub.$emit('addedOverlayGraph', [this.orientation,this.config_file]);
            }
            else{
                let scatter_chart = {"type":"scatter","value":"#magic_data#","color":"#36abee","item":{"color":"#ffcf4d","borderColor":"#ff4000","radius":5,"type":"t"},"tooltip":{"template":"#magic_data#"},"line":{"color":"#ffcf4d","width":1}}
                this.config_file.config_json.data.forEach( (item,idx) => {
                    item['magic'] = this.magic[idx];
                    item['magic_data'] = this.data[idx];
                })
                this.config_file.config_json.xValue = "#magic#"

                this.config_file.config_json.series.push(scatter_chart);

                this.$eventHub.$emit('addedOverlayGraph', [this.orientation,this.config_file]);
            }
        }
    },
    mounted(){
        this.config_file = _.cloneDeep(this.config);
        var type = this.config_file.config_json.type;
        if(type == 'bar' || type == 'stackedBar'){
            this.orientation = 'vertical'
            for(var i = 0; i < this.barCount; i++){
                this.magic.push(i+0.5);//Set to middle of bar item - each whole number = a bar group
                this.data.push(i+1);
            }
        }
        else if(type == 'barH' || type == 'stackedBarH'){
            this.orientation = 'horizontal'
            for(var ii = 0; ii < this.barCount; ii++){
                this.magic.push(ii + 0.5);
                this.data.push(3);
                
            }
            _.reverse(this.magic_data)
            console.log(this.magic)
        }

        
        this.$nextTick(()=>{
            this.hasMounted = true;
        })
    },
}
</script>

<style>

</style>